import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken } from '@/utils/auth'
import * as apiUser from '@/api/user'
import router, { resetRouter } from '@/router'

const defaultGuard = 'admin'

const state = {

    token: getToken(),
    refreshToken: getRefreshToken(),

    userInfo: {
        name: '',
        nickname: '',
        avatar: 'https://assets.koocoding.com/common/images/default_avatar.svg',
        roles: [],
        phone: '',
        number: '',
        institution_name: '',
    }
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_ROLES: (state, roles) => {
        state.userInfo.roles = roles
    },

    SET_USER_INFO: (state, { name, nickname, avatar, phone, number, institution_name }) => {
        // if (name != '') {
        //     state.userInfo.name = name
        // }
        // if (nickname != '') {
        //     state.userInfo.nickname = nickname
        // }
        // if (avatar != '') {
        //     state.userInfo.avatar = avatar
        // }
        // if (phone != '') {
        //     state.userInfo.phone = phone
        // }
        // if (number != '') {
        //     state.userInfo.number = number
        // }
        // if (institution_name != '') {
        //     state.userInfo.institution_name = institution_name
        // }
        state.userInfo.name = name
        state.userInfo.nickname = nickname
        state.userInfo.avatar = avatar
        state.userInfo.phone = phone
        state.userInfo.number = number
        state.userInfo.institution_name = institution_name
    }
}

const actions = {

    setToken({ commit, state }, token) {
        commit('SET_TOKEN', token)
        setToken(token)
        setRefreshToken(token)
    },
    resetToken({ commit }) {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
    },

    async login({ commit }, { username, password }) {
        let guard = defaultGuard

        let response = null;
        if (guard == 'koocoding') {
            response = await apiUser.userLogin({
                username: username.trim(),
                password: password
            })
        } else if (guard == 'admin') {
            response = await apiUser.adminLogin({
                username: username.trim(),
                password: password
            })
        }

        const { data } = response
        if (data && data.token) {
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            setRefreshToken(data.token)
        }

        return response
    },

    async qrcodeLogin({ commit }) {
        let response = await apiUser.qrcodeLogin()

        const { data } = response
        if (data && data.token) {
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            setRefreshToken(data.token)
        }
        return response
    },

    async adminQrcodeLogin({ commit }) {
        let response = await apiUser.adminQrcodeLogin()

        const { data } = response
        if (data && data.token) {
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            setRefreshToken(data.token)
        }
        return response
    },

    async smsLogin({ commit }, { phone, code }) {
        let guard = defaultGuard

        let response = null;
        if (guard == 'koocoding') {
            response = await apiUser.userSmsLogin({
                phone: phone.trim(),
                code: code
            })
        } else if (guard == 'admin') {
            response = await apiUser.adminSmsLogin({
                phone: phone.trim(),
                code: code
            })
        }

        const { data } = response
        if (data && data.token) {
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            setRefreshToken(data.token)
        }
        return response
    },

    async smsRegister({ commit }, { phone, code, password, name }) {
        let guard = defaultGuard

        let response = null;
        if (guard == 'koocoding') {
            response = await apiUser.userSmsRegister({
                phone: phone.trim(),
                code: code,
                password: password
            })
        } else if (guard == 'admin') {
            response = await apiUser.adminSmsRegister({
                phone: phone.trim(),
                code: code,
                password: password,
                name: name
            })
        }
        const { data } = response

        if (data && data.token) {
            commit('SET_TOKEN', data.token)
            setToken(data.token)
            setRefreshToken(data.token)
        }
        return response
    },

    async getUserInfo({ commit, state }) {
        let guard = defaultGuard

        let response = null;
        if (guard == 'koocoding') {
            response = await apiUser.getUserInfo();
        } else if (guard == 'admin') {
            response = await apiUser.adminGetUserInfo();
        }

        // const { data } = response
        // console.log(data)
        const { name, nickname, avatar, phone, number, institution_name, roles } = response.data
        if (response.data) {
            commit('SET_USER_INFO', { name, nickname, avatar, phone, number, institution_name })
            commit('SET_ROLES', roles)
        }
        return response;
    },

    async logout({ commit, state }) {
        let guard = defaultGuard

        let response = null;
        if (guard == 'koocoding') {
            response = await apiUser.logout(state.token)
        } else if (guard == 'admin') {
            response = await apiUser.adminLogout(state.token)
        }

        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()
        
        return response
    },

    async refreshToken({ commit, state }) {
        let guard = defaultGuard

        let response = null;
        if (guard == 'koocoding') {
            response = await apiUser.refreshToken(state.refreshToken)
        } else if (guard == 'admin') {
            response = await apiUser.adminRefreshToken(state.refreshToken)
        }

        const { data } = response

        commit('SET_TOKEN', data.token.access_token)
        setToken(data.token.access_token)
        setRefreshToken(data.token.refresh_token)
        
        return response
    },

    

    // 暂时还没有用，使用前需要再调整
    // dynamically modify permissions
    async changeRoles({ commit, dispatch }, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const { roles } = await dispatch('user/getUserInfo')

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        // dispatch('tagsView/delAllViews', null, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
