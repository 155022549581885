<template>
    <div class="alert-area" v-show="alert.msg != ''">
        <v-alert 
            dense 
            transition="scale-transition" 
            :type="alert.type" 
            :value="alert.msg != ''"
        >
            {{ alert.msg }}
        </v-alert>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'alert',
        computed: {
            ...mapGetters([
                'alert'
            ]),
        },
    }
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    top: 20px;
    // left: 0;
    // right: 0;
    left: calc(50% - 100px);
    width: 240px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}
</style>
