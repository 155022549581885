import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './plugins/base'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VCalendar from 'v-calendar'

import ECharts from 'vue-echarts'
// 全局注册组件（也可以使用局部注册）
Vue.component('v-chart', ECharts)

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VCalendar, {
    componentPrefix: 'vc',
})

new Vue({
    // vuetify: window.Vuetify,
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')

export default i18n;
window.i18n = i18n;
