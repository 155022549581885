<template>
    <div class="exam-objective-true-or-false">
         <div class="question-area">
            <div class="question-type">判断：</div>
            <div class="question-content">
                <div class="question-text">{{question.question_text}}</div>
                <div class="question-img" v-if="question.question_img">
                    <img :src="getExamQuestionImgUrl(question.question_img)" v-if="question.question_img_height > 0"
                        v-bind:style="{'max-height': question.question_img_height + 'px'}" >
                    <img :src="getExamQuestionImgUrl(question.question_img)" v-else >
                </div>
            </div>
        </div>
        <div class="answer-area">
            <div class="answer-row">
                <div class="option"
                    :class="{selected: question.user_answer == 'True', hover: curHover == 'True' && question.user_answer != 'True'}" 
                    @mouseover="curHover = 'True'" @mouseout="curHover = ''"
                    @click.stop="setSelected('True')">正确</div>

                <div class="option-padding"></div>

                <div class="option"
                    :class="{selected: question.user_answer == 'False', hover: curHover == 'False' && question.user_answer != 'False'}" 
                    @mouseover="curHover = 'False'" @mouseout="curHover = ''"
                    @click.stop="setSelected('False')">错误</div>
            </div>
        </div>
    </div>
</template>

<script>
import { commitAnswer } from "@/api/exam";
export default {
    name: "true_or_false",
    props: [
        "question",
        "examId"
    ],
    data: function () {
        return {
            curHover: "",
        };
    },
    methods: {
        async setSelected(selected) {
            if (selected == this.question.user_answer) {
                return
            }

            this.curHover = ''

            this.$store.commit("exam/SetQuestionAnswer", { qid: this.question.qid, userAnswer: selected })

            await commitAnswer({ examId: this.examId, qid: this.question.qid, userAnswer: selected })
        },
        getExamQuestionImgUrl(key) {
            if (key) {
                return "https://assets.exam.koocoding.com/exam/" + key;
            }
            return "";
        },
    },
};
</script>

<style lang="less" scoped>
.exam-objective-true-or-false {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .question-area {
        font-size: 26px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;

        .question-type {
            width: 80px;
        }

        .question-content {
            margin-bottom: 50px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 600px;

            .question-text {
                margin-bottom: 15px;
                display: flex;
                justify-content: flex-start;
                align-content: flex-start;
                text-align: left;
            }

            .question-img {
                img {
                    max-height: 200px;
                    max-width: 600px;
                }
            }
        }
    }

    .answer-area {
        .answer-row {
            width: 100%;
            height: auto;
            display: flex;

            .option {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 30px;
                display: flex;
                padding: 70px;
                border-radius: 4px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 12%);

                &.selected {
                    background-color: #f4920f;
                }

                &.hover {
                    background-color: rgba(150, 150, 150, 50%);
                }

                .option-content {
                    width: auto;

                    img {
                        height: 60px;
                    }
                }
            }

            .option-padding {
                width: 70px;
            }
        }
    }

    .padding {
        height: 30px;
        width: 100%;
    }
}
</style>
