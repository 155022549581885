// import ExamGradeIde from '@/views/pages/examGrade/ide';
// import ExamGradePython from '@/views/pages/examGrade/python';
import ExamGradeRobot from '@/views/pages/examGrade/robot';
import ExamGradeDone from '@/views/pages/examGrade/Done';

// export default [
export const routes = [

    /**************
     * 考官相关
     **************/ 
    // 阅卷
    // {
    //     path: '/grade/ide',
    //     name: 'gradeIde',
    //     component: ExamGradeIde,
    //     meta: { keepAlive: false },
    // },
    // {
    //     path: '/grade/python',
    //     name: 'gradePython',
    //     component: ExamGradePython,
    //     meta: { keepAlive: false },
    // },
    {
        path: '/grade/robot',
        name: 'gradeRobot',
        component: ExamGradeRobot,
        meta: { keepAlive: false },
    },
    {
        path: '/grade/done',
        name: 'gradeDone',
        component: ExamGradeDone,
        meta: { keepAlive: false },
    },

    // Exam-Ide
    //------------------
    // Exam-Scratch
    {
        path: '/grade/scratch',
        name: 'ide-grade-scratch',
        component: () => import(/* webpackChunkName: "ide-grade-scratch" */ '@/views/pages/ide/examGrade/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/grade/scratch',
        name: 'iframe-grade-scratch',
        component: () => import(/* webpackChunkName: "iframe-grade-scratch" */ '@/views/pages/ide/examGrade/scratch/Page'),
        meta: { keepAlive: false },
    },
    // Exam-Python
    {
        path: '/grade/python',
        name: 'grade-python',
        component: () => import(/* webpackChunkName: "grade-python" */ '@/views/pages/ide/examGrade/python/Page'),
        meta: { keepAlive: true },
    },

];

export default routes;
