import Vue from 'vue'
import VueI18n from 'vue-i18n'

// import en from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

const messages = {
//   en: {
//     ...require('@/locales/en.json'),
//     // $vuetify: en,
//   },
  'en': require('@/locales/en.json'),
  'zh-cn': require('@/locales/zh-cn.json'),
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'zh-cn',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh-cn',
  messages,
})
