import { getToken, setToken, removeToken, setAllowLogin } from '@/utils/auth'
import * as apiExam from '@/api/admin_exam'
import * as apiEval from '@/api/examEval'
import cos from '@/utils/cos'

import CoreData from '@/libs/runtime/coreData';

// import * as types from '@/store/mutation-types'

const state = {

    // token: getToken(),

    // cid: '',
    // sid: '',

    /*********
     * 考试相关
     */
    // 考场信息
    exam: {
        examId: '',
        examName: '',
        examPlace: '',
        startTime: '',
        endTime: '',
    },
    // 考卷信息（每个考生不一样）
    paper: {
        count: 0, //试题总数量
        index: 0, //当前试题位置
        curQuestion: {}, //当前试题(为了显示的时候方便)
        questionIds: [], //试题ids
        questionTypes: [], //试题类型
        questionMap: {}, //试题详情
        // 各种类型试题内容说明
        // question: {
        //     type: 'choice', //选择
        //     qid: '',
        //     question_text: '',
        //     question_img: '',
        //     option_a_text: '',
        //     option_a_img: '',
        //     option_b_text: '',
        //     option_b_img: '',
        //     option_c_text: '',
        //     option_c_img: '',
        //     option_d_text: '',
        //     option_d_img: '',
        //     option_show_type: 0,
        //     user_answer: ''
        // },
        // question: {
        //     type: 'trueOrFalse', //判断
        //     qid: '',
        //     question_text: '',
        //     question_img: '',
        //     user_answer: ''
        // },
        // question: {
        //     type: 'operate', //实操
        //     qid: '',
        //     question_name: '',
        //     question_text: '',
        //     question_img: '',
        // },
    },

    /*********
     * 阅卷相关
     */
    grade: {
        qid: '',
        examNumber: '',
        examType: '',
        leftCount: '',
    }
}

const mutations = {
    /**
     * 考试系统相关
     */
    // 设置考试基本信息
    SetExamInfo: (state, {
        examId,
        examTitle,
        examName,
        startTime,
        endTime,
        count,
        questionIds,
        questionTypes
    }) => {
        // 考场信息
        state.exam.examId = examId;
        state.exam.examTitle = examTitle;
        state.exam.examName = examName;
        state.exam.startTime = startTime;
        state.exam.endTime = endTime;
        // 考卷信息
        state.paper.count = count;
        state.paper.questionIds = questionIds;
        state.paper.questionTypes = questionTypes;
    },
    // 设置考卷数据
    SetExamPaperData(state, {
        paperId,
        count,
        questionIds
    }) {
        state.paper.paperId = paperId;
        state.paper.count = count;
        state.paper.index = 0;
        state.paper.questionIds = questionIds;
    },
    ResetExamPaperData(state) {
        state.paper.count = 0
        state.paper.index = 0
        state.paper.curQuestion = {}
        state.paper.questionIds = []
        state.paper.questionTypes = []
        state.paper.questionMap = {}
    },


    SetExamQuestionData: (state, {
        qid,
        question
    }) => {
        state.paper.questionMap[qid] = question
        state.paper.curQuestion = state.paper.questionMap[qid]
    },

    // 设置当前试卷的index
    UpdateExamPaperIndex: (state, qid) => {
        let index = 0;
        for (let i = 0; i < state.paper.questionIds.length; i++) {
            if (state.paper.questionIds[i] == qid) {
                index = i;
                break;
            }
        }
        state.paper.index = index
        state.paper.curQuestion = state.paper.questionMap[qid]
    },
   
    // 提交答案
    SetQuestionAnswer: (state, {
        qid, 
        userAnswer
    }) => {
        if (state.paper.questionMap[qid]) {
            state.paper.questionMap[qid].user_answer = userAnswer
            // state.paper.curQuestion = state.paper.questionMap[qid]
        }
    },

    // 阅卷相关
    SetGradeData: (state, {
        qid,
        examNumber,
        examType,
        leftCount
    }) => {
        console.log(qid)
        console.log(leftCount)
        state.grade.qid = qid
        state.grade.examNumber = examNumber
        state.grade.examType = examType
        state.grade.leftCount = leftCount
    },

    // 设置阅卷相关数据
    SetEvaluationData(state, {
        data
    }) {
        state.evaluationData.userList = data.userList;
    },
    SetEvaluationIndex(state, index) {
        state.evaluationData.curUserIndex = index
    },
}


const actions = {
	async GetExamData({ commit, state }, examId) {
        let res = await apiExam.getExamData(examId);
        if (res) {
            commit('SetExamInfo', {
                examId: res.data.examId,
                examName: res.data.examName,
                examPlace: res.data.examPlace,
                startTime: res.data.startTime,
                endTime: res.data.endTime,
                paperId: res.data.paperId
            });
            commit('SetExamPaperData', {
                paperId: res.data.paperId,
                count: res.data.count,
                questionIds: res.data.questionIds,
            });
        }
    },

    async GetPaperData({ commit, state }, paperId) {
        let res = await apiExam.getPaperData(paperId);
        if (res) {
            commit('SetExamPaperData', {
                paperId: res.data.paperId,
                count: res.data.count,
                questionIds: res.data.questionIds,
            });
        }
    },

    async GetQuestionData({ commit, state }, {
        qid, type
    }) {
        let res = await apiExam.getQuestion4Admin({
            qid: qid,
        });
        // console.log(res.data)

        let question = state.paper.questionMap[qid]

        let questionData = []
        questionData['qid'] = question['qid']
        questionData['type'] = question['type']
        questionData['choice_answer_index'] = question['choice_answer_index']
        questionData['user_answer'] = question['user_answer']
        questionData['question_name'] = res.data.question['question_name']
        questionData['question_text'] = res.data.question['question_text']
        questionData['question_img'] = res.data.question['question_img']
        questionData['question_img_height'] = res.data.question['question_img_height']
        questionData['option_a_text'] = res.data.question['option_a_text']
        questionData['option_a_img'] = res.data.question['option_a_img']
        questionData['option_a_img_height'] = res.data.question['option_a_img_height']
        questionData['option_b_text'] = res.data.question['option_b_text']
        questionData['option_b_img'] = res.data.question['option_b_img']
        questionData['option_b_img_height'] = res.data.question['option_b_img_height']
        questionData['option_c_text'] = res.data.question['option_c_text']
        questionData['option_c_img'] = res.data.question['option_c_img']
        questionData['option_c_img_height'] = res.data.question['option_c_img_height']
        questionData['option_d_text'] = res.data.question['option_d_text']
        questionData['option_d_img'] = res.data.question['option_d_img']
        questionData['option_d_img_height'] = res.data.question['option_d_img_height']
        questionData['option_show_type'] = res.data.question['option_show_type']

        commit('SetExamQuestionData', {
            qid: res.data.qid,
            question: questionData
        })
        commit('UpdateExamPaperIndex', res.data.qid)
    },

    async CommitAnswer({ commit, state }, {
        qid, userAnswer
    }) {
        // await commitAnswer({
        //     qid: qid,
        //     userAnswer: userAnswer
        // });
    },

    async CreateExamUserPaper({ commit, state }) {
        let res = await apiExam.createExamUserPaper();
        // commit('SetExamInfo', {
        //     examId: res.data.examId,
        //     examName: res.data.examName,
        //     examPlace: res.data.examPlace,
        //     startTime: res.data.startTime,
        //     endTime: res.data.endTime,
        //     paperId: res.data.paperId
        // });
        // commit('SetClockData', {
        //     startTime: res.data.startTime,
        //     endTime: res.data.endTime,
        //     isCoundDown: true
        // });
    },

    //获取用户实操数据
	async GetExamUserOperateData({ commit, state }, qid) {
        let res = await apiExam.getExamUserOperateData({
            qid: qid
        });
        commit('SetProjectData', { data: res.data });
        commit('SetLoadDataDone')
    },



    /*************
     * 考官阅卷相关
     */
    // EvaluationLogin({ commit, state }, {
    //     username, password
    // }) {
    //     return new Promise((resolve, reject) => {
    //         evaluationLogin({
    //             username: username,
    //             password: password
    //         }).then(response => {
    //             if (response.data.code == 20000 && response.data.data && response.data.data.access_token) {
    //                 // commit('SetToken', data.access_token);
    //                 setToken(response.data.data.access_token);
    //             }
    //             resolve(response)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    async GetExamUserOperateData4Examiner({ commit, state }, {
        examId, examNumber
    }) {
        let res = await apiEval.getExamUserOperateData4Examiner({
            examId: examId,
            examNumber: examNumber
        });
        // console.log(res.data);
        commit('SetProjectData', { data: res.data });
        commit('SetLoadDataDone')
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
