export const routes = [
    {
        path: '/',
        redirect: { name: 'login' },
        meta: { keepAlive: true },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/admin/Login'),
        meta: { keepAlive: true },
    },
    {
        path: '/',
        component: () => import('@/views/pages/admin/Index'),
        children: [
            {
                name: '总览',
                path: 'dashboard',
                component: () => import('@/views/pages/admin/Dashboard'),
                meta: { keepAlive: true },
            },
            {
                name: '账号信息',
                path: 'profile',
                component: () => import('@/views/pages/admin/Profile'),
                meta: { keepAlive: true },
            },


            // 课程管理
            {
                name: '课程包管理',
                path: '/course-mgr/topic-list',
                component: () => import('@/views/pages/admin/courseMgr/Topic'),
                meta: { keepAlive: true },
            },
            {
                name: '课程列表',
                path: '/course-mgr/topic-list/toic',
                component: () => import('@/views/pages/admin/courseMgr/TopicGroup'),
                meta: { keepAlive: true },
            },
            {
                name: '课程',
                path: '/course-mgr/topic-list/topic/course',
                component: () => import('@/views/pages/admin/courseMgr/Course'),
                meta: { keepAlive: true },
            },
            {
                name: '课程包预览',
                path: '/course-mgr/topic-list/topic-preview',
                component: () => import('@/views/pages/admin/courseMgr/TopicGroup4Preview'),
                meta: { keepAlive: true },
            },
            {
                name: '课程预览',
                path: '/course-mgr/topic-list/topic-preview/course-preview',
                component: () => import('@/views/pages/admin/courseMgr/Course4Preview'),
                meta: { keepAlive: true },
            },
            {
                name: '课程管理',
                path: '/course-mgr/course-list',
                component: () => import('@/views/pages/admin/courseMgr/CourseList'),
                meta: { keepAlive: true },
            },
            {
                name: '课程 ',
                path: '/course-mgr/course-list/course',
                component: () => import('@/views/pages/admin/courseMgr/Course'),
                meta: { keepAlive: true },
            },
            // {
            //     name: '练习管理',
            //     path: '/course-mgr/practice-list',
            //     component: () => import('@/views/pages/admin/courseMgr/Practice'),
            //     meta: { keepAlive: true },
            // },
            // {
            //     name: '练习课程',
            //     path: '/course-mgr/practice-list/course',
            //     component: () => import('@/views/pages/admin/courseMgr/Course'),
            //     meta: { keepAlive: true },
            // },
            

            // 教学管理
            {
                name: '班级列表',
                path: '/education-mgr/class-list',
                component: () => import('@/views/pages/admin/educationMgr/ClassList'),
                meta: { keepAlive: true },
            },
            {
                name: '班级',
                path: '/education-mgr/class-list/class',
                component: () => import('@/views/pages/admin/educationMgr/Class'),
                meta: { keepAlive: true },
            },
            {
                name: '班级 - 课程包',
                path: '/education-mgr/class-list/class/topic',
                component: () => import('@/views/pages/admin/courseMgr/TopicGroup4Class'),
                meta: { keepAlive: true },
            },
            {
                name: '班级 - 课程 ',
                path: '/education-mgr/class-list/class/topic/course',
                component: () => import('@/views/pages/admin/courseMgr/Course4Class'),
                meta: { keepAlive: true },
            },
            {
                name: '学生列表',
                path: '/education-mgr/student-list',
                component: () => import('@/views/pages/admin/educationMgr/StudentList'),
                meta: { keepAlive: true },
            },
            {
                name: '学生练习',
                path: '/education-mgr/student/exercise',
                component: () => import('@/views/pages/admin/educationMgr/StudentExercise'),
                meta: { keepAlive: true },
            },
            // {
            //     name: '报名',
            //     path: '/education-mgr/enroll',
            //     component: () => import('@/views/pages/admin/educationMgr/Enroll'),
            //     meta: { keepAlive: true },
            // },
            // {
            //     name: '排课',
            //     path: '/education-mgr/scheduling',
            //     component: () => import('@/views/pages/admin/educationMgr/Scheduling'),
            //     meta: { keepAlive: true },
            // },


            // 资源管理
            {
                name: '图片',
                path: '/resource-mgr/picture',
                component: () => import('@/views/pages/admin/resourceMgr/Picture'),
                meta: { keepAlive: true },
            },
            {
                name: '声音',
                path: '/resource-mgr/sound',
                component: () => import('@/views/pages/admin/resourceMgr/Sound'),
                meta: { keepAlive: true },
            },


            // 测评管理
            {
                name: '试题库',
                path: '/exam-mgr/question-lib',
                component: () => import('@/views/pages/admin/examMgr/QuestionLib'),
                meta: { keepAlive: true },
            },
            {
                name: '试题库 ',
                path: '/exam-mgr/question-lib/question-list',
                component: () => import('@/views/pages/admin/examMgr/QuestionList'),
                meta: { keepAlive: true },
            },
            {
                name: '试卷库',
                path: '/exam-mgr/paper-list',
                component: () => import('@/views/pages/admin/examMgr/PaperList'),
                meta: { keepAlive: true },
            },
            {
                name: '试卷题目',
                path: '/exam-mgr/paper-list/question-list',
                component: () => import('@/views/pages/admin/examMgr/PaperQuestionList'),
                meta: { keepAlive: true },
            },
            {
                name: '测评列表',
                path: '/exam-mgr/exam-list',
                component: () => import('@/views/pages/admin/examMgr/ExamList'),
                meta: { keepAlive: true },
            },
            {
                name: '测评',
                path: '/exam-mgr/exam-list/exam',
                component: () => import('@/views/pages/admin/examMgr/Exam'),
                meta: { keepAlive: true },
            },

            // 机构管理
            {
                name: '用户列表',
                path: '/institution-mgr/user-list',
                component: () => import('@/views/pages/admin/institutionMgr/UserList'),
                meta: { keepAlive: true },
            },
            {
                name: '角色列表',
                path: '/institution-mgr/role-list',
                component: () => import('@/views/pages/admin/institutionMgr/RoleList'),
                meta: { keepAlive: true },
            },
            {
                name: '校区',
                path: '/institution-mgr/campus',
                component: () => import('@/views/pages/admin/institutionMgr/Campus'),
                meta: { keepAlive: true },
            },

        ],
    },
];

export default routes;
