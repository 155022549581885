import Vue from 'vue';
import VueRouter from 'vue-router';

/**
 * 避免vue-router 报 NavigationDuplicated错误
 */
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

import adminRoutes from '@/router/modules/admin';
import ideRoutes from '@/router/modules/ide';
import examRoutes from '@/router/modules/exam';
import examGradeRoutes from '@/router/modules/examGrade';

const baseRoutes = [
    //...
];

// const router = new VueRouter({
//     mode: 'history',
//     base: __dirname,
//     // linkActiveClass: 'active',
//     // saveScrollPosition: true,
//     routes: routes
// });

const constantRoutes = baseRoutes.concat(adminRoutes, ideRoutes, examRoutes, examGradeRoutes);

const createRouter = () => new VueRouter({
    mode: 'history',
    base: __dirname,
    // scrollBehavior: () => ({ y: 0 }),
    // saveScrollPosition: true,
    routes: constantRoutes
})
  
const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
