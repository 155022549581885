<template>
    <div class="ide">
        <div class="area">
            <TopToast></TopToast>
            
            <Header ref="header"
                :index="curUser.index"
                :count="curUser.count"
                :curStep="curStep"
                @setCurStep="setCurStep"
            >
            </Header>

            <div class="main-area">
                <div class="row">
                    <div class="row-container line">
                        <div class="desc">
                            <div class="text">程序设计</div>
                            <!-- <div class="text2">上传源码文件</div> -->
                        </div>
                        <div class="src-code-name-container">{{userData.codeName}}</div>
                        <div class="btn-download-code" @click.stop="downloadCode()">下载代码</div>
                    </div>
                </div>
                <div class="row">
                    <div class="row-container line">
                        <div class="desc">
                            <div class="text">结构搭建</div>
                            <!-- <div class="text2">上传3张图片</div> -->
                        </div>
                        <div class="user-img-container" v-if="userData.structureIds.length > 0">
                            <div class="user-img" v-for="id in userData.structureIds" v-bind:key="id">
                                <img :src="getUrl(id)" @click.stop="showPreview(id)">
                            </div>
                        </div>
                        <div class="no-img" v-else>考生未上传图片</div>
                    </div>
                </div>
                <div class="row">
                    <div class="row-container line">
                        <div class="desc">
                            <div class="text">实现效果</div>
                            <!-- <div class="text2">上传1张图片</div> -->
                        </div>
                        <div class="user-img-container" v-if="userData.effectIds.length > 0">
                            <div class="user-img" v-for="id in userData.effectIds" v-bind:key="id">
                                <img :src="getUrl(id)" @click.stop="showPreview(id)">
                            </div>
                        </div>
                        <div class="no-img" v-else>考生未上传图片</div>
                    </div>
                </div>
            </div>
            <div class="img-preview-mask" v-if="showImgPreview" @click.stop="hidePreview()">
                <div class="img-previw">
                    <img :src="imgPreview" alt="">
                </div>
            </div>

        </div>

        <div class="evaluation-area" >
            <div class="header" v-if="curStep == 1">
                <div class="evaluation-score">
                    <div class="text">实操题评分</div>
                    <div class="score">{{scoreData.userOperateScore}}</div>
                    <div class="text"> / 总分40</div>
                </div>
                <div class="submit" @click.stop="grade">评分</div>
            </div>
            <div class="header" v-else>
                <div class="evaluation-score">
                    <div class="text">本次测评得分</div>
                    <div class="score">{{scoreData.userScore}}</div>
                    <div class="text"> / 总分100</div>
                </div>
                <div class="submit" @click.stop="submit">提交</div>
            </div>

            <div class="area">
                <div v-show="curStep == 1">
                    <div class="instruction">
                        <div class="name">【任务名称】{{data.name}}</div>
                        <div class="content">【任务目标】</div>
                        <div v-for="(content, index) in data.instructions" v-bind:key="index">
                            <div class="content">{{content}}</div>
                        </div>
                    </div>
                    <div class="evaluation">
                        <div class="name">【评分标准】</div>
                        <div v-for="(evaluation, index) in data.evaluations" v-bind:key="index">
                            <div class="block">
                                <div class="desc">{{evaluation.desc}}</div>
                                <div class="row">
                                    <input type="text" v-model.number="userScoreList.scores[index]" @change="calcScore($event)" @keyup="calcScore($event)" />
                                    <div class="text">满分{{evaluation.score}}分</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="curStep == 2">
                    <div id="chart-container" class="chart-container">
                        <div id="score-chart" class="score-chart"></div>
                    </div>
                    <div class="score">
                        <div class="row">
                            <div class="score-block">
                                <div class="score-type color1">知识掌握</div>
                                <div class="score-value">{{scoreData.scores[0]}} / {{scoreData.totalScores[0]}}</div>
                            </div>
                            <div class="score-block">
                                <div class="score-type color2">技能运用</div>
                                <div class="score-value">{{scoreData.scores[1]}} / {{scoreData.totalScores[1]}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="score-block">
                                <div class="score-type color3">信息文化</div>
                                <div class="score-value">{{scoreData.scores[2]}} / {{scoreData.totalScores[2]}}</div>
                            </div>
                            <div class="score-block">
                                <div class="score-type color4">创作实践</div>
                                <div class="score-value">{{scoreData.scores[3]}} / {{scoreData.totalScores[3]}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="advice">
                        <div class="">【评价及学习建议】</div>
                        <textarea v-model="advice" />
                    </div>
                </div>
            </div>
        </div>

        <Loading></Loading>

    </div>
</template>

<script>
    import Header from '@/views/pages/ide/examGrade/Header'
    import Loading from '@/views/components/mask/Loading';
    import TopToast from '@/views/components/widget/TopToast';
    // import Bottom from './Bottom';

    import { mapState, mapMutations, mapActions } from 'vuex';
    import { getEvaluationData, getEvaluationUser, getExamUserScore, submitOperateScore, submitAdvice, getExamUserRobotData4Examiner } from '@/api/examEval'

    export default {
        name: 'app',
        data: function() {
            return {
                scoreChart: null,
                chartOption: {
                    title: {
                        // text: '基础雷达图'
                    },
                    tooltip: {},
                    legend: {
                        data: ['分值', '得分', '平均分']
                    },
                    grid: {
                        x: 30,
                        y: 10,
                        x2: 30,
                        y2: 10,
                    },
                    radar: {
                        // shape: 'circle',
                        // name: {
                        //     textStyle: {
                        //         color: '#fff',
                        //         backgroundColor: '#999',
                        //         borderRadius: 3,
                        //         padding: [3, 5]
                        //     }
                        // },
                        center:['50%', '55%'],
                        radius:"60%",
                        nameGap: 10,
                        triggerEvent: false,
                        indicator: [
                            { name: '知识掌握', max: 40},
                            { name: '技能运用', max: 40, axisLabel:{show:false}},
                            { name: '信息文化', max: 40, axisLabel:{show:false}},
                            { name: '创作实践', max: 40, axisLabel:{show:false}},
                        ],
                        axisLabel:{
                            show:true,
                            // color:'#333',
                            showMinLabel: false
                        },
                        axisTick: {
                            show: true
                        },
                        splitNumber:4, //指示器轴的分割段数
                    },
                    series: [{
                        name: '阅卷评分',
                        type: 'radar',
                        // areaStyle: {normal: {}},
                        data : [
                            {
                                value : [0, 0, 0, 0],
                                name : '分值'
                            },
                            {
                                value : [0, 0, 0, 0],
                                name : '得分'
                            },
                            {
                                value : [0, 0, 0, 0],
                                name : '平均分'
                            }
                        ],
                        color:['#406cb4','#e37933','#a5a5a5']
                    }]
                },
                img: {
                    scale: "https://assets.koocoding.com/ide/images/scale.png",
                    ruler: "https://assets.koocoding.com/ide/images/ruler.svg",
                    play: "https://assets.koocoding.com/ide/images/play2.svg",
                    stop: "https://assets.koocoding.com/ide/images/stop.svg",
                    reset: "https://assets.koocoding.com/ide/images/reset.svg",
                    close: "https://assets.koocoding.com/ide/images/close.png",
                    closeWhite: "https://assets.koocoding.com/ide/images/close_white.png",

                    // 历史命令按钮
                    back: "https://assets.koocoding.com/ide/images/back.png",
                    backDefault: "https://assets.koocoding.com/ide/images/back_default.png",
                    forward: "https://assets.koocoding.com/ide/images/forward.png",
                    forwardDefault: "https://assets.koocoding.com/ide/images/forward_default.png",
                },


                pid: '',
                projName: '',
                projType: 'project',  // project, course
            
                isRunning: false,
                hasReset: true,

                resolve: '',
                reject: '',

                qid: '',
                lastLoadActorId: '',

                // 测评相关数据
                data: {
                    name: '',
                    instructions: [],
                    evaluations: [],
                },
                // 用于展示和操作各个分项的评分
                userScoreList: {
                    scores: []
                },
                // 考官评语
                advice: '',
                // 当前用户信息
                curUser: {
                    examId: '',
                    examNumber: '',
                    index: 0,
                    count: 0,
                },
                // 当前评分/评语步骤
                curStep: 1,  // 1:正在给实操题打分，2:正在填写评价
                // 当前用户操作分，总分，各维度得分
                scoreData: {
                    userScore: 0,
                    userOperateScore: 0,
                    operateScores: [], //考官给实操题的打分
                    totalScores: [], //各知识维度总分
                    scores: [], //各知识维度得分
                    averageScores: [], //各知识维度平均分
                },

                // 用于EV3等需要上传图片和下载程序的场景
                userData: {
                    codeIds: [],
                    codeName: '',
                    codeFile: null,

                    structureIds: [],
                    structureFile: null,

                    effectIds: [],
                    effectFile: null,
                },

                showImgPreview: false,
                imgPreview: 'https://assets.exam.koocoding.com/assets/images/4b52f9c27779428e313f9e5a178e77d9/get/',
            }
        },
        created() {
        //    Ide.init();
            if (this.$route.query.qid) {
                this.qid = this.$route.query.qid
            }
            this.SetCurrent('user');
        },
        mounted() {
            // 绑定全局事件，用于收回菜单栏
            // TODO: 改成激活菜单时注册全局点击事件，收起菜单时注销全局点击事件
            var that = this;
            window.addEventListener('click', function() {
                that.HideAllMenu();
            });
        
            // 创建变量对话框
            window.myPrompt = () => {
                return this.prompt();
            };

            this.loadData()
        },
        watch: {
        },
        computed: {
             ...mapState([
                'courseId','sectionId','ide','curNav','loadDataDone','initStageDone','courseData',
                'curActor','spriteData','isShowSetting','topToast',
                'exam','paper'
            ]),
        },
        methods: {
             ...mapMutations([
                'SetCourseId','SetSectionId',
                'HideAllMenu','InitStageDone','SetCurActorId','ResetSpriteData','UpdateSpriteData','DeleteSpriteData',
                'SetCurNav','SetPropData','ShowWindow','SetProjectData','SetCurrent',
                'UpdateExamPaperIndex','SetIsShowLoading'
            ]),
            ...mapActions([
                'GetExamData','GetQuestionData','GetCourseInfo',
                'GetExamUserOperateData4Examiner'
            ]),

            loadChart() {
                let chart = document.getElementById('score-chart');
                if (chart) {
                    this.scoreChart = this.$echarts.init(chart)
                    this.scoreChart.setOption(this.chartOption)
                }
            },

            calcScore(event) {
                let totalScore = 0;
                // console.log(this.userScoreList.scores)
                for (let i = 0; i < this.userScoreList.scores.length; i++) {
                    totalScore += parseInt(this.userScoreList.scores[i])
                }
                this.scoreData.userOperateScore = totalScore
                // console.log(this.scoreData.userOperateScore)
            },
            // isNumber(value) {
            //     return typeof value === 'number' && !isNaN(value);
            // },

            async submit() {
                if (this.advice != '') {
                    await submitAdvice({
                        examId: this.curUser.examId,
                        examNumber: this.curUser.examNumber,
                        advice: this.advice,
                    });

                    location.reload();
                }
            },
            async grade() {
                // console.log(this.scoreData.userOperateScore)
                await submitOperateScore({
                    examId: this.curUser.examId,
                    examNumber: this.curUser.examNumber,
                    score: this.scoreData.userOperateScore,
                    scoreList: this.userScoreList.scores
                });

                let res = await getExamUserScore({
                    examId: this.curUser.examId,
                    examNumber: this.curUser.examNumber,
                })
                this.scoreData = res.data
                // console.log(this.scoreData)
                this.chartOption.series[0].data = [
                    {value: this.scoreData.totalScores, name: '分值'},
                    {value: this.scoreData.scores, name: '得分'},
                    {value: this.scoreData.averageScores, name: '平均分'},
                ];

                this.curStep = 2;
                this.loadChart();
            },

            async gotoStep2() {
                let res = await getExamUserScore({
                    examId: this.curUser.examId,
                    examNumber: this.curUser.examNumber,
                })
                this.scoreData = res.data
                // console.log(this.scoreData)
                this.chartOption.series[0].data = [
                    {value: this.scoreData.totalScores, name: '分值'},
                    {value: this.scoreData.scores, name: '得分'},
                    {value: this.scoreData.averageScores, name: '平均分'},
                ];

                this.curStep = 2;
                this.loadChart();
            },

            // 用户重新刷新了页面，需要重新加载考卷数据
            async loadData() {
                let res = await getEvaluationUser();
                // console.log(res);
                if (res.data == 'Done') {
                    window.location.replace("/grade/done");
                } else if (res.data.examType == 1) {
                    window.location.replace("/grade/ide");
                } else if (res.data.examType == 2) {
                    window.location.replace("/grade/python");
                }
                this.curUser = res.data;
                // console.log(this.curUser);

                // 获取用户实操题数据
                res = await getExamUserRobotData4Examiner({examId: this.curUser.examId, examNumber: this.curUser.examNumber});
                // console.log('getExamUserRobotData4Examiner');
                // console.log(res);
                if (res.data) {
                    if (res.data.codeIds && (res.data.codeIds instanceof Array)) {
                        this.userData.codeName = res.data.codeName ? res.data.codeName : ''
                        this.userData.codeIds = res.data.codeIds
                    }
                    if (res.data.structureIds && (res.data.structureIds instanceof Array)) {
                        this.userData.structureIds = res.data.structureIds
                    }
                    if (res.data.effectIds && (res.data.effectIds instanceof Array)) {
                        this.userData.effectIds = res.data.effectIds
                    }
                }

                // 获取测评相关数据
                res = await getEvaluationData({examId: this.curUser.examId, examNumber: this.curUser.examNumber});
                // console.log('getEvaluationData')
                // console.log(res)
                this.data.name = res.data.name;
                if (res.data.instruction) {
                    this.data.instructions = res.data.instruction.split(/[\n]/)
                }
                if (res.data.evaluation) {
                    let evaluations = res.data.evaluation.split(/[\n]/)
                    for (let i = 0; i < evaluations.length; i++) {
                        if (evaluations[i] != '') {
                            let kv = evaluations[i].split(';');
                            this.data.evaluations[i] = {
                                'desc': kv[0],
                                'score': kv[1]
                            }
                        }
                    }
                }

                res = await getExamUserScore({
                    examId: this.curUser.examId,
                    examNumber: this.curUser.examNumber,
                })
                this.scoreData = res.data
                this.userScoreList.scores = this.scoreData.operateScores
                // console.log(this.userScoreList.scores)

                this.SetIsShowLoading(false);
            },

            selectSprite(id) {
                this.$refs.main.selectSprite(id);
            },
            deleteSprite(id) {
                this.$refs.main.deleteSprite(id);
            },
            // 切换舞台
            reloadStage(nav) {
                this.$refs.main.reloadStage(nav);
            },
            saveBlock() {
                this.$refs.main.saveBlock();
            },

            prompt() {
                this.$refs.refVarDlg.confirm().then(result => {
                    //console.log(result);
                    result.id = this.curActor.id;
                    this.resolve(result);
                }).catch(() => {
                    //
                });
                return new Promise((resolve, reject) => {
                    this.resolve = resolve;
                    this.reject = reject;
                });
            },
            setCurStep(curStep) {
                this.curStep = curStep;
                if (curStep == 2) {
                    this.gotoStep2();
                }
            },
            getUrl(key) {
                if (key) {
                    return 'https://assets.exam.koocoding.com/' + 'assets/images/' + key + '/get/';
                }
                return '';
            },
            downloadCode() {
                if (this.userData.codeIds.length > 0) {
                    // console.log(this.getUrl(this.userData.codeIds[0]))
                    window.open(this.getUrl(this.userData.codeIds[0]))
                }
            },
            showPreview(id) {
                this.imgPreview = this.getUrl(id);
                this.showImgPreview = true;
            },
            hidePreview() {
                this.showImgPreview = false;
                this.imgPreview = '';
            }
        },
        components: {
            Header,
            Loading,
            TopToast,
            // 'v-chart': ECharts
        }
    }

</script>

<style lang="less" scoped>
.ide {
    .area {
        position: absolute;
        top: 0;
        left: 0;
        right: 400px;
        bottom: 0;
        width: unset;

        .main-area {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: #1f1e2f;
            // padding: 25px 25px;
            overflow-y: auto;

            .row {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                .row-container {
                    width: 100%;
                    min-height: 150px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0 30px;
                    &.line {
                        border-bottom: 1px dashed #ccc;
                    }

                    .desc {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100px;
                        min-width: 100px;
                        .text {
                        font-size: 16px;
                        font-weight: bold;
                        color: #fff;
                        margin-bottom: 3px;
                        }
                        .text2 {
                        font-size: 14px;
                        font-weight: bold;
                        color: #ccc;
                        }
                    }

                    .upload-container {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        margin: 25px 40px 25px 25px;

                        .input-box {
                        margin-top: 5px;
                        .btn-add-file {
                            position: absolute;
                            background-color: #15141f;
                            background-color: #8a8a8a;
                            color: #fff;
                            // border: 1px solid #e6e6e6;
                            height: 60px;
                            width: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            img {
                            height: 40px;
                            width: 40px;
                            }
                        }
                        .input-file {
                            display: inline-block;
                            box-sizing: border-box;
                            width: 60px;
                            height: 60px;
                            opacity: 0;
                            cursor: pointer;
                        }
                        }
                    }

                    .src-code-name-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #fff;
                    }
                    .btn-download-code {
                        height: 35px;
                        width: 80px;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // font-weight: bold;
                        font-size: 14px;
                        border-radius: 2px;
                        background-color: #ffbf00;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                    .del-file {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 18px;
                        width: 18px;
                        margin: 2px 0 0 10px;
                        cursor: pointer;
                        img {
                        height: 100%;
                        width: 100%;
                        }
                    }

                    .user-img-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        margin: 10px 0;
                        .user-img {
                            height: 150px;
                            width: 200px;
                            background-color: #ddd;
                            border-radius: 4px;
                            margin: 10px 20px 10px 0;
                            position: relative;
                            cursor: pointer;
                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                            }
                            .img-mask {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba(0,0,0,0.8);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                opacity: 0;
                                .icon {
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 60px;
                                    width: 60px;
                                    border-radius: 100px;
                                    border: 2px solid #fff;
                                    img {
                                        height: 30px;
                                        width: 30px;
                                    }
                                }
                            }
                        }
                        .user-img:hover {
                            .img-mask {
                                opacity: 1;
                            }
                        }
                    }

                    .no-img {
                        color: #ccc;
                    }
                }
            }
        }
        .img-preview-mask {
            position: absolute;
            z-index: 2000;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(17, 17, 17, 0.8);
            opacity: 1;
            visibility: visible;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            .img-previw {
                position: absolute;
                top: 100px;
                left: 100px;
                right: 100px;
                bottom: 100px;
                // background-color: #000;
                background-color: #ddd;
                border-radius: 4px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    .evaluation-area {
        position: fixed;
        top: 0;
        right: 0;
        width: 400px;
        bottom: 0;
        background-color: #383838;
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        overflow-y: scroll;
        .header {
            height: 90px;
            // width: 400px;
            width: 385px;
            z-index: 1000;
            position: fixed;
            top: 0;
            // background-color: #303030;
            background-color: #15141f;
            display: flex;
            justify-content: center;
            align-items: center;
            .evaluation-score {
                display: flex;
                justify-content: center;
                align-items: baseline;
                color: #fff;
                // margin-right: 20px;
                .text {
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0 5px;
                }
                .score {
                    font-size: 60px;
                    font-weight: bold;
                }
            }
            .submit {
                height: 35px;
                width: 80px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                // padding: 8px 18px;
                // border: 2px solid #fff;
                border-radius: 2px;
                // background-color: #f4920f;
                // background-color: #ffc107;
                background-color: #ffbf00;
                cursor: pointer;
            }
        }
        .area {
            position: absolute;
            // top: 70px;
            top: 90px;
            left: 0;
            right: 0;
            bottom: 0;
            height: unset;
            .instruction {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 10px 15px;
                margin-top: 15px;
                .name {
                    font-weight: bold;
                    margin: 0 0 10px 0;
                }
                .content {
                    // margin: 8px 0;
                    margin-top: 2px;
                }
            }
            .evaluation {
                padding: 10px 15px;
                .name {
                }
                .block {
                    margin: 10px 0;
                    .desc {
                    }
                    .row {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin: 5px 0;
                        input[type=text] {
                            outline: none;
                            background-color: #515151;
                            color: #e6e6e6;
                            border: 0;
                            height: 28px;
                            width: 60px;
                            padding: 0 10px;
                            margin-right: 15px;
                            text-align: center;
                        }
                        .text {
                        }
                    }
                }
            }
            .chart-container {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                margin-top: 10px;
                .score-chart {
                    width: 350px;
                    height: 300px;
                    padding-top: 10px;
                    border-radius: 8px;
                    background-color: #fff;
                }
            }
            .score {
                padding: 10px 15px;
                margin-top: 15px;
                .row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    .score-block {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .score-type {
                            font-size: 12px;
                            padding: 5px 12px;
                            border-radius: 4px;
                            margin-right: 8px;
                            &.color1 {
                                background-color: #de4307;
                            }
                            &.color2 {
                                background-color: #f29c2b;
                            }
                            &.color3 {
                                background-color: #4695d6;
                            }
                            &.color4 {
                                background-color: #8bc24c;
                            }
                        }
                        .score-value {
                            font-size: 15px;
                            font-weight: bold;
                            width: 65px;
                            text-align: right;
                        }
                    }
                }
            }
            .advice {
                padding: 10px 15px;
                margin-top: 15px;
                textarea {
                    resize: none;
                    outline: none;
                    background-color: #515151;
                    color: #e6e6e6;
                    border: 0;
                    height: 200px;
                    width: 355px;
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
        }
    }

    .cur-obj {
        left: 20px!important;
    }
}
</style>