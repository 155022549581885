<template>
    <div class="exam-header-area">
        <div class="left-side">
            <div class="user">
                <!-- <div class="name">测评阅卷系统</div> -->
                <div class="no">待阅卷：{{grade.leftCount}}人</div>
            </div>
            <div class="padding"></div>
        </div>
        <!-- <div class="middle-side" v-if="showPages">
            <div class="page-idx">第{{index + 1}}位考生 / 共{{count}}位考生</div>
        </div> -->
        <div class="middle-side">测评阅卷系统</div>
        <div class="step-side" v-if="showPages">
            <!-- <div class="step" v-bind:class="{active: curStep == 1}" @click.stop="setCurStep(1)">实操评分</div>
            <div class="step" v-bind:class="{active: curStep == 2}" @click.stop="setCurStep(2)">学习建议</div> -->
            <div class="step" v-bind:class="{active: curStep == 1}" @click.stop="setCurStep(1)">
                <div class="step-index">1</div>
                <div class="step-desc">实操题评分</div>
            </div>
            <div class="step" v-bind:class="{active: curStep == 2}" @click.stop="setCurStep(2)">
                <div class="step-index">2</div>
                <div class="step-desc">填写评价及学习建议</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'ExamGradeHeader',
        props: {
            index: {
                default: 0
            },
            curStep: {
                default: 1
            },
            showPages: {
                default: true
            },
            showCmd: {
                default: false
            }
        },
        data: function () {
            return {
                img: {
                    logo: 'https://assets.koocoding.com/assets/images/common/logo_white_no_text.png',
                },
            }
        },
        computed: {
            ...mapGetters([
                'exam',
                'paper',
                'grade',
            ]),
        },
        methods: {
            setCurStep(curStep) {
                this.$emit('setCurStep', curStep);
            }
        }
    }
</script>

<style lang="less" scoped>
.exam-header-area {
    //height: 60px;
    // height: 70px;
    height: 90px;
    width: 100%;
    /*background-color: black;*/
    // background-color: #303030;
    background-color: #15141f;
    /*border-bottom: 1px solid #4e5154;*/
    //border-bottom: 1px solid #000000;
    display: flex;
    justify-content: space-between;
    /*color: white;*/
    color: #f6f6f6;
    font-size: 15px;
    //z-index: 102;
    z-index: 104;
    position: relative;

    //.top-toast {
    //  position: absolute;
    //  top: 9px;
    //  left: 50%;
    //  background-color: #2185cf;
    //  color: #e6e6e6;
    //  border-radius: 4px;
    //  font-size: 14px;
    //  padding: 5px 60px;
    //}
    
    .left-side {
        height: 100%;
        display: flex;
        //justify-content: space-between;
        justify-content: flex-start;
        align-items: center;
        z-index: 100;

        .nav {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .return {
                height: 100%;
                width: 50px;
                display: flex;
                align-items: center;
                //border-left: 1px solid #000000;
                border-right: 1px solid #4e5154;
                justify-content: center;
                z-index: 10;
                cursor: pointer;

                img {
                    height: 18px;
                    margin-top: 5px;
                }
            }
        }

        .logo {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-right: 1px solid #4e5154;
            padding: 0 15px;
            img {
                height: 50px;
                width: auto;
            }
        }

        .user {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border-right: 1px solid #4e5154;
            // padding: 0 25px;
            .name {
                font-size: 17px;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 3px;
            }
            .no {
                font-size: 16px;
                font-weight: bold;
                color: #e6e6e6;
                margin-left: 20px;
            }
        }

        .cmd {
            height: 100%;
            display: flex;
            //justify-content: flex-end;
            justify-content: flex-start;
            align-items: center;
            margin-left: 10px;

            .cmd-btn {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 60px;
                // width: 50px;
                //border-left: 1px solid #000;
                //border-right: 1px solid #4e5154;
                cursor: pointer;

                &.active {
                    background-color: #ea521f;

                    img {
                        height: 20px;
                        width: 20px;
                    }

                    .text {
                        color: #fefefe;
                    }
                }

                img {
                    // height: 18px;
                    height: 22px;
                    // width: 18px;
                    width: 22px;
                    margin: 3px 0 3px 0;
                }

                .text {
                    // font-size: 12px;
                    font-size: 14px;
                    color: #ddd;
                }
            }

            .cmd-btn:hover {
                background-color: rgba(100, 100, 100, 0.3);
            }
        }

        .autoSaveTip {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 20px;

            img {
                height: 15px;
                width: auto;
                margin-right: 4px;
            }

            .text {
                font-size: 12px;
                color: #bfbfbf;
                letter-spacing: .3px;
            }
        }
    }

    .middle-side {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 90;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 1px;

        .previous {
            height: 35px;
            width: 80px;
            border: 1px solid #fff;
            border-radius: 2px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            &.disabled {
                color: #525252;
                border-color: #525252;
                background-color: initial;
                cursor: not-allowed;
                opacity: .65;
            }
        }
        .previous:hover {
            background-color: #fff;
            color: #000;
        }

        .next {
            height: 35px;
            width: 80px;
            // border: 1px solid #fff;
            border-radius: 2px;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: 20px;
            background-color: #ffc107;
            &.disabled {
                color: #525252;
                border: 1px solid #525252;
                background-color: initial;
                cursor: not-allowed;
                opacity: .65;
            }
        }

        .previous-page {
            height: 35px;
            width: 35px;
            margin-right: 15px;
            cursor: pointer;
            img {
                height: 100%;
                width: 100%;
            }
        }

        .page-idx {
            color: #efefef;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 2px;
        }

        .next-page {
            height: 35px;
            width: 35px;
            margin-left: 15px;
            cursor: pointer;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .step-side {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
        z-index: 90;
        padding-right: 20px;
        .step {
            font-size: 15px;
            color: #fff;
            padding: 8px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;
            .step-index {
                font-size: 20px;
                font-weight: bold;
                margin-right: 10px;
            }
            .step-desc {
                font-size: 18px;
                font-weight: bold;
            }
            &.active {
                color: #FFBF00;
            }
        }
    }

    .right-side {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //border-left: 1px solid #4e5154;

        .section {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            //padding-right: 10px;
            border-right: 1px solid #4e5154;

            .text {
                font-size: 14px;
                margin-right: 15px;
            }

            .number {
                height: 24px;
                width: 24px;
                font-size: 14px;
                background-color: #000;
                border: 2px solid #f6f6f6;
                border-radius: 30px;
                margin-right: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.active {
                    background-color: #fb8b18;
                }
            }
        }

        // 目前只保留avatar头像
        .login {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 15px 0 15px;
            border-left: 1px solid #000000;
            border-right: 1px solid #4e5154;
            position: relative;
            z-index: 10;
            cursor: pointer;

            .avatar {
                padding-top: 3px;

                img {
                    height: 28px;
                    width: 28px;
                    border: 2px solid white;
                    border-radius: 35px;
                }
            }

            .nickname {
                color: white;
                /*font-size: 18px;*/
                margin-left: 10px;
            }

            .dropdown-menu {
                visibility: hidden;
                position: absolute;
                top: 65px;
                left: -2px;
                z-index: 100;
                background-color: #303030;
                border-left: 1px solid #4e5154;
                border-right: 1px solid #000000;
                border-bottom: 1px solid #4e5154;

                .pointer {
                    position: absolute;
                    top: -8px;
                    left: 60px;
                    border-right: 5px solid transparent;
                    border-bottom: 8px solid #303030;
                    border-left: 5px solid transparent;
                }

                .menu-grid {
                    border-left: 1px solid #000000;
                    border-right: 1px solid #4e5154;
                }

                .dropdown-menu-item {
                    //height: 40px;
                    width: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 5px 20px 5px 10px;
                    //border-left: 1px solid #000000;
                    //border-right: 1px solid #4e5154;

                    .item-img {
                        height: 16px;
                        width: 31px;
                        position: relative;
                        text-align: center;

                        img {
                            height: 18px;
                            margin-right: 5px;
                        }
                    }

                    .text {
                        max-width: 200px;
                        white-space: nowrap;
                    }
                }

                .padding {
                    height: 5px;
                }
            }

            //.dropdown-menu:hover {
            //  visibility: visible;
            //}
        }

        .help {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 12px 0 12px;
            border-left: 1px solid #000000;
            border-right: 1px solid #4e5154;
            z-index: 10;
            cursor: pointer;

            img {
                height: 20px;
                margin-right: 5px;
            }
        }

        .text {
            font-size: 18px;
            margin-left: 10px;
        }
    }

    .padding {
        height: 100%;
        width: 1px;
        border-left: 1px solid #000000;
    }
}

</style>
