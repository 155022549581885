<template>

    <div class="app-container">
        <div class="area">

            <Header :showPages="false"></Header>

            <div class="exam-container">
                <div class="exam-start-container ">
                    <div class="title">国际编程教育等级测评</div>
                    <div class="exam-end">测评阅卷完成，感谢您的辛勤付出！</div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import Header from '@/views/pages/ide/examGrade/Header'

    import { mapState, mapMutations, mapActions } from 'vuex';
    import { logout } from '@/api/user'

    export default {
        name: 'app',
        data: function() {
            return {
                img: {
                    logo: 'https://assets.koocoding.com/assets/images/common/logo_white_no_text.png',
                    return: "https://assets.koocoding.com/ide/images/return.svg",
                },
                showLoading: true,
            }
        },
        created() {
            this.logout()
        },
        mounted() {
        },
        activated() {
        },
        watch: {
            loadDataDone: function() {
                this.showLoading = false;
            },
            paperId: function() {
                // console.log(this.paper)
            }
        },
        computed: {
            ...mapState([
                'exam','paper','loadDataDone'
            ]),
            paperId: function() {
                return this.paper.paperId
            }
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
                'GetExamData'
            ]),
            logout() {
                logout()
            }
        },
        components: {
            Header,
        }
    }

</script>
