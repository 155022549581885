import * as apiRes from '@/api/resource'
import axios from 'axios'
import COS from 'cos-js-sdk-v5';

const Cos = {
    // cos: null,
    cosName: '',
    cosMap: {
        'project': null,
        'doc': null,
        'video': null,
    },
    config: {
        'project': {
            Bucket: 'project-1253970464',
            Region: 'ap-beijing',
            Prefix: 'project',
        },
        'doc': {
            Bucket: 'doc-1253970464',
            Region: 'ap-beijing',
            Prefix: 'slide',
        },
        'video': {
            Bucket: 'video-1253970464',
            Region: 'ap-beijing',
            Prefix: 'video',
        },
    },

    // 函数
    init(cosName) {
        if (!this.cosMap[cosName]) {
            this.cosMap[cosName] = new COS({
                getAuthorization: this.getAuth(cosName),
                UploadCheckContentMd5: true,
            });
        }
    },

    getAuth(cosName) {
        return function(options, callback) {
            // let res = await apiRes.getCosAuth();
            // let res = await apiRes.getCosAuthReadOnly();
            apiRes.getCosAuth(cosName).then(res => {
                if (res && res.data && res.data.credentials) {
                    callback({
                        TmpSecretId: res.data.credentials.tmpSecretId,
                        TmpSecretKey: res.data.credentials.tmpSecretKey,
                        XCosSecurityToken: res.data.credentials.sessionToken,
                        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                        StartTime: res.data.startTime, // 时间戳，单位秒，如：1580000000
                        ExpiredTime: res.data.expiredTime, // 时间戳，单位秒，如：1580000900
                        // ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
                    });
    
                } else {
                    return console.error('credentials invalid');
                }
            })
            .catch(function (err) {
                console.log(err);
                return console.error('catch err: ' + err);
            });
        }
    },

    async getObject(cosName, prefix, key) {
        return new Promise((resolve, reject) => {
            if (!this.cosMap[cosName]) {
                this.init(cosName)
            }
            if (this.cosMap[cosName]) {
                this.cosMap[cosName].getObject({
                    Bucket: this.config[cosName].Bucket,
                    Region: this.config[cosName].Region,
                    Key: prefix + '/' + key,
                    onProgress: function (progressData) {
                        // console.log(JSON.stringify(progressData));
                    }
                }, function (err, data) {
                    // console.log('getObject:', err || data);
                    if (err) {
                        resolve(null);
                    } else {
                        resolve(data);
                    }
                });
            }
        });
    },

    putObject(cosName, prefix, key, data, onProgress = null) {
        return new Promise((resolve, reject) => {
            if (!this.cosMap[cosName]) {
                this.init(cosName)
            }
            if (this.cosMap[cosName]) {
                this.cosMap[cosName].putObject({
                    Bucket: this.config[cosName].Bucket,
                    Region: this.config[cosName].Region,
                    Key: prefix + '/' + key,
                    Body: data,
                    onTaskReady: function (tid) {
                        // TaskId = tid;
                    },
                    onHashProgress: function (progress) {
                        // console.log('onHashProgress', JSON.stringify(progress));
                    },
                    onProgress: function (progress) {
                        // console.log(JSON.stringify(progress));
                        if (onProgress) {
                            onProgress(progress)
                        }
                    },
                }, function (err, data) {
                    // console.log('selectFileToUpload:', err || data);
                    if (err) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            }
        });
    },

    // 目前不用了
    // 保留，可以参考写法
    async getObjectFromUrl(key) {
        let baseUrl = 'https://project-1253970464.cos.ap-beijing.myqcloud.com/';

        return new Promise((resolve, reject) => {
            axios.get(baseUrl + 'project/' + key).then(res => {
                // console.log(res);
                if (res && res.status == 200 && res.data) {
                    resolve(res.data)
                } else {
                    resolve(null)
                }
            }).catch(function (err) {
                // console.log(err);
                resolve(null)
            });
        })
    },
}

export default Cos;
