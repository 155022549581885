import request from '@/utils/request'

/***
 * 用户登陆相关
 */
// Admin
export function adminCheckUser(username) {
    return request({
        url: '/api/admin/checkUser',
        method: 'post',
        data: { username }
    })
}

export function adminLogin({ username, password }) {
    return request({
        url: '/api/admin/login',
        method: 'post',
        data: { username, password }
    })
}

export function adminSmsLogin({ phone, code }) {
    return request({
        url: '/api/admin/smsLogin',
        method: 'post',
        data: { phone, code }
    })
}

// 用于之前没有填写姓名的Admin用户，补充姓名
export function adminLastLogin(name) {
    return request({
        url: '/api/admin/lastLogin',
        method: 'post',
        data: { name }
    })
}

export function adminSmsRegister({ phone, code, password, name }) {
    return request({
        url: '/api/admin/smsRegister',
        method: 'post',
        data: { phone, code, password, name }
    })
}

export function adminGetUserInfo() {
    return request({
        url: '/api/admin/getUserInfo',
        method: 'get'
    })
}

export function adminLogout() {
    return request({
        url: '/api/admin/logout',
        method: 'post',
    })
}

export function adminRefreshToken(refresh_token) {
    return request({
        url: '/api/admin/refresh',
        method: 'post',
        data: { refresh_token }
    })
}

export function adminResetPassword({ oldPassword, newPassword, newPassword2 }) {
    return request({
        url: '/api/admin/resetPassword',
        method: 'post',
        data: { oldPassword, newPassword, newPassword2 }
    })
}
// Admin端微信扫码关注登录
export function adminGetLoginQrcode() {
    return request({
        url: '/auth/adminGetLoginQrcode',
        method: 'get',
    })
}
export function adminQrcodeLogin() {
    return request({
        url: '/auth/adminQrcodeLogin',
        method: 'post',
    })
}



// User
export function userLogin({ username, password }) {
    return request({
        url: '/api/data/login',
        method: 'post',
        data: { username, password }
    })
}

export function userSmsLogin({ phone, code }) {
    return request({
        url: '/api/data/smsLogin',
        method: 'post',
        data: { phone, code }
    })
}

export function userSmsRegister({ phone, code, password }) {
    return request({
        url: '/api/data/smsRegister',
        method: 'post',
        data: { phone, code, password }
    })
}

export function getUserInfo() {
    return request({
        url: '/api/data/getUserInfo',
        method: 'get'
    })
}

export function refreshToken(refresh_token) {
    return request({
        url: '/api/data/refresh',
        method: 'post',
        data: { refresh_token }
    })
}

export function logout() {
    return request({
        url: '/api/data/logout',
        method: 'post',
    })
}

export function resetPassword({ oldPassword, newPassword, newPassword2 }) {
    return request({
        url: '/api/data/resetPassword',
        method: 'post',
        data: { oldPassword, newPassword, newPassword2 }
    })
}

// Wechat
export function getLoginQrcode() {
    return request({
        url: '/auth/getLoginQrcode',
        method: 'get',
    })
}

export function qrcodeLogin() {
    return request({
        url: '/auth/qrcodeLogin',
        method: 'post',
    })
}
