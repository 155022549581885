import request from '@/utils/request'

/***
 * 考试相关
 */
export function getExamData(examId) {
    return request({
        url: '/api/data/getExamData',
        method: 'get',
        params: { examId }
    })
}

export function getQuestion({ qid }) {
    return request({
        url: '/api/data/getQuestion',
        method: 'get',
        params: { qid }
    })
}

export function commitAnswer({ examId, qid, userAnswer }) {
    return request({
        url: '/api/data/commitAnswer',
        method: 'post',
        data: { examId, qid, userAnswer }
    })
}

export function endExam(examId) {
    return request({
        url: '/api/data/endExam',
        method: 'post',
        data: { examId }
    })
}

export function createExamUserPaper(examId) {
    return request({
        url: '/api/data/createExamUserPaper',
        method: 'post',
        data: { examId }
    })
}

export function getExamUserOperateData({ qid }) {
    return request({
        url: '/api/data/getExamUserOperateData',
        method: 'post',
        data: { qid }
    })
}
export function getExamUserPythonData({ qid }) {
    return request({
        url: '/api/data/getExamUserPythonData',
        method: 'post',
        data: { qid }
    })
}
export function getExamUserRobotData({ qid }) {
    return request({
        url: '/api/data/getExamUserRobotData',
        method: 'post',
        data: { qid }
    })
}


// TODO: 临时
export function saveExamUserRobotData({ qid, type, data }) {
    return request({
        url: '/api/data/saveExamUserRobotData',
        method: 'post',
        data: { qid, type, data }
    })
}
