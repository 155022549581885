import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import Vuetify from 'vuetify/lib/framework';
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify);

// 由Vuetify（javascript）提供的翻译
import zhHans from 'vuetify/es5/locale/zh-Hans'

// 由Vuetify（typescript）提供的翻译
// import pl from 'vuetify/src/locale/zh-Hans'

// 您自己的翻译文件
// import sv from './i18n/vuetify/sv'

const theme = {
    // primary: '#4CAF50',
    primary: '#9C27b0', //紫色
    secondary: '#9C27b0',
    accent: '#9C27b0',
    info: '#00CAE3',
}

export default new Vuetify({
    // lang: {
    //     t: (key, ...params) => i18n.t(key, params),
    // },
    lang: {
        locales: { zhHans },
        current: 'zhHans',
    },
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    },
});
