//////////////////////////
// Scratch核心数据
//////////////////////////

import $ from 'jquery';
import Ide from '@/libs/ide/ide.js';
import ToolboxData from '@/libs/runtime/toolboxData.js'
import { deepClone } from '@/utils/util.js';

/* eslint-disable no-undef */

// CoreData数据结构如下（包括变量和列表等）：
// coreData: {
//     version: "1.8.1",
//     actors: {
//         actor1: {
//             meta: {
//                 id: 'actorId',
//                 name: '',
//                 x: 0,
//                 y: 0,
//                 width: 0,
//                 height: 0,
//                 anchorX: 0,
//                 anchorY: 0,
//                 degree: 90,
//                 index: 0,
//                 size: 100,
//                 curCostumeIndex: 0,
//                 curCostumeId: 'costumeId', //TODO：删掉
//                 isShow: 'true',
//                 isDraggable: 'false',
//                 rotationMode: 0,
//                 isFlipHorizontal: 'false',
//                 isFlipVertical: 'false',
//             },
//             block: "",
//             costumes: [
//                 {
//                     id: '',
//                     name: '',
//                     type: 'img',
//                     aniName: '', //用于龙骨动画，暂时没用上 
//                 }
//             ],
//             sounds: [
//                 {
//                     id: '',
//                     name: '',
//                     type: 'sound',
//                     ext: 'mp3',
//                 }
//             ],
//             //本地变量id
//             variables: [],
//             //本地列表id
//             lists: [],
//             //角色区中的排序
//             index: 0,
//         },
//         actor2: {
//             //...
//         },
//     },
//     backdrop: {
//         meta: {},
//         block: "",
//         costumes: [],
//         sounds: [],
//         //本地变量id
//         variables: [],
//         //本地列表id
//         lists: [],
//     },
//     global: {
//          //全局变量id
//          variables: [],
//          //全局列表id
//          lists: [],
//     },
//     //所有变量/列表的具体信息
//     dataMap: {
//         vId1: {
//             id: '',
//             type: '', //空为变量
//             name: 'var1',
//             value: '', //对应初始值，不是运行中的数据
//             isLocal: false,
//             isCloud: false,
//             isShow: true,
//         },
//         vId2: {
//             id: '',
//             type: 'list',
//             name: 'list1',
//             value: [],
//             isLocal: false,
//             isCloud: false,
//             isShow: true,
//         },
//         vId3: {
//             id: '',
//             type: 'map',
//             name: 'map1',
//             value: {},
//             isLocal: false,
//             isCloud: false,
//             isShow: true,
//         },
//     },
// },
const CoreData = {
    demoData: null,
    userData: null,
    current: '',
    toolbox: '',
    version: '1.0.1',

    reset() {
        this.demoData = null;
        this.userData = null;
        this.current = '';
        this.toolbox = '';
    },

    // 函数
    getVersion() {
        return this.version;
    },

    /**
     * 读取/设置不同场景，如demo/user
     */
    setCurrent(current) {
        this.current = current;
    },
    getCurrent() {
        return this.current;
    },


    /**
     * 读取/设置不同数据，如demo/user所对应的数据
     */
    getCurData(current = null) {
        if (!current) {
            current = this.current;
        }
        if (!current) {
            return null;
        }

        switch (current) {
            case 'demo':
                if (!this.demoData) {
                    this.demoData = this._createData();
                }
                return this.demoData;
            case 'user':
                if (!this.userData) {
                    this.userData = this._createData();
                }
                return this.userData;
        }
        return null;
    },
    setData(current, data) {
        // if (!data) {
        //     return;
        // }

        let curData = this.getCurData(current);
        if (curData) {
            // 适配
            // if (data && Object.prototype.hasOwnProperty.call(data, 'data')) {
            //     data = data.data;
            // }
            // if (!data) {
            //     return;
            // }

            if (data.version) {
                curData.version = data.version;
            }

            curData.actors = {};
            for (let index in data.actors) {
                let actor = this._createActor();
                this._copyActor(actor, data.actors[index]);

                curData.actors[actor.meta.id] = actor;
            }

            curData.backdrop = this._createBackdrop();
            if (data.backdrop) {
                // curData.backdrop = data.backdrop;
                this._copyBackdrop(curData.backdrop, data.backdrop);
            }

            curData.global = this._createGlobal();
            if (data.global) {
                curData.global = deepClone(data.global);
            }

            if (data.dataMap) {
                curData.dataMap = deepClone(data.dataMap);
            }
        }
    },
    showData() {
        let curData = this.getCurData();
        console.log(curData);
    },

    setMeta(id, name, value) {
        if (id == 'backdrop') {
            return;
        }

        let curActor = this.getCurActor(id);
        if (curActor) {
            curActor.meta[name] = value;
        }
    },

    /**
     * 命令blocks
     */
    setToolbox(toolbox) {
        if (toolbox) {
            this.toolbox = toolbox;
        }
    },
    getToolbox() {
        return this.toolbox;
    },
    getToolbox4Default() {
        return ToolboxData.getToolbox4Default();
    },
    updateToolbox(type) {
        // 更新egret中状态，用于MenuOption
        MenuOptions.setType(type);

        if (type == 'backdrop') {
            Ide.workspace.updateToolbox(ToolboxData.getToolbox4Backdrop());
        } else if (type == 'actor') {
            Ide.workspace.updateToolbox(ToolboxData.getToolbox4Actor());
        }
    },


    /**
     * 快速读取，辅助函数
     */
    addActor(params) {
        let curData = this.getCurData();
        if (curData) {
            let actor = this._createActor();
            
            // let index = this._genUniqueIndex(curData, params['id']);
            actor.meta.id = this._genUniqueId(curData, params['id']);
            actor.meta.name = this._genUniqueActorName(curData, params['name']);
            actor.meta.curCostumeId = params['id'];

            actor.costumes.push({
                id: params['id'],
                name: params['name'],
                type: params['type'],
            })

            curData.actors[actor.meta.id] = actor;

            return actor;
        }
        return null;
    },
    copyActor(id) {
        let curData = this.getCurData();
        if (!curData) {
            return null;
        }

        let actor = this.getCurActor(id);
        if (actor) {
            let newActor = this._createActor();
            this._copyActor(newActor, actor);

            // 新的id
            let baseId = this._getBaseFromUniqueId(newActor.meta.id)
            newActor.meta.id = this._genUniqueId(curData, baseId);

            // 新的名字
            let baseName = this._getBaseFromUniqueActorName(newActor.meta.name)
            newActor.meta.name = this._genUniqueActorName(curData, baseName);

            // 将坐标稍稍向右下偏移一点，方便区分
            newActor.meta.x += 10;
            newActor.meta.y -= 10;

            curData.actors[newActor.meta.id] = newActor;

            return newActor;
        }
        return null;
    },
    genCostumeName(name) {
        let curData = this.getCurData();
        if (curData) {
            this._genUniqCostumeName(curData, name);
        }
    },

    getCurActor(id) {
        let curData = this.getCurData();
        if (curData) {
            return curData.actors[id];
        }
        return null;
    },
    getCurBackdrop() {
        let curData = this.getCurData();
        if (curData) {
            return curData.backdrop;
        }
        return null;
    },
    getCurCostume(id) {
        if (id == 'backdrop') {
            let curData = this.getCurData();
            if (curData.backdrop.costumes.length > 0) {
                return curData.backdrop.costumes[curData.backdrop.meta.curCostumeIndex];
            }
        } else {
            let actor = this.getCurActor(id);
            if (actor) {
                return actor.costumes[actor.meta.curCostumeIndex];
            }
        }
        return null;
    },
    getDataMap() {
        let curData = this.getCurData();
        if (curData) {
            return curData.dataMap;
        }
        return null;
    },


    /**
     * 内部函数
     */
    _createData() {
        return {
            version: "1.8.1",
            actors: {},
            backdrop: this._createBackdrop(),
            global: this._createGlobal(),
            dataMap: {},
        }
    },
    _createActor() {
        return {
            meta: {
                id: '',
                name: '',
                x: 0,
                y: 0,
                width: 0,
                height: 0,
                anchorX: 0,
                anchorY: 0,
                degree: 90,
                index: 0,
                size: 100,
                curCostumeIndex: 0,
                curCostumeId: '', //删掉
                isShow: 'true',
                isDraggable: 'false',
                rotationMode: 0,
                isFlipHorizontal: 'false',
                isFlipVertical: 'false',
            },
            block: "",
            costumes: [],
            sounds: [],
            //本地变量id
            variables: [],
            //本地列表id
            lists: [],
        }
    },
    _createBackdrop() {
        return {
            meta: {},
            block: "",
            costumes: [],
            sounds: [],
            //本地变量id
            variables: [],
            //本地列表id
            lists: [],
        }
    },
    _createGlobal() {
        return {
            // 全局变量id
            variables: [],
            // 全局列表id
            lists: [],
        }
    },
    _createCostume() {
        return {
            id: '',
            name: '',
            type: 'img',
        }
    },
    _createSound() {
        return {
            id: '',
            name: '',
            type: '',
            ext: '',
        }
    },
    _copyActor(_new, _old) {
        // if (_old.hasOwnProperty('meta')) {
        if (Object.prototype.hasOwnProperty.call(_old, 'meta')) {
            _new.meta.id = _old.meta.id;
            _new.meta.name = _old.meta.name;
            _new.meta.x = _old.meta.x;
            _new.meta.y = _old.meta.y;
            _new.meta.width = _old.meta.width;
            _new.meta.height = _old.meta.height;
            _new.meta.anchorX = _old.meta.anchorX;
            _new.meta.anchorY = _old.meta.anchorY;
            _new.meta.degree = _old.meta.degree;
            _new.meta.index = _old.meta.index;
            _new.meta.size = _old.meta.size;
            _new.meta.curCostumeIndex = _old.meta.curCostumeIndex;
            _new.meta.curCostumeId = _old.meta.curCostumeId;
            _new.meta.isShow = _old.meta.isShow;
            if (_old.meta.isDraggable) {
                _new.meta.isDraggable = _old.meta.isDraggable;
            }
            if (_old.meta.rotationMode) {
                _new.meta.rotationMode = _old.meta.rotationMode;
            }
            if (_old.meta.isFlipHorizontal) {
                _new.meta.isFlipHorizontal = _old.meta.isFlipHorizontal;
            }
            if (_old.meta.isFlipVertical) {
                _new.meta.isFlipVertical = _old.meta.isFlipVertical;
            }
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'block')) {
            _new.block = _old.block;
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'costumes')) {
            _new.costumes = deepClone(_old.costumes);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'sounds')) {
            _new.sounds = deepClone(_old.sounds);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'variables') && $.isArray(_old.variables)) {
            _new.variables = deepClone(_old.variables);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'lists') && $.isArray(_old.lists)) {
            _new.lists = deepClone(_old.lists);
        }
    },
    _copyBackdrop(_new, _old) {
        if (Object.prototype.hasOwnProperty.call(_old, 'meta')) {
            _new.meta = deepClone(_old.meta);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'block')) {
            _new.block = _old.block;
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'costumes')) {
            _new.costumes = deepClone(_old.costumes);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'sounds')) {
            _new.sounds = deepClone(_old.sounds);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'variables') && $.isArray(_old.variables)) {
            _new.variables = deepClone(_old.variables);
        }
        if (Object.prototype.hasOwnProperty.call(_old, 'lists') && $.isArray(_old.lists)) {
            _new.lists = deepClone(_old.lists);
        }
    },
    _genUniqueId(curData, id) {
        let index = 0;
        for (;;) {
            if (!curData.actors[id + '-' + index]) {
                return id + '-' + index;
            }
            index++;
        }
    },
    _genUniqueActorName(curData, name) {
        // 初始是"角色名"，如果遇到重复，变成"角色名2"，"角色名3"
        let index = 2;
        let uniqName = name;
        for (let id in curData.actors) {
            if (curData.actors[id].meta.name == uniqName) {
                uniqName = name + index;
                index++;
            }
        }
        return uniqName;
    },
    _genUniqCostumeName() {
        
    },
    _getBaseFromUniqueId(id) {
        if (id == '') {
            return ''
        }
        let ids = id.split('-')
        if (ids.length == 2) {
            return ids[0]
        }
        return id
    },
    _getBaseFromUniqueActorName(name) {
        if (name == '') {
            return ''
        }

        let last = name[name.length - 1]
        while (this._isNumber(last)) {
            name = name.substring(0, name.length - 1)
            last = name[name.length - 1]
        }
        return name
    },
    _isNumber(val) {
        if (parseFloat(val).toString() == "NaN") {
            return false;
        } else {
            return true;
        }
    },

    /**
     * 数据相关：变量/列表等
     * 创建、删除、重命名：变量/列表等
     */
    createData(id, type, name, isLocal, isCloud, isShow) {
        let curData = this.getCurData();
        if (curData) {
            // 已经存在
            // if (curData.dataMap.hasOwnProperty(id)) {
            if (Object.prototype.hasOwnProperty.call(curData.dataMap, id)) {
                // TODO：返回“已经存在”？
                // console.warn('id[' + id + '] aleady exists!');
                return;
            }

            // 初始值
            let initValue = '';
            switch (type) {
                case '':
                    initValue = 0;
                    break;
                case 'list':
                    initValue = [];
                    break;
                case 'map':
                    initValue = {};
                    break;
            }

            // 在dataMap中保存完整信息
            curData.dataMap[id] = {
                id: id,
                type: type,
                name: name,
                value: initValue,
                isLocal: isLocal,
                isCloud: isCloud,
                isShow: isShow,
            };

            if (isLocal) {
                let curActorId = window['curActorId']();
                if (curActorId == 'backdrop') {
                    let backdrop = this.getCurBackdrop();
                    if (backdrop) {
                        if (type == '') {
                            backdrop.variables.push(id);
                        } else if (type == 'list') {
                            backdrop.lists.push(id);
                        }
                    }
                } else {
                    let curActor = this.getCurActor(curActorId);
                    if (curActor) {
                        if (type == '') {
                            curActor.variables.push(id);
                        } else if (type == 'list') {
                            curActor.lists.push(id);
                        }
                        // window['setDataAreaData'](curActorId, id, curData.dataMap[id]);
                    }
                }
            } else {
                let curData = this.getCurData();
                // 全局变量
                if (type == '') {
                    curData.global.variables.push(id);
                } else if (type == 'list') {
                    curData.global.lists.push(id);
                }
            }
        }

        // 手动补充checkbox
        this.paddingCheckbox();

        // 更新展示区中的变量显示
        if (window.stageInstance) {
            window.stageInstance.showAllVars();
        }

        // log
        // this.showData();
    },
    deleteData(id, type, name, isLocal, isCloud) {
        // console.log('got deleteData @@@@');
        let curData = this.getCurData();
        if (curData) {
            // if (curData.dataMap.hasOwnProperty(id)) {
            if (Object.prototype.hasOwnProperty.call(curData.dataMap, id)) {
            
                delete curData.dataMap[id];
            }
        }

        let dataList = [];
        if (isLocal) {
            let curActorId = window['curActorId']();
            if (curActorId == 'backdrop') {
                let backdrop = this.getCurBackdrop();
                if (backdrop) {
                    if (type == '') {
                        dataList = backdrop.variables;
                    } else if (type == 'list') {
                        dataList = backdrop.lists;
                    }
                }
            } else {
                let curActor = this.getCurActor(curActorId);
                if (curActor) {
                    if (type == '') {
                        dataList = curActor.variables;
                    } else if (type == 'list') {
                        dataList = curActor.lists;
                    }
                }
            }
        } else {
            let curData = this.getCurData();
            // 全局变量
            if (type == '') {
                dataList = curData.global.variables;
            } else if (type == 'list') {
                dataList = curData.global.lists;
            }
        }
        for (let i = 0; i < dataList.length; i++) {
            if (dataList[i] == id) {
                dataList.splice(i, 1);
            }
        }

        // 更新展示区中的变量显示
        if (window.stageInstance) {
            window.stageInstance.showAllVars();
        }

        // log
        // this.showData();
    },
    renameData(id, type, name, isLocal, isCloud, newName) {
        // console.log('got renameData @@@@');
        let curData = this.getCurData();
        if (curData) {
            // if (curData.dataMap.hasOwnProperty(id)) {
            if (Object.prototype.hasOwnProperty.call(curData.dataMap, id)) {
                curData.dataMap[id].name = newName;
            }
        }
        // 更新展示区中的变量显示
        if (window.stageInstance) {
            window.stageInstance.showAllVars();
        }
    },
    // 设置变量显示
    setShowStatus(id, isShow) {
        let curData = this.getCurData();
        if (curData) {
            // if (curData.dataMap.hasOwnProperty(id)) {
            if (Object.prototype.hasOwnProperty.call(curData.dataMap, id)) {
                curData.dataMap[id].isShow = isShow;
            }
        }
        window.stageInstance.showAllVars();
    },
    // 用于在egret中获取当前需要显示的变量数据（程序未运行时）
    getShowDatas() {
        let datas = [];
        let curData = this.getCurData();
        if (curData) {
            for (let id in curData.dataMap) {
                if (curData.dataMap[id].type == "" && curData.dataMap[id].isShow) {
                    datas.push(curData.dataMap[id]);
                }
            }
        }
        return datas;
    },



    // save时保存数据
    getSaveData(current) {
        let curData = this.getCurData(current);
        if (curData) {
            // 从显示引擎更新最新的角色层级
            let idIndexMap = window.stageInstance.getSpritesIndex();
            for (let id in idIndexMap) {
                if (curData.actors[id]) {
                    curData.actors[id].meta.index = idIndexMap[id];
                }
            }

            return {
                backdrop: curData.backdrop,
                actors: curData.actors,
                global: curData.global,
                dataMap: curData.dataMap,
            }
        }
        return null;
    },
    saveBlock(actorId) {
        let curData = this.getCurData();
        if (actorId == 'backdrop') {
            curData.backdrop.block = Ide.workspace2block();
        } else if (actorId != '') {
            curData.actors[actorId].block = Ide.workspace2block();
        }
    },
    loadBlock(actorId) {
        let curData = this.getCurData();
        if (actorId == 'backdrop') {
            Ide.block2workspace(curData.backdrop.block);
        } else {
            Ide.block2workspace(curData.actors[actorId].block);
        }
    },

    // 必须当前Nav为demo时使用（需要从显示引擎更新角色层级）
    copyRes(from, to) {
        let fromData = this.getCurData(from)
        if (fromData) {
            // 从显示引擎更新最新的角色层级
            let idIndexMap = window.stageInstance.getSpritesIndex();
            for (let id in idIndexMap) {
                if (fromData.actors[id]) {
                    fromData.actors[id].meta.index = idIndexMap[id];
                }
            }

            this.setData(to, fromData)

            // 删除代码部分
            let toData = this.getCurData(to)
            if (toData) {
                toData.backdrop.block = ''
                for (let id in toData.actors) {
                    toData.actors[id].block = ''
                }
            }
        }
    },



    // 角色切换时，补全toolbox中的全局变量
    paddingGlobalDatas() {
        // Blockly.Events.disable();
        let curData = this.getCurData();
        for (let id in curData.dataMap) {
            let data = curData.dataMap[id];
            if (!data.isLocal) {
                Ide.workspace.createVariable(data.name, data.type, data.id, data.isLocal, data.isCloud);
                // console.log(variable);
            }
        }
        // Blockly.Events.enable();

        window.stageInstance.showAllVars();

        this.paddingCheckbox();
    },
    // 角色切换时，补全toolbox中的checkbox
    paddingCheckbox() {
        var flyout = Ide.workspace.getFlyout();
        if (!flyout || !flyout.setCheckboxState) {
            return;
        }

        let curData = this.getCurData();
        for (let id in curData.dataMap) {
            let data = curData.dataMap[id];
            if (data.isShow && data.type == "") {
                // console.log('show checkbox: ' + data.name);
                flyout.setCheckboxState(data.id, true);
            }
        }
    }
}

export default CoreData;
window.CoreData = CoreData;
