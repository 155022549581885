export const routes = [
    //------------------
    // 各种编辑器
    //------------------
    
    // Scratch
    //------------------
    // Scratch - Ide
    {
        path: '/ide/scratch',
        name: 'ide-scratch',
        component: () => import(/* webpackChunkName: "ide-scratch" */ '@/views/pages/ide/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/ide/scratch',
        name: 'iframe-ide-scratch',
        component: () => import(/* webpackChunkName: "iframe-ide-scratch" */ '@/views/pages/ide/scratch/Page'),
        meta: { keepAlive: false },
    },
    // Scratch - Classroom
    {
        path: '/classroom/scratch',
        name: 'classroom-scratch',
        component: () => import(/* webpackChunkName: "ide-scratch" */ '@/views/pages/ide/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/classroom/scratch',
        name: 'iframe-classroom-scratch',
        component: () => import(/* webpackChunkName: "iframe-ide-scratch" */ '@/views/pages/ide/scratch/Page'),
        meta: { keepAlive: false },
    },
    // Scratch - Editor
    {
        path: '/editor/scratch',
        name: 'editor-scratch',
        component: () => import(/* webpackChunkName: "ide-scratch" */ '@/views/pages/ide/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/editor/scratch',
        name: 'iframe-editor-scratch',
        component: () => import(/* webpackChunkName: "iframe-ide-scratch" */ '@/views/pages/ide/scratch/Page'),
        meta: { keepAlive: false },
    },
    // {
    //     path: '/editor/scratch',
    //     name: 'editor-scratch',
    //     component: () => import(/* webpackChunkName: "ide-scratch" */ '@/views/pages/ide/scratch/Page2'),
    //     meta: { keepAlive: false },
    // },
    // Scratch - Exercise
    {
        path: '/exercise/scratch',
        name: 'exercise-scratch',
        component: () => import(/* webpackChunkName: "ide-scratch" */ '@/views/pages/ide/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/exercise/scratch',
        name: 'iframe-exercise-scratch',
        component: () => import(/* webpackChunkName: "iframe-ide-scratch" */ '@/views/pages/ide/scratch/Page'),
        meta: { keepAlive: false },
    },
    // Scratch - Question 试题
    {
        path: '/question/scratch',
        name: 'question-scratch',
        component: () => import(/* webpackChunkName: "ide-scratch" */ '@/views/pages/ide/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/question/scratch',
        name: 'iframe-question-scratch',
        component: () => import(/* webpackChunkName: "iframe-ide-scratch" */ '@/views/pages/ide/scratch/Page'),
        meta: { keepAlive: false },
    },


    // Python
    //------------------
    // Python - Ide
    {
        path: '/ide/python',
        name: 'ide-python',
        component: () => import(/* webpackChunkName: "ide-python" */ '@/views/pages/ide/python/Page'),
        meta: { keepAlive: true },
    },
    // Python - Classroom
    {
        path: '/classroom/python',
        name: 'classroom-python',
        component: () => import(/* webpackChunkName: "ide-python" */ '@/views/pages/ide/python/Page'),
        meta: { keepAlive: true },
    },
    // Python - Editor
    {
        path: '/editor/python',
        name: 'editor-python',
        component: () => import(/* webpackChunkName: "ide-python" */ '@/views/pages/ide/python/Page'),
        meta: { keepAlive: true },
    },
    // Python - Exercise
    {
        path: '/exercise/python',
        name: 'exercise-python',
        component: () => import(/* webpackChunkName: "ide-python" */ '@/views/pages/ide/python/Page'),
        meta: { keepAlive: true },
    },


    // Python-Graphic
    //------------------
    // Python-Graphic - Ide
    {
        path: '/ide/python-graphic',
        name: 'ide-python-graphic',
        component: () => import(/* webpackChunkName: "ide-python-graphic" */ '@/views/pages/ide/pythonGraphic/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/ide/python-graphic',
        name: 'iframe-ide-python-graphic',
        component: () => import(/* webpackChunkName: "iframe-ide-python-graphic" */ '@/views/pages/ide/pythonGraphic/Page'),
        meta: { keepAlive: false },
    },


    // Python-Game
    //------------------
    // Python-Game - Ide
    // 编程游戏
    {
        path: '/ide/python-game',
        name: 'ide-python-game',
        component: () => import(/* webpackChunkName: "ide-python-game" */ '@/views/pages/ide/pythonGame/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/ide/python-game',
        name: 'iframe-ide-python-game',
        component: () => import(/* webpackChunkName: "iframe-ide-python-game" */ '@/views/pages/ide/pythonGame/Page'),
        meta: { keepAlive: false },
    },


    // Slide/Md
    //------------------
    // Slide/Md - Classroom
    {
        path: '/classroom/slide-md',
        name: 'classroom-slide-md',
        // 此处因为在classroom中revealjs只加载一次，不涉及多次加载的问题，所以可以直接用PageMd
        component: () => import(/* webpackChunkName: "ide-slide-md" */ '@/views/pages/ide/slide/slide-md/PageMd'),
        meta: { keepAlive: true },
    },
    // Slide/Md - Editor
    {
        path: '/editor/slide-md',
        name: 'editor-slide-md',
        // 此处使用wrapper为了解决revealjs库不支持页面重复加载的问题
        component: () => import(/* webpackChunkName: "ide-slide-md" */ '@/views/pages/ide/slide/slide-md/PageMdWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/editor/slide-md',
        name: 'iframe-editor-slide-md',
        component: () => import(/* webpackChunkName: "iframe-ide-slide-md" */ '@/views/pages/ide/slide/slide-md/PageMd'),
        meta: { keepAlive: true },
    },


    // Slide/PPT
    //------------------
    // Slide/PPT - Classroom
    {
        path: '/classroom/slide-ppt',
        name: 'classroom-slide-ppt',
        component: () => import(/* webpackChunkName: "ide-slide-ppt" */ '@/views/pages/ide/slide/slide-ppt/PagePPT'),
        meta: { keepAlive: false },
    },
    // Slide/PPT - Editor
    {
        path: '/editor/slide-ppt',
        name: 'editor-slide-ppt',
        component: () => import(/* webpackChunkName: "ide-slide-ppt" */ '@/views/pages/ide/slide/slide-ppt/PagePPT'),
        meta: { keepAlive: false },
    },


    // Video
    //------------------
    // Video - Classroom
    {
        path: '/classroom/video',
        component: () => import(/* webpackChunkName: "ide-video" */ '@/views/pages/ide/video/Page'),
        meta: { keepAlive: true },
    },
    // Video - Editor
    {
        path: '/editor/video',
        component: () => import(/* webpackChunkName: "ide-video" */ '@/views/pages/ide/video/Page'),
        meta: { keepAlive: true },
    },


    // Pdf
    //------------------
    // Pdf - Classroom
    {
        path: '/classroom/pdf',
        component: () => import(/* webpackChunkName: "ide-pdf" */ '@/views/pages/ide/pdf/Page'),
        meta: { keepAlive: true },
    },
    // Pdf - Editor
    {
        path: '/editor/pdf',
        component: () => import(/* webpackChunkName: "ide-pdf" */ '@/views/pages/ide/pdf/Page'),
        meta: { keepAlive: true },
    },
];

export default routes;
