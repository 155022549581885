export function isEmptyObject(e) {
    var t;
    for (t in e)
        return !1;
    return !0
}

// export function deepClone(obj) {
//     return JSON.parse(JSON.stringify(obj));
// }
export function deepClone(obj) {
    let newObj = Array.isArray(obj) ? [] : {}
    if (obj && typeof obj === "object") {
        for (let key in obj) {
            // if (obj.hasOwnProperty(key)) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newObj[key] = (obj && typeof obj[key] === 'object') ? deepClone(obj[key]) : obj[key];
            }
        }
    } 
    return newObj
}

export function goBack() {
    history.go(-1);
}

/**
 * 获取图片链接
 */

// 说明:
// mode: 1, 限定缩略图的宽最少为<Width>，高最少为<Height>，进行等比缩放，居中裁剪
// mode: 2, 限定缩略图的宽最多为<Width>，高最多为<Height>，进行等比缩放，不裁剪
export function getImgUrl(imgId, defaultImgId = "", width = 0, height = 0, mode = 1) {
    if (imgId == "" || imgId == null || imgId == undefined) {
        return defaultImgId
    }
    if (width == 0 && height == 0) {
        return 'https://assets.koocoding.com/assets/images/' + imgId + '/get/';
    } else {
        return 'https://assets.koocoding.com/assets/images/' + imgId + '/get/?imageView2/' + mode + '/w/' + width + '/h/' + height;
    }
}
export function getImgUrl2(imgId, width = 200, height = 200, mode = 2) {
    return 'https://assets.koocoding.com/assets/images/' + imgId + '/get/?imageView2/' + mode + '/w/' + width + '/h/' + height;
}
export function getImgUrl4Exam(imgId, width = 200, height = 200, mode = 2) {
    // return 'https://assets.exam.koocoding.com/' + imgId + '?imageView2/' + mode + '/w/' + width + '/h/' + height;
    return 'https://assets.koocoding.com/assets/exam/' + imgId + '/get/?imageView2/' + mode + '/w/' + width + '/h/' + height;
}

export function getAvatar(img) {
    if (img == "") {
        return 'https://assets.koocoding.com/common/images/default_avatar.svg';
    }
    return img;
}


/**
 * 判断机型
 */
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
}

/**
 * 判断手机号
 */
export function isPhone(phone) {
    if (phone == "") return false

    return /^1[3-9]\d{9}$/.test(phone)
}


/**
 * 字符串相关
 */
 export function getRandomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

export function getText(text, def) {
    if (text != "") {
        return text
    }
    return def
}

export function getTypeFirstLetter(name) {
    if (name == "") {
        return ""
    }
    return name.substring(0,1).toUpperCase()
}

export function getFileExtName(filename) {
    return filename.split('.').pop()
}

// 时间格式化
export function dateFormat(fmt, strTime) {
    let date = new Date(strTime)

    let reg;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (let k in opt) {
        reg = new RegExp("(" + k + ")").exec(fmt);
        if (reg) {
            fmt = fmt.replace(reg[1], (reg[1].length == 1) ? (opt[k]) : (opt[k].padStart(reg[1].length, "0")))
        }
    }
    return fmt;
}


/**
 * 
 */
export function getQuestionType(type) {
    switch (type) {
        case 1:
            return '选择'
        case 2:
            return '判断'
        case 3:
        case 4:
        case 5:
            return '实操'
        default:
            return '其他'
    }
}
