import request from '@/utils/request'

/***
 * PC Web界面
 */
// 获取主题列表
export function getTopicList() {
    return request({
        url: '/api/data/getTopicList',
        method: 'get'
    })
}
// 获取主题
export function getTopic(topicId) {
    return request({
        url: '/api/data/getTopic',
        method: 'get',
        params: { topicId }
    })
}
// 获取分组列表
export function getGroupList(topicId) {
    return request({
        url: '/api/data/getGroupList',
        method: 'get',
        params: { topicId }
    })
}
// 获取分组
export function getGroup(groupId) {
    return request({
        url: '/api/data/getGroup',
        method: 'get',
        params: { groupId }
    })
}
// 获取课程列表
export function getCourseList(groupId) {
    return request({
        url: '/api/data/getCourseList',
        method: 'get',
        params: { groupId }
    })
}
// 获取课程码课程
export function checkClassroomCode(code) {
    return request({
        url: '/api/data/checkClassroomCode',
        method: 'post',
        data: { code }
    })
}

// 获取“我的作品”
export function getMyProjects(type) {
    return request({
        url: '/api/data/getMyProjects',
        method: 'get',
        params: { type }
    })
}
// 删除作品
export function deleteProject({ pid, type }) {
    return request({
        url: '/api/data/deleteProject',
        method: 'post',
        data: { pid, type }
    })
}



/***
 * 班级相关
 */
// 获取班级信息
export function getClass(classId) {
    return request({
        url: '/api/data/getClass',
        method: 'get',
        params: { classId }
    })
}
// 获取班级分组列表
export function getClassGroupList(classId) {
    return request({
        url: '/api/data/getClassGroupList',
        method: 'get',
        params: { classId }
    })
}
// 获取分组
export function getClassGroup({ classId, groupId }) {
    return request({
        url: '/api/data/getClassGroup',
        method: 'get',
        params: { classId, groupId }
    })
}
// 获取课程列表
export function getClassCourseList({ classId, groupId }) {
    return request({
        url: '/api/data/getClassCourseList',
        method: 'get',
        params: { classId, groupId }
    })
}



/***
 * IDE，课程，相关
 */
export function getProjectInfo(pid) {
    return request({
        url: '/api/data/getProjectInfo',
        method: 'get',
        params: { pid }
    })
}
export function getCourseInfo(cid) {
    return request({
        url: '/api/data/getCourseInfo',
        method: 'get',
        params: { cid }
    })
}

export function getClassroomData({ cid, sid }) {
    return request({
        url: '/api/data/getClassroomData',
        method: 'get',
        params: { cid, sid }
    })
}
export function resetClassroomData({ cid, sid }) {
    return request({
        url: '/api/data/resetClassroomData',
        method: 'post',
        data: { cid, sid }
    })
}

export function getResources({ category, index, showCostume, keyword }) {
    return request({
        url: '/api/data/getResources',
        method: 'get',
        params: { category, index, showCostume, keyword }
    })
}
export function saveProjectName({ pid, name }) {
    return request({
        url: '/api/data/saveProjectName',
        method: 'post',
        data: { pid, name }
    })
}

export function genClassroomUserSectionId({ cid, sid }) {
    return request({
        url: '/api/data/genClassroomUserSectionId',
        method: 'post',
        data: { cid, sid }
    })
}

// 更新代码行数
export function updateCodeLineCount({ type, id, count }) {
    return request({
        url: '/api/data/updateCodeLineCount',
        method: 'post',
        data: { type, id, count }
    })
}

/**
 * python demo相关
 */
// 获取python demo示例数据（id列表）
export function getPythonDemo(sid) {
    return request({
        url: '/api/data/getPythonDemo',
        method: 'get',
        params: { sid }
    })
}

/**
 * 小节相关, 如PPT、视频、pdf等
 */
 export function getSectionInfo(sid) {
    return request({
        url: '/api/data/getSectionInfo',
        method: 'get',
        params: { sid }
    })
}
 export function genResId() {
    return request({
        url: '/api/data/genResId',
        method: 'post',
    })
}
export function saveMySectionRes({ cid, sid, id }) {
    return request({
        url: '/api/data/saveMySectionRes',
        method: 'post',
        data: { cid, sid, id }
    })
}



/**
 * 共用接口
 */
export function sendReleaseMsg({ type, category, id, cid }) {
    return request({
        url: '/api/data/sendReleaseMsg',
        method: 'post',
        data: { type, category, id, cid }
    })
}
export function createProject({ name, type }) {
    return request({
        url: '/api/data/createProject',
        method: 'post',
        data: { name, type }
    })
}
export function saveProjectCover(data) {
    return request({
        url: '/api/data/saveProjectCover',
        method: 'post',
        data: data
    })
}
