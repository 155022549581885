import request from '@/utils/request'

// 资源相关

export function getCosAuth(cosName) {
    return request({
        url: '/api/data/getCosAuth',
        method: 'get',
        params: { cosName }
    })
}
export function getCosAuthReadOnly() {
    return request({
        url: '/api/data/getCosAuthReadOnly',
        method: 'get'
    })
}



// 七牛上传Token
export function getUploadInfo() {
    return request({
        url: '/api/data/getUploadInfo',
        method: 'get',
    })
}
