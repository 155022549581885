<template>
  <v-row justify="center">
    <v-dialog
      :value="isShowReconnectDialog"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title class="card-title">
          登陆超时
        </v-card-title>

        <v-card-text class="card-text">
          因较长时间没有操作，您需要重新登陆才能继续
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="card-btn"
            color="blue darken-1"
            text
            @click.stop="confirm()"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "ReconnectDialog",
    computed: {
        ...mapGetters([
            'isShowReconnectDialog',
        ]),
    },
    methods: {
        cancel() {
            // 超时重连对话框不能关闭
            // this.$store.commit('web/SetShowReconnectDialog', false);
        },
        confirm() {
            // 用于取消页面离开提示框
            window.onbeforeunload = function () {
               // blank function do nothing
            }
            
            // 隐藏当前对话框
            this.$store.commit('web/SetShowReconnectDialog', false);

            // 跳转到首页
            window.location.href = '/';
        },
    },
};
</script>

<style lang="scss" scoped>
.card-title {
    font-size: 20px !important;
}
.card-text {
    font-size: 14px !important;
}
.card-btn {
    font-size: 14px !important;
}
</style>
