<template>
    <div class="exam-header-area">
        <div class="left-side">
            <div class="nav">
                <div class="return" @click.stop="goBack()">
                    <img src="@/assets/images/ide/return.svg">
                    <div class="text">返回</div>
                </div>
            </div>
        </div>
        <div class="middle-side" v-if="showPages">
            <div class="previous" v-bind:class="{disabled: paper && paper.index <= 0}" @click.stop="previous">上一题</div>
         
            <div class="page-idx">{{paper.index + 1}}/{{paper.count}}</div>

            <div class="next" v-if="paper.index + 1 >= paper.count" @click.stop="showHandInDlg">立即交卷</div>
            <div class="next" v-else @click.stop="next">下一题</div>

        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { getQuestion4Admin } from "@/api/admin_exam";
    import { goBack } from '@/utils/util'

    export default {
        name: 'Header',
        props: {
            showPages: {
                default: true
            },
            showCmd: {
                default: false
            },
            examId: {
                default: ""
            },
            paperId: {
                default: ""
            },
            qid: {
                default: ""
            },
        },
        data: function () {
            return {
                img: {
                    logo: 'https://assets.koocoding.com/assets/images/common/logo_white_no_text.png',
                    return: "https://assets.koocoding.com/ide/images/return.svg",
                    save: "https://assets.koocoding.com/ide/images/save.svg",
                
                    previous_page: '../../images/exam/previous_page.svg',
                    first_previous_page: '../../images/exam/first_previous_page.svg',
                    next_page: '../../images/exam/next_page.svg',
                    last_next_page: '../../images/exam/last_next_page.svg',
                },
            }
        },
        computed: {
            ...mapGetters(["exam", "paper"]),
        },
        created() {
        },
        activated() {
        },
        watch: {
        },
        methods: {
            goBack,
            async previous() {
                let previousQuestion = await this.GetPreviousQuestion()
                if (previousQuestion) {
                    this.goto(previousQuestion.question_type, previousQuestion.qid)
                }
            },
            async next() {
                let nextQuestion = await this.GetNextQuestion()
                if (nextQuestion) {
                    this.goto(nextQuestion.question_type, nextQuestion.qid)
                }
            },
            goto(type, qid) {
                console.log(type)
                console.log(qid)
                
                if (type == 1 || type == 2) {
                    this.$router.replace({path: '/exam/question', query: { examId: this.examId, paperId: this.paperId, qid: qid }});
                
                } else if (type == 3) {
                    this.$router.replace({ path: '/exam/scratch', query: { examId: this.examId, paperId: this.paperId, qid: qid}});
                
                } else if (type == 4) {
                    this.$router.replace({path: '/exam/python', query: { examId: this.examId, paperId: this.paperId, qid: qid}});
                
                } else if (type == 5) {
                    this.$router.replace({path: '/exam/robot', query: { examId: this.examId, paperId: this.paperId, qid: qid}});
                }
            },
            async GetPreviousQuestion() {
                if (this.paper.index - 1 >= 0) {
                    let qid = this.paper.questionIds[this.paper.index - 1]
                    if (this.paper.questionMap[qid] == undefined) {
                        let res = await getQuestion4Admin({
                            qid: qid,
                        });
                        this.paper.questionMap[qid] = res.data.question
                    }
                    return this.paper.questionMap[qid]
                }
                return null;
            },
            async GetNextQuestion() {
                if (this.paper.index + 1 < this.paper.count) {
                    let qid = this.paper.questionIds[this.paper.index + 1]
                    if (this.paper.questionMap[qid] == undefined) {
                        let res = await getQuestion4Admin({
                            qid: qid,
                        });
                        this.paper.questionMap[qid] = res.data.question
                    }
                    return this.paper.questionMap[qid]
                }
                return null;
            },
        },
        components: {
        }
    }
</script>

<style lang="scss" scoped>
.exam-header-area {
    //height: 60px;
    // height: 70px;
    height: 90px;
    width: 100%;
    /*background-color: black;*/
    // background-color: #303030;
    background-color: #15141f;
    /*border-bottom: 1px solid #4e5154;*/
    //border-bottom: 1px solid #000000;
    display: flex;
    justify-content: space-between;
    /*color: white;*/
    color: #f6f6f6;
    font-size: 15px;
    //z-index: 102;
    z-index: 104;
    position: relative;

    //.top-toast {
    //  position: absolute;
    //  top: 9px;
    //  left: 50%;
    //  background-color: #2185cf;
    //  color: #e6e6e6;
    //  border-radius: 4px;
    //  font-size: 14px;
    //  padding: 5px 60px;
    //}
    
    .left-side {
        height: 100%;
        display: flex;
        //justify-content: space-between;
        justify-content: flex-start;
        align-items: center;
        z-index: 100;

        .nav {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .return {
                height: 100%;
                // width: 50px;
                margin: 0 20px;
                display: flex;
                align-items: center;
                //border-left: 1px solid #000000;
                // border-right: 1px solid #4e5154;
                justify-content: center;
                z-index: 10;
                cursor: pointer;

                img {
                    height: 18px;
                    margin-top: 2px;
                }

                .text {
                    font-size: 18px;
                    margin-left: 10px;
                }
            }
        }

        .logo {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-right: 1px solid #4e5154;
            padding: 0 15px;
            img {
                height: 50px;
                width: auto;
            }
        }

        .user {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border-right: 1px solid #4e5154;
            // padding: 0 25px;
            .name {
                font-size: 17px;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 3px;
            }
            .no {
                font-size: 16px;
                color: #e6e6e6;
            }
        }

        .cmd {
            height: 100%;
            display: flex;
            //justify-content: flex-end;
            justify-content: flex-start;
            align-items: center;
            margin-left: 10px;

            .cmd-btn {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 60px;
                // width: 50px;
                //border-left: 1px solid #000;
                //border-right: 1px solid #4e5154;
                cursor: pointer;

                &.active {
                    background-color: #ea521f;

                    img {
                        height: 20px;
                        width: 20px;
                    }

                    .text {
                        color: #fefefe;
                    }
                }

                img {
                    // height: 18px;
                    height: 22px;
                    // width: 18px;
                    width: 22px;
                    margin: 3px 0 3px 0;
                }

                .text {
                    // font-size: 12px;
                    font-size: 14px;
                    color: #ddd;
                }
            }

            .cmd-btn:hover {
                background-color: rgba(100, 100, 100, 0.3);
            }
        }

        .autoSaveTip {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 20px;

            img {
                height: 15px;
                width: auto;
                margin-right: 4px;
            }

            .text {
                font-size: 12px;
                color: #bfbfbf;
                letter-spacing: .3px;
            }
        }
    }

    .middle-side {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 90;

        .previous {
            height: 35px;
            width: 80px;
            border: 1px solid #fff;
            border-radius: 2px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 20px;
            &.disabled {
                color: #525252;
                border-color: #525252;
                background-color: initial;
                cursor: not-allowed;
                opacity: .65;
            }
        }
        .previous:hover {
            background-color: #fff;
            color: #000;
        }

        .next {
            height: 35px;
            width: 80px;
            // border: 1px solid #fff;
            border-radius: 2px;
            color: #000;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-left: 20px;
            background-color: #ffc107;
            &.disabled {
                color: #525252;
                border: 1px solid #525252;
                background-color: initial;
                cursor: not-allowed;
                opacity: .65;
            }
        }

        .previous-page {
            height: 35px;
            width: 35px;
            margin-right: 15px;
            cursor: pointer;
            img {
                height: 100%;
                width: 100%;
            }
        }

        .page-idx {
            color: #efefef;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 2px;
        }

        .next-page {
            height: 35px;
            width: 35px;
            margin-left: 15px;
            cursor: pointer;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .step-side {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 90;
        margin-left: 150px;
        .step {
            font-size: 15px;
            color: #fff;
            padding: 8px 12px;
            // border: 1px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right: 10px;
            .step-index {
                font-size: 26px;
                font-weight: bold;
                margin-right: 10px;
            }
            .step-desc {
                font-size: 22px;
                font-weight: bold;
            }
            &.active {
                // color: #333;
                // background-color: #ccc;
                color: #FFBF00;
            }
        }
    }

    .right-side {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //border-left: 1px solid #4e5154;

        .section {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            //padding-right: 10px;
            border-right: 1px solid #4e5154;

            .text {
                font-size: 14px;
                margin-right: 15px;
            }

            .number {
                height: 24px;
                width: 24px;
                font-size: 14px;
                background-color: #000;
                border: 2px solid #f6f6f6;
                border-radius: 30px;
                margin-right: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.active {
                    background-color: #fb8b18;
                }
            }
        }

        // 目前只保留avatar头像
        .login {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 15px 0 15px;
            border-left: 1px solid #000000;
            border-right: 1px solid #4e5154;
            position: relative;
            z-index: 10;
            cursor: pointer;

            .avatar {
                padding-top: 3px;

                img {
                    height: 28px;
                    width: 28px;
                    border: 2px solid white;
                    border-radius: 35px;
                }
            }

            .nickname {
                color: white;
                /*font-size: 18px;*/
                margin-left: 10px;
            }

            .dropdown-menu {
                visibility: hidden;
                position: absolute;
                top: 65px;
                left: -2px;
                z-index: 100;
                background-color: #303030;
                border-left: 1px solid #4e5154;
                border-right: 1px solid #000000;
                border-bottom: 1px solid #4e5154;

                .pointer {
                    position: absolute;
                    top: -8px;
                    left: 60px;
                    border-right: 5px solid transparent;
                    border-bottom: 8px solid #303030;
                    border-left: 5px solid transparent;
                }

                .menu-grid {
                    border-left: 1px solid #000000;
                    border-right: 1px solid #4e5154;
                }

                .dropdown-menu-item {
                    //height: 40px;
                    width: auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 5px 20px 5px 10px;
                    //border-left: 1px solid #000000;
                    //border-right: 1px solid #4e5154;

                    .item-img {
                        height: 16px;
                        width: 31px;
                        position: relative;
                        text-align: center;

                        img {
                            height: 18px;
                            margin-right: 5px;
                        }
                    }

                    .text {
                        max-width: 200px;
                        white-space: nowrap;
                    }
                }

                .padding {
                    height: 5px;
                }
            }

            //.dropdown-menu:hover {
            //  visibility: visible;
            //}
        }

        .help {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 2px 12px 0 12px;
            border-left: 1px solid #000000;
            border-right: 1px solid #4e5154;
            z-index: 10;
            cursor: pointer;

            img {
                height: 20px;
                margin-right: 5px;
            }
        }

        .text {
            font-size: 18px;
            margin-left: 10px;
        }
    }

    .padding {
        height: 100%;
        width: 1px;
        border-left: 1px solid #000000;
    }
}
</style>