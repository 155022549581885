<template>
<keep-alive>
    <div class="exam-objective-choice">
        <div class="question-area">
            <div class="question-type">选择：</div>
            <div class="question-content">
                <div class="question-text">{{question.question_text}}</div>
                <div class="question-img" v-if="question.question_img" >
                    <img :src="getExamQuestionImgUrl(question.question_img)" v-if="question.question_img_height > 0"
                        v-bind:style="{'max-height': question.question_img_height + 'px'}" >
                    <img :src="getExamQuestionImgUrl(question.question_img)" v-else >
                </div>
            </div>
        </div>

        <div class="answer-area">
            <div class="answer-row">
                <div class="option" 
                    :class="{selected: question.user_answer == 'A', hover: curHover == 'A' && question.user_answer != 'A'}" 
                    @mouseover="curHover = 'A'" @mouseout="curHover = ''"
                    @click.stop="setSelected('A')">
                    <div class="option-idx">A</div>
                    <div class="option-content">
                        <div class="option-text" v-for="(line,index) in getContent(question.option_a_text)" v-bind:key="index">{{line}}</div>
                        <div class="option-img" v-if="question.option_a_img">
                            <img :src="getExamQuestionImgUrl(question.option_a_img)" v-if="question.option_a_img_height > 0"
                                  v-bind:style="{'height': question.option_a_img_height + 'px'}" >
                            <img :src="getExamQuestionImgUrl(question.option_a_img)" v-else >
                        </div>
                    </div>
                </div>
            </div>
            <div class="answer-row">
                <div class="option" 
                    :class="{selected: question.user_answer == 'B', hover: curHover == 'B' && question.user_answer != 'B'}" 
                    @mouseover="curHover = 'B'" @mouseout="curHover = ''"
                    @click.stop="setSelected('B')">
                    <div class="option-idx">B</div>
                     <div class="option-content">
                        <div class="option-text" v-for="(line,index) in getContent(question.option_b_text)" v-bind:key="index">{{line}}</div>
                        <div class="option-img" v-if="question.option_b_img">
                            <img :src="getExamQuestionImgUrl(question.option_b_img)" v-if="question.option_b_img_height > 0"
                                  v-bind:style="{'height': question.option_b_img_height + 'px'}" >
                            <img :src="getExamQuestionImgUrl(question.option_b_img)" v-else >
                        </div>
                    </div>
                </div>
            </div>
            <div class="answer-row">
                <div class="option" 
                    :class="{selected: question.user_answer == 'C', hover: curHover == 'C' && question.user_answer != 'C'}" 
                    @mouseover="curHover = 'C'" @mouseout="curHover = ''"
                    @click.stop="setSelected('C')">
                    <div class="option-idx">C</div>
                     <div class="option-content">
                        <div class="option-text" v-for="(line,index) in getContent(question.option_c_text)" v-bind:key="index">{{line}}</div>
                        <div class="option-img" v-if="question.option_c_img">
                            <img :src="getExamQuestionImgUrl(question.option_c_img)" v-if="question.option_c_img_height > 0"
                                  v-bind:style="{'height': question.option_c_img_height + 'px'}" >
                            <img :src="getExamQuestionImgUrl(question.option_c_img)" v-else >
                        </div>
                    </div>
                </div>
            </div>
            <div class="answer-row">
                <div class="option" 
                    :class="{selected: question.user_answer == 'D', hover: curHover == 'D' && question.user_answer != 'D'}" 
                    @mouseover="curHover = 'D'" @mouseout="curHover = ''"
                    @click.stop="setSelected('D')">
                    <div class="option-idx">D</div>
                     <div class="option-content">
                        <div class="option-text" v-for="(line,index) in getContent(question.option_d_text)" v-bind:key="index">{{line}}</div>
                        <div class="option-img" v-if="question.option_d_img">
                            <img :src="getExamQuestionImgUrl(question.option_d_img)" v-if="question.option_d_img_height > 0"
                                  v-bind:style="{'height': question.option_d_img_height + 'px'}" >
                            <img :src="getExamQuestionImgUrl(question.option_d_img)" v-else >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</keep-alive>
</template>

<script>
    import { commitAnswer } from "@/api/exam";

    export default {
        name: 'choice_3',
        props: [
            'question',
            'examId',
        ],
        data: function () {
            return {
                curHover: '',
            }
        },
        methods: {
            async setSelected(selected) {
                if (selected == this.question.user_answer) {
                    return
                }

                this.curHover = ''

                this.$store.commit("exam/SetQuestionAnswer", { qid: this.question.qid, userAnswer: selected })

                await commitAnswer({ examId: this.examId, qid: this.question.qid, userAnswer: selected })
            },
            getExamQuestionImgUrl(key) {
                if (key) {
                    return 'https://assets.exam.koocoding.com/' + key
                }
                return '';
            },
            wrap(str) {
                return str.replace(/\n/g,'<br/>');
            },
            getContent(text) {
                return text.split(/[\n]/)
            }
        }
    }
</script>

<style lang="less" scoped>
.exam-objective-choice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .question-area {
        font-size: 26px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;

        .question-type {
            width: 80px;
        }

        .question-content {
            margin-bottom: 50px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            // max-width: 600px;
            max-width: 770px;

            .question-text {
                margin-bottom: 15px;
                display: flex;
                justify-content: flex-start;
                align-content: flex-start;
                text-align: left;
            }

            .question-img {
                img {
                    max-height: 200px;
                    max-width: 600px;
                }
            }
        }
    }

    .answer-area {
        // margin-bottom: 50px;
        .answer-row {
            width: 100%;
            height: auto;
            display: flex;

            .option {
                // width: 300px;
                width: 520px;
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 30px;
                display: flex;
                padding: 15px 25px;
                border-radius: 4px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 12%);

                &.selected {
                    background-color: #f4920f;
                }

                &.hover {
                    background-color: rgba(150, 150, 150, 50%);
                }

                .option-idx {
                    // width: 35px;
                    margin-right: 15px;
                }

                .option-content {
                    .option-text {
                        margin-bottom: 10px;
                    }
                    .option-img {
                        width: auto;
                        img {
                            height: 60px;
                        }
                    }
                }
            }
            .option-padding {
                width: 50px;
            }
        }
    }
}
</style>
