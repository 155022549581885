<template>
    <div class="robot">
        <div class="area">
            <!-- <TopToast></TopToast> -->
            <Alert></Alert>
            
            <Header ref="header"
                :showPages="true"
                :showCmd="false"
                @showHandInDlg="showHandInDlg">
            </Header>

            <div class="main-area">

                <div id="task-area" class="task-area" v-if="isShowTask" >
                    <!-- <div v-html="instructionHtml"></div> -->
                    <!-- <div class="btn-collapse" @click.stop="hideTask()" >
                        <img :src="img.collapse" alt="">
                    </div> -->
                    <div class="task-title">
                        <img :src="img.require" alt="">
                        <div class="text">任务</div>
                    </div>
                    <!-- <div class="task-content">{{task.title}}</div> -->
                    <div class="task-content">{{this.paper.curQuestion.question_name}}</div>
                    <div class="task-title">
                        <img :src="img.goal" alt="">
                        <div class="text">目标</div>
                    </div>
                    <!-- <div class="task-content">{{task.content}}</div> -->
                    <!-- <div class="task-content">{{this.paper.curQuestion.question_text}}</div> -->
                    <div class="task-img" v-if="paper.curQuestion.question_img != ''">
                        <img :src="getExamQuestionImgUrl(paper.curQuestion.question_img)" alt="">
                    </div>
                    <div class="task-content" 
                        v-for="(line,index) in getContent(this.paper.curQuestion.question_text)"
                        v-bind:key="index"
                    >
                        {{ line }}
                    </div>
                </div>

                <div class="right-area">
                    <div class="row line">
                        <div class="desc">
                            <div class="text">程序设计</div>
                            <div class="text2">上传源码文件</div>
                        </div>
                        <div class="upload-container">
                            <form method="post" enctype="multipart/form-data" id="form" action="upload">
                                <div id="input-box" class="input-box">
                                    <div class="btn-add-file">
                                        <img :src="img.add_file" alt="">
                                    </div>
                                    <!--<input class="input-file" type="file" multiple="multiple" v-on:change="uploadFile($event)" >-->
                                    <input class="input-file" type="file" v-on:change="uploadFile('code', $event)" >
                                </div>
                            </form>
                        </div>
                        <div class="src-code-name-container">{{userData.codeName}}</div>
                        <div class="del-file" v-if="userData.codeIds.length > 0" @click.stop="deleteItem('code', '')">
                            <img :src="img.close" alt="">
                        </div>
                    </div>
                    <div class="row line">
                        <div class="desc">
                            <div class="text">结构搭建</div>
                            <div class="text2">上传3张图片</div>
                        </div>
                        <div class="upload-container">
                            <form method="post" enctype="multipart/form-data" id="form" action="upload">
                                <div id="input-box" class="input-box">
                                    <div class="btn-add-file">
                                        <img :src="img.add_file" alt="">
                                    </div>
                                    <!--<input class="input-file" type="file" multiple="multiple" v-on:change="uploadFile($event)" >-->
                                    <input class="input-file" type="file" v-on:change="uploadFile('structure', $event)" >
                                </div>
                            </form>
                        </div>
                        <div class="user-img-container">
                            <div class="user-img" 
                                v-for="id in userData.structureIds"
                                v-bind:key="id"
                            >
                                <img :src="getUrl(id)" alt="">
                                <div class="img-mask">
                                    <div class="icon" @click.stop="deleteItem('structure', id)">
                                        <img :src="img.delete" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="desc">
                            <div class="text">实现效果</div>
                            <div class="text2">上传1张图片</div>
                        </div>
                        <div class="upload-container">
                            <form method="post" enctype="multipart/form-data" id="form" action="upload">
                                <div id="input-box" class="input-box">
                                    <div class="btn-add-file">
                                        <img :src="img.add_file" alt="">
                                    </div>
                                    <!--<input class="input-file" type="file" multiple="multiple" v-on:change="uploadFile($event)" >-->
                                    <input class="input-file" type="file" v-on:change="uploadFile('effect', $event)" >
                                </div>
                            </form>
                        </div>
                        <div class="user-img-container">
                            <div class="user-img" 
                                v-for="id in userData.effectIds"
                                v-bind:key="id"
                            >
                                <img :src="getUrl(id)" alt="">
                                <div class="img-mask">
                                    <div class="icon" @click.stop="deleteItem('effect', id)">
                                        <img :src="img.delete" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loading></Loading>

        <!-- 交卷弹窗提示 -->
        <div class="dlg-wrapper" v-show="bShowHandInDlg" @click.stop="closeHandInDlg()">
            <div class="hand-in-container" @click.stop="noop()">
                <div class="tip">确认交卷后考试会立即结束</div>
                <!-- <div class="tip" style="margin-bottom: 25px;">你确定要现在交卷吗？</div> -->
                <div class="tip" style="margin-bottom: 25px;">请确认操作题试题已保存</div>
                <div class="op">
                    <div class="btn-op cancel" @click.stop="closeHandInDlg()">取消</div>
                    <div class="btn-op ok" @click.stop="handIn()">现在交卷</div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    /* eslint-disable no-undef */
    import Header from './Header';
    import Loading from '@/views/components/mask/Loading';
    import Alert from '@/views/components/widget/Alert';

    import { mapState, mapMutations, mapActions } from 'vuex';
    import { getExamUserRobotData } from '@/api/exam'
    import { getUploadInfo } from '@/api/resource'

    export default {
        name: 'app',
        data: function() {
            return {
                img: {
                    require: 'https://assets.koocoding.com/assets/python/require.svg',
                    task: 'https://assets.koocoding.com/assets/python/task.svg',
                    goal: 'https://assets.koocoding.com/assets/python/goal.svg',
                    collapse: 'https://assets.koocoding.com/assets/python/collapse.svg',
                    zoom: 'https://assets.koocoding.com/assets/python/zoom.svg',
                    refresh: 'https://assets.koocoding.com/assets/python/refresh.svg',
                    refresh_active: 'https://assets.koocoding.com/assets/python/refresh_active.svg',
                    add_file: 'https://assets.koocoding.com/ide/images/add_actor.png',
                    delete: '/images/icon/delete.svg',
                    close: '/images/icon/close.svg',
                },

                isRunning: false,
                // hasReset: true,
                curOutput: 'console',

                task: {
                    title: '',
                    content: '',
                },
                isShowTask: true,

                instruction: '',
                instructionHtml: '',

                qid: '',
                // curFile: null,
                isUploading: false,

                bShowHandInDlg: false,

                userData: {
                    codeIds: [],
                    codeName: '',
                    codeFile: null,

                    structureIds: [],
                    structureFile: null,

                    effectIds: [],
                    effectFile: null,
                },
            }
        },
        created() {
            if (this.$route.query.qid) {
                this.qid = this.$route.query.qid
            }
        },
        mounted() {
            this.loadData();
        },
        watch: {
        },
        computed: {
             ...mapState([
                'courseId','sectionId','ide','curNav','loadDataDone','initStageDone','courseData',
                'curActor','spriteData','isShowSetting','topToast',
                'exam','paper'
            ]),
        },
        methods: {
             ...mapMutations([
                'SetCourseId','SetSectionId',
                'InitStageDone','SetCurActorId','ResetSpriteData','UpdateSpriteData','DeleteSpriteData',
                'SetPropData','ShowWindow','SetCurrent',
                'SetIsShowLoading'
            ]),
            ...mapActions([
                'GetExamData', 'GetQuestionData', 'SetAlert'
            ]),

            async loadData() {
                // 用户重新刷新了页面，需要重新加载考卷数据
                if (this.paper.paperId == '') {
                    await this.GetExamData()
                }
                let question = this.paper.questionMap[this.qid]
                if (question && !question.level) {
                    await this.GetQuestionData({ qid: this.qid, type: question.type })
                }
                // this.UpdateExamPaperIndex(this.qid)

                let res = await getExamUserRobotData({ qid:this.qid });
                // console.log(res.data);
                if (res.data) {
                    if (res.data.codeIds && (res.data.codeIds instanceof Array)) {
                        this.userData.codeName = res.data.codeName ? res.data.codeName : ''
                        this.userData.codeIds = res.data.codeIds
                    }
                    if (res.data.structureIds && (res.data.structureIds instanceof Array)) {
                        this.userData.structureIds = res.data.structureIds
                    }
                    if (res.data.effectIds && (res.data.effectIds instanceof Array)) {
                        this.userData.effectIds = res.data.effectIds
                    }
                }

                this.SetIsShowLoading(false);
            },

            getContent(text) {
                if (text) {
                    return text.split(/[\n]/)
                }
                return ''
            },
            getExamQuestionImgUrl(key) {
                if (key) {
                    return 'https://assets.exam.koocoding.com/' + key
                }
                return '';
            },

            async uploadFile(type, event) {
                this.isUploading = true;

                let canUpload = true;
                let curFile = null;
                switch (type) {
                    case 'code':
                        if (this.userData.codeIds.length > 1) {
                            this.SetAlert({ type: 'error', msg: '只能上传一个程序源文件，请先删除不需要的文件' });
                            canUpload = false;
                        } else {
                            this.userData.codeFile = event.target.files[0];
                            this.userData.codeName = this.userData.codeFile.name;
                            curFile = this.userData.codeFile;
                        }
                        break;

                    case 'structure':
                        if (this.userData.structureIds.length >= 3) {
                            this.SetAlert({ type: 'error', msg: '最多上传三张结构搭建图片，请先删除不需要的文件' });
                            canUpload = false;
                        } else {
                            this.userData.structureFile = event.target.files[0];
                            curFile = this.userData.structureFile;
                        }
                        break;

                    case 'effect':
                        if (this.userData.effectIds.length >= 1) {
                            this.SetAlert({ type: 'error', msg: '只能上传一张实现效果图片，请先删除不需要的文件' });
                            canUpload = false;
                        } else {
                            this.userData.effectFile = event.target.files[0];
                            curFile = this.userData.effectFile;
                        }
                        break;
                }

                if (!canUpload) {
                    return;
                }

                // 获取token
                let res = await getUploadInfo();
                let tokenInfo = res.data
                if (tokenInfo.uptoken && tokenInfo.uuid) {
                    let key = 'assets/images/' + tokenInfo.uuid + '/get/';
                    this.uploadWithSDK(curFile, type, tokenInfo.uptoken, tokenInfo.uuid, key);
                }
            },
            uploadWithSDK(curFile, type, token, uuid, key) {
                let putExtra = {
                    fname: '',
                    params: {},
                    // mimeType: ["image/png", "image/jpeg", "image/jpg", "image/gif"]
                };
                let config = {
                    useCdnDomain: true,
                    disableStatisticsReport: false,
                    retryCount: 5,
                };

                var finishedAttr = [];
                var compareChunks = [];
                var observable;

                if (curFile) {
                    let that = this;
//                    key = this.curFile.name;
//                    putExtra.params["x:name"] = key.split(".")[0];
                    putExtra.params["x:name"] = key;

                    // 设置next,error,complete对应的操作，分别处理相应的进度信息，错误信息，以及完成后的操作
                    var error = function(err) {
                        console.log(err);
                        // alert("上传出错")
                    };
                    var complete = function(res) {
                        // console.log('上传完成！');
                        // 显示
                        that.uploadComplete(type, uuid);
                    };
                    var next = function(response) {
                        var chunks = response.chunks||[];
                        var total = response.total;
                        // 这里对每个chunk更新进度，并记录已经更新好的避免重复更新，同时对未开始更新的跳过
                        for (var i = 0; i < chunks.length; i++) {
                            if (chunks[i].percent === 0 || finishedAttr[i]){
                                continue;
                            }
                            if (compareChunks[i].percent === chunks[i].percent){
                                continue;
                            }
                            if (chunks[i].percent === 100){
                                finishedAttr[i] = true;
                            }
                            console.log('percent: ' + chunks[i].percent);
                        }
                        compareChunks = chunks;
                    };

                    var subObject = {
                        next: next,
                        error: error,
                        complete: complete
                    };
                    var subscription;
                    // 调用sdk上传接口获得相应的observable，控制上传和暂停
                    observable = qiniu.upload(curFile, key, token, putExtra, config);
                    subscription = observable.subscribe(subObject);

                    // 取消上传
                    // subscription.unsubscribe();
                }
            },
            uploadComplete(type, uuid) {
                switch (type) {
                    case 'code':
                        this.userData.codeIds.push(uuid);
                        break;
                    case 'structure':
                        this.userData.structureIds.push(uuid);
                        break;
                    case 'effect':
                        this.userData.effectIds.push(uuid);
                        break;
                }
                this.resetFileHandler(type);
                this.isUploading = false;

                this.SetAlert({ type: 'success', msg: '上传成功' });

                this.saveExamUserRobotData()
            },
            saveExamUserRobotData() {
                axios.post('/api/data/saveExamUserRobotData', {
                    qid: this.paper.curQuestion.qid,
                    type: this.paper.curQuestion.type,
                    data: {
                        'codeName': this.userData.codeName ? this.userData.codeName : '',
                        'codeIds': this.userData.codeIds,
                        'structureIds': this.userData.structureIds,
                        'effectIds': this.userData.effectIds,
                    },

                }).then((response) => {
                    //
                }).catch((err) => {
                    console.log(err);
                });
            },
            resetFileHandler(type) {
                switch (type) {
                    case 'code':
                        this.userData.codeFile = null;
                        break;
                    case 'structure':
                        this.userData.structureFile = null;
                        break;
                    case 'effect':
                        this.userData.effectFile = null;
                        break;
                }
            },
            deleteItem(type, id) {
                switch (type) {
                    case 'code':
                        this.userData.codeName = '';
                        this.userData.codeIds = [];
                        break;
                    case 'structure':
                        this.remove(this.userData.structureIds, id);
                        break;
                    case 'effect':
                        this.remove(this.userData.effectIds, id);
                        break;
                }
                this.SetAlert({ type: 'success', msg: '删除成功' });
                this.saveExamUserRobotData();
            },
            remove(arr, val) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i] == val) {
                        arr.splice(i, 1);
                        break;
                    }
                }
            },
            getUrl(key) {
                if (key) {
                    return 'https://assets.exam.koocoding.com/' + 'assets/images/' + key + '/get/';
                }
                return '';
            },

            // 交卷：弹窗提示
            handIn() {
                // console.log('hand in ~~~~');
                // self.$emit('autoSave')
                this.$router.push({ path: '/exam/end' });
            },
            showHandInDlg() {
                this.bShowHandInDlg = true;
            },
            closeHandInDlg() {
                this.bShowHandInDlg = false;
            },
            noop() {},
        },
        components: {
            Header,
            Loading,
            // TopToast,
            Alert,
        }
    }

</script>
