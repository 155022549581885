<template>
    <div class="top-toast" v-if="topToast != ''">
        <div class="icon"><img src="@/assets/images/ide/check.svg" /></div>
        <div class="text">{{ topToast }}</div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "topToast",
    computed: {
        ...mapGetters(["topToast"]),
    },
    created() {},
    mounted() {},
    activated() {},
    watch: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.top-toast {
    position: fixed;
    z-index: 5000;
    top: 35%;
    left: 50%;
    height: 120px;
    width: 120px;
    border-radius: 5px;
    background: rgba(17, 17, 17, 0.7);
    color: #efefef;
    margin-left: -60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon {
        height: 50px;
        width: 50px;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .text {
        font-size: 16px;
        margin-top: 8px;
        text-align: center;
    }
}
</style>
