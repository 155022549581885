import * as apiWeb from '@/api/web'
import * as apiAdmin from '@/api/admin'
import cos from '@/utils/cos'

import CoreData from '@/libs/runtime/coreData.js';
import Ide from '@/libs/ide/ide.js';
import Vue from 'vue';

/* eslint-disable no-undef */

const state = {

    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    // barImage: '/images/admin/sidebar-bg.jpeg', //可以不用了
    drawer: null,

}


const mutations = {

    ToggleDrawer: (state) => {
        state.drawer = !state.drawer;
    },
    SetDrawer: (state, val) => {
        state.drawer = val;
    },

}


const actions = {
    
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
