import axios from 'axios'
import store from '@/store'
import { getToken, setToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use( 
    config => {
        // do something before request is sent

        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // config.headers['X-Token'] = getToken()
            // 适配Laravel
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        // 判断一下响应中是否有 token，如果有就直接使用此 token 替换掉本地的 token
        var token = response.headers.authorization
        if (token) {
            // 如果 header 中存在 token，那么触发 refreshToken 方法，替换本地的 token
            store.dispatch('user/setToken', token.slice(7))
        }

        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 20000) {

            if (res.code === 401) {
                setToken('')
                store.commit('web/SetShowReconnectDialog', true);

                store.dispatch('user/logout').then(() => {
                    this.$alert('超时未操作，请重新登录。', '提示', {
                        confirmButtonText: '确定'
                    }).then(() => {
                        // location.reload()
                        window.location.href = '/login'
                    })
                })
            }

            console.log('RETURN CODE ERROR !!!')
            return Promise.reject(new Error(res.message || 'Error'))

        } else {
            return res
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '错误请求'
                    break;
                case 401:
                    error.message = '未授权，请重新登录'
                    //重置Cookie中的token
                    setToken('')
                    store.commit('web/SetShowReconnectDialog', true);
                    break;
                case 403:
                    error.message = '拒绝访问'
                    break;
                case 404:
                    error.message = '请求错误,未找到该资源'
                    break;
                case 405:
                    error.message = '请求方法未允许'
                    break;
                case 408:
                    error.message = '请求超时'
                    break;
                case 500:
                    error.message = '服务器端出错'
                    break;
                case 501:
                    error.message = '网络未实现'
                    break;
                case 502:
                    error.message = '网络错误'
                    break;
                case 503:
                    error.message = '服务不可用'
                    break;
                case 504:
                    error.message = '网络超时'
                    break;
                case 505:
                    error.message = 'http版本不支持该请求'
                    break;
                default:
                    error.message = `连接错误${error.response.status}`
            }
        } else {
            error.message = "连接到服务器失败"
        }

        return Promise.reject(error)
    }
)

export default service
