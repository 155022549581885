<template>
    <keep-alive>
        <div class="app-container">
            <div class="area">
                <Header ref="header" 
                    :showPages="true"
                    :examId="examId"
                    :paperId="paperId"
                    :qid="qid"
                >
                </Header>
                <div class="exam-container">
                    <template v-show="paper.curQuestion && paper.curQuestion.type == 1">
                        <Choice_1 :question="paper.curQuestion" :examId="examId" v-if="paper.curQuestion.option_show_type == 0"></Choice_1>
                        <Choice_2 :question="paper.curQuestion" :examId="examId" v-if="paper.curQuestion.option_show_type == 1"></Choice_2>
                        <Choice_3 :question="paper.curQuestion" :examId="examId" v-if="paper.curQuestion.option_show_type == 2"></Choice_3>
                    </template>
                    <TrueOrFalse :question="paper.curQuestion" :examId="examId" v-if="paper.curQuestion && paper.curQuestion.type == 2"></TrueOrFalse>
                </div>
            </div>
        </div>
    </keep-alive>
</template>

<script>
import Header from "@/views/pages/exam/Header";
import Choice_1 from "./children/Choice_1";
import Choice_2 from "./children/Choice_2";
import Choice_3 from "./children/Choice_3";
import TrueOrFalse from "./children/TrueOrFalse";

import { mapGetters } from "vuex";
import { getQuestion4Admin } from "@/api/admin_exam";

export default {
    name: "ExamQuestion",
    data: function () {
        return {
            examId: "",
            paperId: "",
            qid: "",
        };
    },
    activated() {
        if (this.$route.query.examId) {
            this.examId = this.$route.query.examId;
        }
        if (this.$route.query.paperId) {
            this.paperId = this.$route.query.paperId;
        }
        if (this.$route.query.qid) {
            this.qid = this.$route.query.qid;
        }
        this.loadData()
    },
    computed: {
        ...mapGetters(["exam", "paper"]),
    },
    beforeRouteUpdate(to, from, next) {
        // 解决路由重复问题，从to中读取参数，手动更新页面
        this.examId = to.query.examId
        this.paperId = to.query.paperId
        this.qid = to.query.qid

        this.loadData()

        next()
    },
    methods: {
        async loadData() {
            if (this.paper != "" && this.qid == "") {
                this.$store.commit("exam/ResetExamPaperData")
            }

            if (this.paper && this.paper.questionIds.length <= 0) {
                await this.$store.dispatch('exam/GetPaperData', this.paperId)

                if (this.qid == "" && this.paper.questionIds.length > 0) {
                    this.qid = this.paper.questionIds[0]
                }
            }
            await this.loadQuestion()
        },
        async loadQuestion() {
            if (!this.paper.questionMap[this.qid]) {
                let res = await getQuestion4Admin({
                    qid: this.qid,
                });

                this.$store.commit("exam/SetExamQuestionData", {
                    qid: this.qid,
                    question: res.data.question,
                });
            }
            this.$store.commit('exam/UpdateExamPaperIndex', this.qid);
        },
    },
    components: {
        Header,
        Choice_1,
        Choice_2,
        Choice_3,
        TrueOrFalse,
    },
};
</script>

<style lang="less" scoped>

.app-container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .area {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;

    .exam-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(50, 50, 50, 0.95);
      overflow: hidden;
      color: #efefef;

      .exam-start-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 48px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-bottom: 30px;
        }

        .exam-site {
          font-size: 26px;
          font-weight: bold;
          margin-bottom: 30px;
        }

        .exam-start-time {
          font-size: 20px;
          margin-bottom: 50px;
          color: #ccc;
        }

        .btn-start {
          font-size: 18px;
          font-weight: bold;
          padding: 12px 28px;
          border: 2px solid #efefef;
          border-radius: 50px;
          background-color: #f4920f;
          margin-bottom: 50px;
          cursor: pointer;
        }

        .exam-end {
          font-size: 40px;
          font-weight: bold;
          margin-bottom: 50px;
        }
      }

      .exam-objective-choice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .question-area {
          font-size: 26px;
          font-weight: bold;
          display: flex;
          justify-content: flex-start;

          .question-type {
            width: 80px;
          }

          .question-content {
            margin-bottom: 50px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            // max-width: 600px;
            max-width: 770px;

            .question-text {
              margin-bottom: 15px;
            }

            .question-img {
              img {
                  max-height: 200px;
                  max-width: 600px;
              }
            }
          }
        }

        .answer-area {
          // margin-bottom: 50px;

          .row {
            width: 100%;
            height: auto;
            display: flex;

            .option {
              // width: 300px;
              width: 520px;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 30px;
              display: flex;
              padding: 15px 25px;
              border-radius: 4px;
              cursor: pointer;
              background-color: rgba(0, 0, 0, 12%);

              &.selected {
                background-color: #f4920f;
              }

              &.hover {
                background-color: rgba(150, 150, 150, 50%);
              }

              .option-idx {
                // width: 35px;
                margin-right: 15px;
              }

              .option-content {

                .option-text {
                  margin-bottom: 10px;
                }

                .option-img {
                  width: auto;

                  img {
                    height: 60px;
                  }
                }
              }
            }

            .option-padding {
              width: 50px;
            }
          }
        }
      }

      .exam-objective-true-or-false {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .question-area {
          font-size: 26px;
          font-weight: bold;
          display: flex;
          justify-content: flex-start;

          .question-type {
            width: 80px;
          }

          .question-content {
            margin-bottom: 50px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            max-width: 600px;

            .question-text {
              margin-bottom: 15px;
            }

            .question-img {
              img {
                  max-height: 200px;
                  max-width: 600px;
              }
            }
          }
        }

        .answer-area {
          // margin-bottom: 50px;

          .row {
            width: 100%;
            height: auto;
            display: flex;

            .option {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 30px;
              display: flex;
              padding: 70px;
              border-radius: 4px;
              cursor: pointer;
              background-color: rgba(0, 0, 0, 12%);

              &.selected {
                background-color: #f4920f;
              }

              &.hover {
                background-color: rgba(150, 150, 150, 50%);
              }

              .option-content {
                width: auto;

                img {
                  height: 60px;
                }
              }
            }

            .option-padding {
              width: 70px;
            }
          }
        }

        .padding {
          height: 30px;
          width: 100%;
        }
      }
    }
  }
}
</style>
