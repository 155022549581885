import request from '@/utils/request'


// Dashboard相关
export function getAdminDashboard() {
    return request({
        url: '/api/admin/getAdminDashboard',
        method: 'get',
    })
}



// 商店相关
export function getAdminTopicStoreList() {
    return request({
        url: '/api/admin/getAdminTopicStoreList',
        method: 'get',
    })
}
export function getAdminTopicStoreInfo(topicId) {
    return request({
        url: '/api/admin/getAdminTopicStoreInfo',
        method: 'get',
        params: { topicId }
    })
}
export function getAdminTopicStoreGroupList(topicId) {
    return request({
        url: '/api/admin/getAdminTopicStoreGroupList',
        method: 'get',
        params: { topicId }
    })
}
export function getAdminTopicStoreGroupCourseRelations(id) {
    return request({
        url: '/api/admin/getAdminTopicStoreGroupCourseRelations',
        method: 'get',
        params: { id }
    })
}

export function getAdminTopicList(scope) {
    return request({
        url: '/api/admin/getAdminTopicList',
        method: 'get',
        params: { scope }
    })
}
export function buyAdminTopic(topicId) {
    return request({
        url: '/api/admin/buyAdminTopic',
        method: 'post',
        data: { topicId }
    })
}



// 课程包相关
export function getAdminTopic(topicId) {
    return request({
        url: '/api/admin/getAdminTopic',
        method: 'get',
        params: { topicId }
    })
}
export function editAdminTopic({ topicId, name, desc, cover, index }) {
    return request({
        url: '/api/admin/editAdminTopic',
        method: 'post',
        data: { topicId, name, desc, cover, index }
    })
}
export function delAdminTopic(topicId) {
    return request({
        url: '/api/admin/delAdminTopic',
        method: 'post',
        data: { topicId }
    })
}
export function onlineTopic(topicId) {
    return request({
        url: '/api/admin/onlineTopic',
        method: 'post',
        data: { topicId }
    })
}
export function offlineTopic(topicId) {
    return request({
        url: '/api/admin/offlineTopic',
        method: 'post',
        data: { topicId }
    })
}
export function duplicateAdminTopic(topicId) {
    return request({
        url: '/api/admin/duplicateAdminTopic',
        method: 'post',
        data: { topicId }
    })
}



// 主题分组相关
export function getAdminGroupList(id) {
    return request({
        url: '/api/admin/getAdminGroupList',
        method: 'get',
        params: { id }
    })
}
export function updateAdminGroupListIndex({id, groupIds}) {
    return request({
        url: '/api/admin/updateAdminGroupListIndex',
        method: 'post',
        data: { id, groupIds }
    })
}
export function editAdminGroup({ topicId, id, name, desc, cover, index }) {
    return request({
        url: '/api/admin/editAdminGroup',
        method: 'post',
        data: { topicId, id, name, desc, cover, index }
    })
}
export function delAdminGroup(id) {
    return request({
        url: '/api/admin/delAdminGroup',
        method: 'post',
        data: { id }
    })
}
// 主题分组中的课程列表
export function getAdminGroupCourseRelations(id) {
    return request({
        url: '/api/admin/getAdminGroupCourseRelations',
        method: 'get',
        params: { id }
    })
}
export function updataAdminGroupCourseRelations({id, courseIds}) {
    return request({
        url: '/api/admin/updataAdminGroupCourseRelations',
        method: 'post',
        data: { id, courseIds }
    })
}
export function delAdminGroupCourseRelation({id, cid}) {
    return request({
        url: '/api/admin/delAdminGroupCourseRelation',
        method: 'post',
        data: { id, cid }
    })
}
// 班级课程包相关
// 获取班级课程包中的课程列表（包含课程进度）
export function getAdminGroupCourseRelations4Class({ id, classId }) {
    return request({
        url: '/api/admin/getAdminGroupCourseRelations4Class',
        method: 'get',
        params: { id, classId }
    })
}
// 设置班级课程进度状态
export function setAdminClassCourseUnlocked({ classId, cid, isUnlocked }) {
    return request({
        url: '/api/admin/setAdminClassCourseUnlocked',
        method: 'post',
        data: { classId, cid, isUnlocked }
    })
}
// 获取班级课程包中的小节列表
export function getAdminSectionList4Class({ cid, classId }) {
    return request({
        url: '/api/admin/getAdminSectionList4Class',
        method: 'get',
        params: { cid, classId }
    })
}
// 设置班级课程进度状态
export function setAdminClassSectionUnlocked({ classId, cid, sid, isUnlocked }) {
    return request({
        url: '/api/admin/setAdminClassSectionUnlocked',
        method: 'post',
        data: { classId, cid, sid, isUnlocked }
    })
}



// 课程相关
export function getAdminAllCourseList({scope, courseType = ''}) {
    return request({
        url: '/api/admin/getAdminAllCourseList',
        method: 'get',
        params: { scope, courseType }
    })
}
export function getAdminCourseList(groupId) {
    return request({
        url: '/api/admin/getAdminCourseList',
        method: 'get',
        params: { groupId }
    })
}
export function getAdminCourse(cid) {
    return request({
        url: '/api/admin/getAdminCourse',
        method: 'get',
        params: { cid }
    })
}
export function editAdminCourse({ cid, name, courseType, desc, points, level, cover, groupId = "" }) {
    return request({
        url: '/api/admin/editAdminCourse',
        method: 'post',
        data: { cid, name, courseType, desc, points, level, cover, groupId }
    })
}
export function delAdminCourse({ groupId, cid }) {
    return request({
        url: '/api/admin/delAdminCourse',
        method: 'post',
        data: { groupId, cid }
    })
}
export function duplicateAdminCourse({ groupId, cid }) {
    return request({
        url: '/api/admin/duplicateAdminCourse',
        method: 'post',
        data: { groupId, cid }
    })
}
export function moveAdminCourse({ cid, groupId, newGroupId }) {
    return request({
        url: '/api/admin/moveAdminCourse',
        method: 'post',
        data: { cid, groupId, newGroupId }
    })
}
export function onlineCourse(cid) {
    return request({
        url: '/api/admin/onlineCourse',
        method: 'post',
        data: { cid }
    })
}
export function offlineCourse(cid) {
    return request({
        url: '/api/admin/offlineCourse',
        method: 'post',
        data: { cid }
    })
}
export function setAdminCourseOnline({ cid, status }) {
    return request({
        url: '/api/admin/setAdminCourseOnline',
        method: 'post',
        data: { cid, status }
    })
}
export function genClassroomCode(type, id) {
    return request({
        url: '/api/admin/genClassroomCode',
        method: 'post',
        data: { type, id }
    }) 
}



// 小节相关
export function getAdminSectionList(cid) {
    return request({
        url: '/api/admin/getAdminSectionList',
        method: 'get',
        params: { cid }
    })
}
export function getAdminSection(sid) {
    return request({
        url: '/api/admin/getAdminSection',
        method: 'get',
        params: { sid }
    })
}
export function editAdminSection({ sid, cid, name, type, subType, desc, content, blockScope, index }) {
    return request({
        url: '/api/admin/editAdminSection',
        method: 'post',
        data: { sid, cid, name, type, subType, desc, content, blockScope, index }
    })
}
export function delAdminSection({sid, cid}) {
    return request({
        url: '/api/admin/delAdminSection',
        method: 'post',
        data: { sid, cid }
    })
}
export function duplicateAdminSection({sid, cid}) {
    return request({
        url: '/api/admin/duplicateAdminSection',
        method: 'post',
        data: { sid, cid }
    })
}
export function moveAdminSection({ sid, cid, newCourseId }) {
    return request({
        url: '/api/admin/moveAdminSection',
        method: 'post',
        data: { sid, cid, newCourseId }
    })
}
export function setAdminSectionShow({ sid, cid, isShow }) {
    return request({
        url: '/api/admin/setAdminSectionShow',
        method: 'post',
        data: { sid, cid, isShow }
    })
}
export function updateAdminSectionListIndex({id, sectionIds}) {
    return request({
        url: '/api/admin/updateAdminSectionListIndex',
        method: 'post',
        data: { id, sectionIds }
    })
}



// 练习
export function getAdminPracticeList(type = '') {
    return request({
        url: '/api/admin/getAdminPracticeList',
        method: 'get',
        params: { type }
    })
}
export function getAdminPracticeStatList({ cid, classId }) {
    return request({
        url: '/api/admin/getAdminPracticeStatList',
        method: 'get',
        params: { cid, classId }
    })
}
export function getAdminPracticeUserList({ sid, classId }) {
    return request({
        url: '/api/admin/getAdminPracticeUserList',
        method: 'get',
        params: { sid, classId }
    })
}



// 班级相关
// 获取班级列表
export function getAdminClassList() {
    return request({
        url: '/api/admin/getAdminClassList',
        method: 'get',
    })
}
// 获取班级信息
export function getAdminClass(classId) {
    return request({
        url: '/api/admin/getAdminClass',
        method: 'get',
        params: { classId }
    })
}
// 编辑班级信息
export function editAdminClass({ classId, topicId, name, desc, cover, startDate, endDate, classTime, isCharge, price, status }) {
    return request({
        url: '/api/admin/editAdminClass',
        method: 'post',
        data: { classId, topicId, name, desc, cover, startDate, endDate, classTime, isCharge, price, status }
    })
}
// 删除班级
export function delAdminClass(classId) {
    return request({
        url: '/api/admin/delAdminClass',
        method: 'post',
        data: { classId }
    })
}
// 获取当前班级中的学生列表
export function getAdminClassStudentList(classId) {
    return request({
        url: '/api/admin/getAdminClassStudentList',
        method: 'get',
        params: { classId }
    })
}



// Classroom(获取上课用的课程数据)
export function getClassroomData({ cid, sid }) {
    return request({
        url: '/api/admin/getClassroomData',
        method: 'get',
        params: { cid, sid }
    })
}
export function resetClassroomData({ cid, sid }) {
    return request({
        url: '/api/admin/resetClassroomData',
        method: 'post',
        data: { cid, sid }
    })
}

// 保存scratch题中的blocks
export function saveQuestionBlocks({ qid, blocks }) {
    return request({
        url: '/api/admin/saveQuestionBlocks',
        method: 'post',
        data: { qid, blocks }
    })
}

export function md2html(data) {
    return request({
        url: '/api/admin/md2html',
        method: 'post',
        data: { data }
    })
}

// 创建新用户，用于给公立学校分配账号
export function createUserAccount({start, end}) {
    return request({
        url: '/api/admin/createUserAccount',
        method: 'post',
        data: { start, end }
    })
}



// 资源相关
export function getResources2({ scope, category }) {
    return request({
        url: '/api/admin/getResources2',
        method: 'get',
        params: { scope, category }
    })
}
export function editResource({ rid, name_cn, name_en, res_type, ext, category, group_ids, group_index }) {
    return request({
        url: '/api/admin/editResource',
        method: 'post',
        data: { rid, name_cn, name_en, res_type, ext, category, group_ids, group_index }
    })
}
export function delResource(rid) {
    return request({
        url: '/api/admin/delResource',
        method: 'post',
        data: { rid }
    })
}
export function deleteImage({ id }) {
    return request({
        url: '/api/admin/deleteImage',
        method: 'post',
        params: { id }
    })
}



// 学生相关
// 获取学生列表
export function getAdminStudentList(classId) {
    return request({
        url: '/api/admin/getAdminStudentList',
        method: 'get',
    })
}
// 更新学生信息
export function updateAdminStudent({ studentId, realName, phone }) {
    return request({
        url: '/api/admin/updateAdminStudent',
        method: 'post',
        data: { studentId, realName, phone }
    })
}
// 删除当前机构中的学生
export function delAdminStudent(studentId) {
    return request({
        url: '/api/admin/delAdminStudent',
        method: 'post',
        data: { studentId }
    })
}
// 向班级中添加学生
export function addAdminClassStudent({ classId, studentIds }) {
    return request({
        url: '/api/admin/addAdminClassStudent',
        method: 'post',
        data: { classId, studentIds }
    })
}
// 删除班级内的学生
export function delAdminClassStudent({ studentId, classId }) {
    return request({
        url: '/api/admin/delAdminClassStudent',
        method: 'post',
        data: { studentId, classId }
    })
}
export function getStudentExerciseList(uid = '') {
    return request({
        url: '/api/admin/getStudentExerciseList',
        method: 'get',
        params: { uid }
    })
}


/**
 * 机构相关
 */
// 机构用户相关
export function getAdminUserList() {
    return request({
        url: '/api/admin/getAdminUserList',
        method: 'get',
    })
}
// 机构角色相关
export function getAdminRoleList() {
    return request({
        url: '/api/admin/getAdminRoleList',
        method: 'get',
    })
}



// 获取小节基本信息
export function getSectionInfo(sid) {
    return request({
        url: '/api/data/getSectionInfo',
        method: 'get',
        params: { sid }
    })
}
export function getCourseInfo(cid) {
    return request({
        url: '/api/admin/getCourseInfo',
        method: 'get',
        params: { cid }
    })
}


/**
 * python demo相关
 */
// 获取python demo示例数据（id列表）
export function getAdminPythonDemo(sid) {
    return request({
        url: '/api/admin/getAdminPythonDemo',
        method: 'get',
        params: { sid : sid }
    })
}
// 创建一个新demo
export function addAdminPythonDemo(sid) {
    return request({
        url: '/api/admin/addAdminPythonDemo',
        method: 'post',
        data: { sid: sid }
    })
}
// 删除一个demo
export function delAdminPythonDemo({ sid, demoId }) {
    return request({
        url: '/api/admin/delAdminPythonDemo',
        method: 'post',
        data: { sid, demoId }
    })
}


/**
 * 资源相关, 如PPT、视频、pdf等
 */
 export function getAdminSectionData({ cid, sid }) {
    return request({
        url: '/api/admin/getAdminSectionData',
        method: 'get',
        params: { cid, sid }
    })
}
export function saveAdminSectionRes({ cid, sid, resId }) {
    return request({
        url: '/api/admin/saveAdminSectionRes',
        method: 'post',
        data: { cid, sid, resId }
    })
}
export function saveMySectionRes({ cid, sid, id }) {
    return request({
        url: '/api/admin/saveMySectionRes',
        method: 'post',
        data: { cid, sid, id }
    })
}


/**
 * 共用接口
 */
export function sendReleaseMsg({ type, category, id, cid }) {
    return request({
        url: '/api/admin/sendReleaseMsg',
        method: 'post',
        data: { type, category, id, cid }
    })
}
export function saveProjectCover(data) {
    return request({
        url: '/api/admin/saveProjectCover',
        method: 'post',
        data: data
    })
}
