<template>
    <div class="app-container">
        <div class="area">
            <!-- <Header ref="header" :showPages="false"></Header> -->
            <div class="exam-container">
                <div class="exam-start-container" v-if="exam.examName != ''">
                    <div class="exam-title">{{ exam.examTitle }}</div>
                    <div class="exam-name">【 {{ exam.examName }} 】</div>
                    <div class="btn-start" @click.stop="start">开始答题</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import screenfull from "screenfull";
import { mapGetters } from "vuex";

// import Header from "./Header";
// import { getExamData, createExamUserPaper } from "@/api/admin_exam";

export default {
    name: "ExamStart",
    data: function () {
        return {
            examId: "",
        };
    },
    created() {
    },
    activated() {
        if (this.$route.query.examId) {
            this.examId = this.$route.query.examId;
        }
        if (this.examId != "") {
            this.loadData();
        }
    },
    computed: {
        ...mapGetters(["exam", "paper"]),
    },
    methods: {
        async loadData() {
            await this.$store.dispatch('exam/GetExamData', this.examId)
        },
        start() {
            let qid = this.paper.questionIds[0];
            console.log(qid)
            this.$router.push({ path: "/exam/question", query: { examId: this.examId, qid: qid } });
        },
        /**
         * 全屏事件
         */
        screenfull() {
            if (screenfull.isEnabled && !screenfull.isFullscreen) {
                screenfull.request();
            }
        },
    },
    components: {
        // Header,
    },
};
</script>

<style lang="less" scoped>
.app-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .area {
        width: 100%;
        height: 100%;
        margin: 0;
        border: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        z-index: 100;

        .exam-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(50, 50, 50, 0.95);
            overflow: hidden;
            color: #efefef;

            .exam-start-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .exam-title {
                    font-size: 48px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    margin-bottom: 30px;
                }

                .exam-name {
                    font-size: 28px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    margin-bottom: 30px;
                }

                .exam-site {
                    font-size: 26px;
                    font-weight: bold;
                    margin-bottom: 30px;
                }

                .exam-start-time {
                    font-size: 20px;
                    margin-bottom: 50px;
                    color: #aaa;
                    letter-spacing: 1px;
                }

                .btn-start {
                    font-size: 18px;
                    font-weight: bold;
                    padding: 12px 35px;
                    border: 2px solid #efefef;
                    // border-radius: 50px;
                    background-color: #f4920f;
                    margin-bottom: 50px;
                    cursor: pointer;
                }

                .exam-end {
                    font-size: 40px;
                    font-weight: bold;
                    margin-bottom: 50px;
                }
            }
        }
    }
}
</style>
