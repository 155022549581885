// export default {
	
// }
const getters = {

    drawer: state => state.admin.drawer,
    barColor: state => state.admin.barColor,
    // barImage: state => state.admin.barImage,

    token: state => state.user.token,
    userInfo: state => state.user.userInfo,

    headerConfig: state => state.web.headerConfig,
    defaultHeaderConfig: state => state.web.defaultHeaderConfig,

    ide: state => state.web.ide,
    
    pid: state => state.web.pid,
    cid: state => state.web.cid,
    sid: state => state.web.sid,

    curEnv: state => state.web.curEnv,
    current: state => state.web.current,

    projInfo: state => state.web.projInfo,
    courseInfo: state => state.web.courseInfo,
    sectionInfo: state => state.web.sectionInfo,

    isRunning: state => state.web.isRunning,
    isReset: state => state.web.isReset,

    isShowDemoCode: state => state.web.isShowDemoCode,
    isShowLoading: state => state.web.isShowLoading,
    isShowReconnectDialog: state => state.web.isShowReconnectDialog,
    
    isShowSetting: state => state.web.isShowSetting,
    isShowMediaWindow: state => state.web.ide.window.isShowMediaLib,
    isShowConfigWindow: state => state.web.ide.window.isShowConfig,
    
    curMediaLibType: state => state.web.curMediaLibType,
    curMediaLibShowCostume: state => state.web.curMediaLibShowCostume,

    curActor: state => state.web.curActor,

    actorAreaData: state => state.web.actorAreaData,
    propData: state => state.web.propData,
    dataAreaData: state => state.web.dataAreaData,

    loadStageDone: state => state.web.loadStageDone,
    loadDataDone: state => state.web.loadDataDone,

    modalDlg: state => state.web.modalDlg,
    alert: state => state.web.alert,
    topMsg: state => state.web.topMsg,

    curLang: state => state.web.curLang,
    curCodeType: state => state.web.curCodeType,

    pyCurrent: state => state.web.pyCurrent,
    pyDemoIds: state => state.web.pyDemoIds,
    pyCurDemoId: state => state.web.pyCurDemoId,

    /***
     * 考试相关
     */
    exam: state => state.exam.exam,
    paper: state => state.exam.paper,
    grade: state => state.exam.grade,

    /***
     * 公共
     */
    clockData: state => state.web.clockData,


}
export default getters
