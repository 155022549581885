import request from "@/utils/request";

/***
 * 考官阅卷
 */
export function getGradeStudent(examId) {
    return request({
        url: "/api/admin/getGradeStudent",
        method: "get",
        params: { examId },
    });
}

export function getExamUserOperateData4Examiner({ examId, examNumber }) {
    return request({
        url: "/api/admin/getExamUserOperateData4Examiner",
        method: "get",
        params: { examId, examNumber },
    });
}

export function getExamUserRobotData4Examiner({ examId, examNumber }) {
    return request({
        url: "/api/admin/getExamUserRobotData4Examiner",
        method: "get",
        params: { examId, examNumber },
    });
}

export function getEvaluationData({ examId, examNumber }) {
    return request({
        url: "/api/admin/getEvaluationData",
        method: "get",
        params: { examId, examNumber },
    });
}

export function getEvaluationUser() {
    return request({
        url: "/api/admin/getEvaluationUser",
        method: "get",
    });
}

export function getExamUserScore({ examId, examNumber }) {
    return request({
        url: "/api/admin/getExamUserScore",
        method: "get",
        params: { examId, examNumber },
    });
}

export function submitOperateScore({ examId, examNumber, score, scoreList }) {
    return request({
        url: "/api/admin/submitOperateScore",
        method: "post",
        data: { examId, examNumber, score, scoreList },
    });
}

export function submitAdvice({ examId, examNumber, advice }) {
    return request({
        url: "/api/admin/submitAdvice",
        method: "post",
        data: { examId, examNumber, advice },
    });
}
