import request from '@/utils/request'

/*************
 * 试题库管理
 */
export function getQuestionLibDimensions(questionLibId) {
    return request({
        url: '/api/admin/getQuestionLibDimensions',
        method: 'get',
        params: { questionLibId }
    })
}
export function getQuestionLibs() {
    return request({
        url: '/api/admin/getQuestionLibs',
        method: 'get'
    })
}
export function getQuestionLib(id) {
    return request({
        url: '/api/admin/getQuestionLib',
        method: 'get',
        params: { id }
    })
}
export function editQuestionLib({ id, name, desc }) {
    return request({
        url: '/api/admin/editQuestionLib',
        method: 'post',
        data: { id, name, desc }
    })
}
export function delQuestionLib(id) {
    return request({
        url: '/api/admin/delQuestionLib',
        method: 'post',
        data: { id }
    })
}


/*************
 * 试题管理
 */
export function getNextQuestion(questionType, qid) {
    return request({
        url: '/api/admin/getNextQuestion',
        method: 'post',
        data: { questionType, qid }
    })
}
export function getPreviousQuestion(questionType, qid) {
    return request({
        url: '/api/admin/getPreviousQuestion',
        method: 'post',
        data: { questionType, qid }
    })
}


export function getQuestionsFromLib(questionLibId, questionType, type, level) {
    return request({
        url: '/api/admin/getQuestionsFromLib',
        method: 'get',
        params: { questionLibId, questionType, type, level }
    })
}
export function getQuestionFromLib(questionLibId, questionType, qid) {
    return request({
        url: '/api/admin/getQuestionFromLib',
        method: 'get',
        params: { questionLibId, questionType, qid }
    })
}
export function addQuestion(questionLibId, questionType, info) {
    return request({
        url: '/api/admin/addQuestion',
        method: 'post',
        data: { questionLibId, questionType, info }
    })
}
export function editQuestion(questionLibId, info) {
    return request({
        url: '/api/admin/editQuestion',
        method: 'post',
        data: { questionLibId, info }
    })
}
export function delQuestion(questionLibId, questionType, id) {
    return request({
        url: '/api/admin/delQuestion',
        method: 'post',
        data: { questionLibId, questionType, id }
    })
}


export function getPaperInfo(paperId) {
    return request({
        url: '/api/admin/getPaperInfo',
        method: 'get',
        params: { paperId }
    })
}
export function getPaperQuestions(paperId, curPage) {
    return request({
        url: '/api/admin/getPaperQuestions',
        method: 'get',
        params: { paperId, curPage }
    })
}
export function addPaperQuestion(paperId, qids) {
    return request({
        url: '/api/admin/addPaperQuestion',
        method: 'post',
        data: { paperId, qids }
    })
}
export function delPaperQuestion(paperId, qid) {
    return request({
        url: '/api/admin/delPaperQuestion',
        method: 'post',
        data: { paperId, qid }
    })
}


/**************
 * 考试/比赛/测评管理
 */
export function editExamination(info) {
    return request({
        url: '/api/admin/editExamination',
        method: 'post',
        data: { 'info': info }
    })
}

export function delExamination(id) {
    return request({
        url: '/api/admin/delExamination',
        method: 'post',
        data: { 'id': id }
    })
}

export function getExaminations() {
    return request({
        url: '/api/admin/getExaminations',
        method: 'get',
    })
}

export function getExamination(id) {
    return request({
        url: '/api/admin/getExamination',
        method: 'get',
        params: { id }
    })
}

// 获取参加测评的学生列表
export function getExamStudents(examId) {
    return request({
        url: '/api/admin/getExamStudents',
        method: 'get',
        params: { examId }
    })
}
export function addExamStudent(examId, studentIds) {
    return request({
        url: '/api/admin/addExamStudent',
        method: 'post',
        data: { examId, studentIds }
    })
}
export function delExamStudent(examId, studentId) {
    return request({
        url: '/api/admin/delExamStudent',
        method: 'post',
        data: { examId, studentId }
    })
}

export function getExamCandidates(institutionId, curPage, status = -1) {
    return request({
        url: '/api/admin/getExamCandidates',
        method: 'get',
        params: { institutionId, curPage, status }
    })
}

export function confirmPaid(institutionId, candidateId) {
    return request({
        url: '/api/admin/confirmPaid',
        method: 'post',
        data: { 'institutionId': institutionId, 'candidateId': candidateId }
    })
}

// 编辑赛事介绍
export function editContestInfo(contestId, content) {
    return request({
        url: '/api/admin/editContestInfo',
        method: 'post',
        data: { contestId, content }
    })
}
export function getContestInfo(contestId) {
    return request({
        url: '/api/admin/getContestInfo',
        method: 'get',
        params: { contestId }
    })
}


/**
 * 考卷管理
 */
export function editPaper(id, name, type, level) {
    return request({
        url: '/api/admin/editPaper',
        method: 'post',
        data: { id, name, type, level }
    })
}

export function delPaper(id) {
    return request({
        url: '/api/admin/delPaper',
        method: 'post',
        data: { 'id': id }
    })
}

export function getPapers() {
    return request({
        url: '/api/admin/getPapers',
        method: 'get',
    })
}

export function getPaper(id) {
    return request({
        url: '/api/admin/getPaper',
        method: 'get',
        params: { id }
    })
}

export function getOption4Papers(id) {
    return request({
        url: '/api/admin/getOption4Papers',
        method: 'get',
        params: { id }
    })
}


/**
 * 测评管理
 */
export function addSelectedExaminer(id, ids) {
    return request({
        url: '/api/admin/addSelectedExaminer',
        method: 'post',
        data: { id, ids }
    })
}
export function delSelectedExaminer(id, examinerId) {
    return request({
        url: '/api/admin/delSelectedExaminer',
        method: 'post',
        data: { id, examinerId }
    })
}
export function getSelectedExaminers(id) {
    return request({
        url: '/api/admin/getSelectedExaminers',
        method: 'get',
        params: { id }
    })
}


/**
 * 打分
 */
export function grade(examId) {
    return request({
        url: '/api/admin/grade',
        method: 'post',
        data: { examId }
    })
}
export function startGrade(examId) {
    return request({
        url: '/api/admin/startGrade',
        method: 'post',
        data: { examId }
    })
}
export function endGrade(examId) {
    return request({
        url: '/api/admin/endGrade',
        method: 'post',
        data: { examId }
    })
}
export function resetExamExaminer(examId) {
    return request({
        url: '/api/admin/resetExamExaminer',
        method: 'post',
        data: { examId }
    })
}


/***
 * 考试相关
 */
export function getExamData(examId) {
    return request({
        url: '/api/admin/getExamData',
        method: 'get',
        params: { examId }
    })
}

export function getPaperData(paperId) {
    return request({
        url: '/api/admin/getPaperData',
        method: 'get',
        params: { paperId }
    })
}

export function getQuestion4Admin({ qid }) {
    return request({
        url: '/api/admin/getQuestion4Admin',
        method: 'get',
        params: { qid }
    })
}

export function commitAnswer({ examId, qid, userAnswer }) {
    return request({
        url: '/api/admin/commitAnswer',
        method: 'post',
        data: { examId, qid, userAnswer }
    })
}

export function endExam(examId) {
    return request({
        url: '/api/admin/endExam',
        method: 'post',
        data: { examId }
    })
}

export function createExamUserPaper(examId) {
    return request({
        url: '/api/admin/createExamUserPaper',
        method: 'post',
        data: { examId }
    })
}

export function getExamUserOperateData({ qid }) {
    return request({
        url: '/api/admin/getExamUserOperateData',
        method: 'post',
        data: { qid }
    })
}
export function getExamUserPythonData({ qid }) {
    return request({
        url: '/api/admin/getExamUserPythonData',
        method: 'post',
        data: { qid }
    })
}
export function getExamUserRobotData({ qid }) {
    return request({
        url: '/api/admin/getExamUserRobotData',
        method: 'post',
        data: { qid }
    })
}
