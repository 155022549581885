'use strict';

import BlocklyHooker from '@/libs/ide/hooker.js';

import ace from 'ace-builds'
import 'ace-builds/webpack-resolver' // 在 webpack 环境中使用必须要导入
import 'ace-builds/src-noconflict/theme-monokai' // 默认设置的主题
import 'ace-builds/src-noconflict/mode-javascript' // 默认设置的语言模式
import CoreData from '../runtime/coreData';

/* eslint-disable no-undef */

//TODO: Ide中的方法和变量，需要重新封装到vue的blockly组件中
const Ide = {

    workspace: null,
    workspace4code: null,

    declarationWorkspace: null,
    mutationRoot: null,
    mutationCallback: null,

    editor: null,

    leftRightBorder: null,
    leftArea: null,
    rightArea: null,
    blocklyDiv: null,

    isMoving: false,

    updateSize(env) {
        // 调整分割线位置
        if (env == 'ide' || env == 'classroom') {
            this.leftRightBorder.style.left = this.leftArea.offsetWidth + 'px';
        }

        // 调整ide中展示区大小
        if (window.ideResize) {
            window.ideResize();
        }

        // 调整blockly区位置和大小
        if (this.workspace) {
            Blockly.svgResize(this.workspace);
        }

        // 调整代码区位置和大小
        if (this.editor) {
            this.editor.resize();
        }

        // 调整展示区位置和大小
        if (window.stageInstance && window.stageInstance.updateStage) {
            window.stageInstance.updateStage();
        }
    },
    initDom(env) {
        if (env == 'ide' || env == 'classroom') {
            this.leftRightBorder = document.getElementById("left-right-border");
            this.leftArea = document.getElementById("left-area");
            this.rightArea = document.getElementById("right-area");
            this.blocklyDiv = document.getElementById('blockly-div');
        }
    },
    // 将积木区代码转成展示性代码，显示到Editor
    workspace2code() {
        if (this.editor) {
            let code = Blockly.JavaScript.workspaceToCode(this.workspace);
            // 更新代码区，此处参数-1的作用是把光标置于第一行的开头
            this.editor.setValue(code, -1);
        }
    },
    // 说明：似乎不需要了，先屏蔽掉
    generateCode(event) {
        if (event.type == Blockly.Events.MOVE) {
            // console.log('@@@ from generateCode');
            this.workspace2code();

            // 通知vue中的方法，更新&保存workspace中的内容到actors数组中
            // window['saveBlock']();
        }
    },
    updateHistoryStatus(event) {
        if (event.type == Blockly.Events.MOVE) {
            let showHistoryBack = false;
            if (this.workspace.undoStack_.length > 0) {
                showHistoryBack = true;
            }

            let showHistoryForward = false;
            if (this.workspace.redoStack_.length > 0) {
                showHistoryForward = true;
            }

            window['updateHistoryStatus'](showHistoryBack, showHistoryForward);
        }
    },
    init(env = 'ide', showToolbox = true, toolbox = null) {
        var that = this;

        this.initDom(env);

        // window.addEventListener('resize', this.onresize, false);
        window.onresize = function (env) {
            if (that.workspace) {
                that.updateSize(env);
            }
        };

        // 调整blockly工具栏大小
        let startScale = 1;
        let isControl = true;
        if (env == 'classroom') {
            // 调整样式
            Blockly.Toolbox.prototype.width = 310;
            Blockly.VerticalFlyout.prototype.DEFAULT_WIDTH = 200;
            startScale = 0.8;

        } else if (env == 'ide') {
            Blockly.Toolbox.prototype.width = 345;
            Blockly.VerticalFlyout.prototype.DEFAULT_WIDTH = 260;
            startScale = 0.75;

        } else if (env == 'mobile') {
            startScale = 0.7;
            isControl = false;
        }

        // let toolbox = null;
        if (showToolbox && !toolbox) {
            toolbox = CoreData.getToolbox4Default();
        }

        // console.log(Blockly.Toolbox.prototype.width);
        // console.log(Blockly.VerticalFlyout.prototype.DEFAULT_WIDTH)
        // console.log(startScale);

        BlocklyHooker.init();

        var locale = 'zh-cn'; // or 'en' for English
        Blockly.ScratchMsgs.setLocale(locale);

        // 初始化blockly(包括工具栏和积木区)
        this.workspace = Blockly.inject('blockly-div', {
            comments: true,
            disable: false,
            collapse: false,
            media: '/scratch-blockly/media/',
            readOnly: false,
            rtl: false,
            scrollbars: true,
            // trashcan: true, //未生效
            toolbox: toolbox,
            // toolboxPosition: side == 'top' || side == 'start' ? 'start' : 'end',
            toolboxPosition: 'start',
            // horizontalLayout: side == 'top' || side == 'bottom',
            horizontalLayout: false,
            sounds: true,
            grid: {
                spacing: 55,
                length: 55,
                colour: '#4e4f51',
                snap: true
            },
            zoom: {
                controls: isControl,
                wheel: true,
                startScale: startScale,
                maxScale: 1.5,
                minScale: 0.5,
                scaleSpeed: 1.2
            },
            colours: {
                fieldShadow: 'rgba(255, 255, 255, 0.3)',
                dragShadowOpacity: 0.6
            }
        });

        this.workspace4code = Blockly.inject('blockly-code-generator', {
            comments: true,
            disable: false,
            collapse: false,
            media: '/scratch-blockly/media/',
            readOnly: false,
            rtl: false,
            scrollbars: false,
            toolbox: null,
            // toolboxPosition: side == 'top' || side == 'start' ? 'start' : 'end',
            toolboxPosition: 'start',
            // horizontalLayout: side == 'top' || side == 'bottom',
            horizontalLayout: false,
            sounds: false,
            grid: null,
            zoom: null
        });

        // Add to reserved word list: Local variables in execution environment (runJS)
        // and the infinite loop detection function.
        // Blockly.JavaScript.STATEMENT_PREFIX = 'highlightBlock(%1);\n';
        // Blockly.JavaScript.addReservedWords('highlightBlock');
        // Blockly.JavaScript.addReservedWords('code,timeouts,checkTimeout');

        // 初始化Editor
        if (env == 'ide' || env == 'classroom') {
            this.editor = ace.edit("code-editor");
            if (this.editor) {
                // this.editor.setTheme("ace/theme/monokai");
                // this.editor.setTheme("ace/theme/twilight");
                this.editor.setTheme("ace/theme/tomorrow_night_eighties");
                this.editor.session.setMode("ace/mode/javascript");
                this.editor.setReadOnly(true);
                this.editor.session.setUseWrapMode(true);
                this.editor.setOptions({
                    selectionStyle: 'text',
                    hScrollBarAlwaysVisible: false,
                    vScrollBarAlwaysVisible: false,
                    fontSize: 18,
                    showLineNumbers: true,
                    showFoldWidgets: false,
                    highlightActiveLine: false,
                    displayIndentGuides: false,
                    showInvisibles: false,
                    showPrintMargin: false,
                    printMargin: false,
                    printMarginColumn: false,
                    fixedWidthGutter: false,
                    wrap: false,
                    // spellcheck: false,
                    // enableBasicAutocompletion: false,
                    // enableLiveAutocompletion: false,
                    // enableSnippets: false,
                });
                // 关闭ace的语法检查器，避免代码展示中不必要的语法检查
                this.editor.getSession().setUseWorker(false);
            }
        }

        // 下面这一段删掉？？？
        // // 初始化分割条，注册事件
        // if (env == 'ide' || env == 'classroom') {
        //     this.initBorderDragger(env);
        //     // 注册更新代码Handler
        //     this.workspace.addChangeListener(that.generateCode.bind(this));
        //     // 更新历史命令状态Handler
        //     this.workspace.addChangeListener(this.updateHistoryStatus.bind(this));
        // }

        // 更新历史命令状态Handler
        this.workspace.addChangeListener(this.updateHistoryStatus.bind(this));

        // 刷新布局
        this.updateSize(env);
    },
    init2(env = 'ide') {
        var that = this;

        this.initDom(env);

        // window.addEventListener('resize', this.onresize, false);
        window.onresize = function (env) {
            that.updateSize(env);
        };

        // 初始化分割条，注册事件
        if (env == 'ide' || env == 'classroom') {
            this.initBorderDragger(env);
        }

        // 刷新布局
        this.updateSize(env);
    },


    dispose() {
        if (this.workspace) {
            this.workspace.dispose()
            this.workspace = null
        }
        if (this.workspace4code) {
            this.workspace4code.dispose()
            this.workspace4code = null
        }
        if (this.declarationWorkspace) {
            this.declarationWorkspace.dispose()
            this.declarationWorkspace = null
        }
        if (this.mutationRoot) {
            this.mutationRoot = null
        }
        if (this.mutationCallback) {
            this.mutationCallback = null
        }
        if (this.editor) {
            this.editor.destroy();
            this.editor = null
        }
    },
    setLocale(locale) {
        this.workspace.getFlyout().setRecyclingEnabled(false);
        var xml = Blockly.Xml.workspaceToDom(this.workspace);
        Blockly.ScratchMsgs.setLocale(locale);
        Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, this.workspace);
        this.workspace.getFlyout().setRecyclingEnabled(true);
    },
    // 清除积木区代码
    clearWorkspace() {
        if (this.workspace) {
            this.workspace.clear();
        }
    },
    // 更新命令工具栏
    // refreshToolbox: () => {
    //     this.workspace.refreshToolboxSelection_();
    // },
    // 将block转成xml
    workspace2block() {
        let xml = Blockly.Xml.workspaceToDom(this.workspace);
        return Blockly.Xml.domToText(xml);
    },
    // 将xml转成block
    block2workspace(xmlText) {
        let xml = Blockly.Xml.textToDom(xmlText);
        Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, this.workspace);
    },
    
    // 生成待执行代码
    workspace2runCode(xmlText) {
        if (xmlText && xmlText != '') {
            this.workspace4code.clear();

            let xml = Blockly.Xml.textToDom(xmlText);
            Blockly.Xml.domToWorkspace(xml, this.workspace4code);

            return Blockly.JavaScript.workspaceToRunCode(this.workspace4code);
        }
    },
    // 在scratch-blockly代码中调用，正在测试是否可以去掉？看起来可以
    // saveCode: () => {
    //     // 将积木代码同步到代码区
    //     this.workspace2code();
    //     // 通知vue中的方法，更新&保存workspace中的内容到actors数组中
    //     window['saveBlock']();
    // },
    initBorderDragger(env) {
        var that = this;

        if (env == 'ide' || env == 'classroom') {
            this.isMoving = false;

            this.leftRightBorder.onmousedown = function(e){
                if(!this.isMoving){
                    this.startInfo = {
                        pageX: e.pageX, 
                        pageY: e.pageY, 
                        rightAreaWidth: this.rightArea.offsetWidth
                    };
                    this.isMoving = true;
                }
            };
            // window.addEventListener('onmousemove', function moving(e) {
            document.onmousemove = function(e){
                if (that.isMoving) {
                    if (e.pageX >= 200 && e.pageX <= 600) {
                        that.leftArea.style.width = e.pageX + "px";
                        that.leftRightBorder.style.left = that.leftArea.offsetWidth + "px";

                    } else if (e.pageX < 200) {
                        that.leftArea.style.width = 200 + "px";
                        that.leftRightBorder.style.left = that.leftArea.offsetWidth + "px";

                    } else if (e.pageX > 600) {
                        that.leftArea.style.width = 600 + "px";
                        that.leftRightBorder.style.left = that.leftArea.offsetWidth + "px";
                    }

                    that.updateSize(env);
                }
            };
            // window.addEventListener('onmouseup', function moveEnd(e) {
            document.onmouseup = function(e){
                if (that.isMoving) {
                    that.isMoving = false;
                    that.updateSize(env);
                }
            };
        }
    }
}

export default Ide;
