import Cookies from 'js-cookie'

const TokenKey = 'Koocoding-Token'
const RefreshTokenKey = 'Koocoding-Refresh-Token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    Cookies.set(TokenKey, token)
}

export function getRefreshToken() {
    return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
    Cookies.set(RefreshTokenKey, token)
}

export function removeToken() {
    Cookies.remove(TokenKey)
    Cookies.remove(RefreshTokenKey)
}
