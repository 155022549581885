<template>
    <!--添加角色窗口-->
    <div v-show="isShowLoading" class="loading">
        <div class="screen-mask"></div>
        <div class="container">
            <!--<div class="logo">-->
            <!--<img :src="img.icon_logo">-->
            <!--</div>-->
            <div class="text">项目加载中</div>
            <div class="gear-container">
                <div class="gear" id="gear1"></div>
                <div class="gear" id="gear2"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "loading",
    props: [],
    data: function () {
        return {
            // img: {
            //     icon_logo: '../../images/ide/icon_logo.png',
            // },
        };
    },
    computed: {
        ...mapGetters(["isShowLoading"]),
    },
    created() {},
    activated() {},
    watch: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.loading {
    .screen-mask {
        position: fixed;
        z-index: 2000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background: rgba(0, 0, 0, 0.9);
        background: #000;
        opacity: 1;
        visibility: visible;
    }

    .container {
        position: fixed;
        z-index: 2001;
        top: 35%;
        left: 50%;
        width: 235px;
        height: 210px;
        text-align: center;
        margin-top: -105px;
        margin-left: -105px;

        .logo {
            height: 200px;
            width: auto;
            img {
                height: 100%;
                width: 100%;
            }
        }

        .text {
            font-size: 30px;
            font-weight: bold;
            color: #dfdfdf;
            margin-bottom: 25px;
        }

        .gear-container {
            position: relative;

            .gear {
                float: none;
                position: absolute;
                text-align: center;

                -moz-animation-timing-function: linear;
                -moz-animation-iteration-count: infinite;
                -moz-animation-direction: normal;
                -moz-animation-delay: 0s;
                -moz-animation-play-state: running;
                -moz-animation-fill-mode: forwards;

                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                -webkit-animation-direction: normal;
                -webkit-animation-delay: 0s;
                -webkit-animation-play-state: running;
                -webkit-animation-fill-mode: forwards;
            }
            #gear1 {
                background: url('~@/assets/images/common/gear2.svg') no-repeat 0 0;
                height: 85px;
                width: 85px;
                background-size: 85px;
                left: 0;
                top: 10px;

                -moz-animation-name: ckw;
                //-moz-animation-duration: 10s;
                -moz-animation-duration: 5s;
                -webkit-animation-name: ckw;
                //-webkit-animation-duration: 10s;
                -webkit-animation-duration: 5s;
            }
            #gear2 {
                background: url('~@/assets/images/common/gear2.svg') no-repeat 0 0;
                height: 125px;
                width: 125px;
                background-size: 125px;
                left: 86px;
                top: 0;

                -moz-animation-name: cckw;
                //-moz-animation-duration: 16.84s;
                -moz-animation-duration: 7s;
                -webkit-animation-name: cckw;
                //-webkit-animation-duration: 16.84s;
                -webkit-animation-duration: 7s;
            }
        }
    }

    /* CSS3 keyframes */
    @-webkit-keyframes ckw {
        0% {
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        100% {
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }
    @-moz-keyframes ckw {
        0% {
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        100% {
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes cckw {
        0% {
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
        100% {
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
    }
    @-moz-keyframes cckw {
        0% {
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
        }
        100% {
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
    }
}
</style>