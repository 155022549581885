import ExamStart from '@/views/pages/exam/ExamStart';
import ExamEnd from '@/views/pages/exam/ExamEnd';
import ExamQuestion from '@/views/pages/exam/examQuestion/ExamQuestion';
import ExamRobotTest from '@/views/pages/exam/RobotTest';

// export default [
export const routes = [

    /**************
     * 考试相关
     **************/ 
    {
        path: '/exam/start',
        name: 'examStart',
        component: ExamStart,
        meta: { keepAlive: true },
    },
    {
        path: '/exam/end',
        name: 'examEnd',
        component: ExamEnd,
        meta: { keepAlive: true },
    },
    {
        path: '/exam/question',
        name: 'examQuestion',
        component: ExamQuestion,
        meta: { keepAlive: true },
    },
    {
        path: '/exam/robot',
        name: 'examRobotTest',
        component: ExamRobotTest,
        meta: { keepAlive: false },
    },

    // Exam-Ide
    //------------------
    // Exam-Scratch
    {
        path: '/exam/scratch',
        name: 'ide-exam-scratch',
        component: () => import(/* webpackChunkName: "ide-exam-scratch" */ '@/views/pages/ide/exam/scratch/PageWrapper'),
        meta: { keepAlive: true },
    },
    {
        path: '/iframe/exam/scratch',
        name: 'iframe-exam-scratch',
        component: () => import(/* webpackChunkName: "iframe-exam-scratch" */ '@/views/pages/ide/exam/scratch/Page'),
        meta: { keepAlive: false },
    },
    // Exam-Python
    {
        path: '/exam/python',
        name: 'exam-python',
        component: () => import(/* webpackChunkName: "exam-python" */ '@/views/pages/ide/exam/python/Page'),
        meta: { keepAlive: true },
    },
];

export default routes;
