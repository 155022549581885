<template>
    <div id="app">
        <v-app id="inspire">
            <!-- <keep-alive>
                <router-view />
            </keep-alive> -->
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>

            <Alert />
            <div class="app-reconnect-dialog">
                <ReconnectDialog />
            </div>
        </v-app>
    </div>
</template>

<script>
import ReconnectDialog from "@/views/components/dialog/ReconnectDialog";
import Alert from "@/views/components/widget/Alert";

export default {
    name: "app",
    components: {
        ReconnectDialog,
        Alert,
    },
};
</script>

<style lang="scss">
html,
body {
    -ms-touch-action: none;
    //background: #888888;
    padding: 0;
    border: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    //overflow: hidden;
    body::-webkit-scrollbar { width: 0 !important }

    // 避免页面中的文字被选中
    -webkit-user-select: none; /*webkit浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    height: 100%;
    width: 100%;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>

<style lang="scss" scoped>
.app-reconnect-dialog {
    position: fixed;
    z-index: 9999;
}
</style>
