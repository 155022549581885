import * as apiWeb from '@/api/web'
import * as apiAdmin from '@/api/admin'
import cos from '@/utils/cos'

import CoreData from '@/libs/runtime/coreData';
import Ide from '@/libs/ide/ide';
import Vue from 'vue';

/* eslint-disable no-undef */

const state = {

    /**
     * Common部分
     */
    
    // 当前环境
    curEnv: '',
    
    // header配置
    headerConfig: {},
    defaultHeaderConfig: {
        /**
         * 按钮部分
         */
        // Common
        logo: false,
        goBack: false,
        fullscreen: false,
        help: false,
        save: false,
        release: false,

        // Class
        fontPlus: false,
        fontMinus: false,

        // Scratch
        projName: false,
        data: false,
        nav: false,
        demoCode: false, //教师用示例代码
        code: false, //Scratch对应的代码
        copyRes: false,
        changeBg: false,

        // Python
        exercise: false,
        reset: false,
        createDemo: false,
        demoList: false,

        /**
         * 中间部分
         */
        title: true,

        /**
         * 右侧部分
         */
        sectionList: true,
        avatar: true,
    },

    // Python相关
    // 区分 练习 or Demo
    pyCurrent: "exercise", // exercise, demo
    // demo id列表
    // TODO：临时添加，需要优化
    pyDemoIds: [],
    pyCurDemoId: '',

    /**
     * Scratch相关
     */
    pid: '',
    cid: '',
    sid: '',

    topMsg: '',
    alert: {
        type: 'success',
        msg: '',
    },
    
    // 当前对应的数据，demo/user
    current: '',

    //Header区相关控制变量
    curCodeType: 'Scratch', //当前展示的代码类型：Scratch，JavaScript，Python
    curLang: 'zh-cn', //当前语言：zh-cn, zh-tw, en

    curMediaLibType: 'all', //媒体库显示内容类型，all：全部，actor：角色类，bg：背景类，sound：声音类
    curMediaLibShowCostume: 'false',

    //Ide各个区域是否显示
    ide: {
        menu: { //Ide，Classroom中Header部分的下拉菜单
            isShowCodeMenu: false, //是否显示代码菜单
            isShowLangMenu: false, //是否显示语言菜单
        },
        area: {
            isShowCodeArea: false, //是否显示代码区
            isShowDataArea: false, //是否显示数据区
            // isShowDemoCodeArea: false, //是否显示学习目标对应的源代码，只有当用户是老师时有效
        },
        window: {
            isShowMediaLib: false, //是否显示媒体库窗口
            isShowConfig: false, //是否显示角色配置窗口
        },
        dialog: {
            isShowDeleteActorDlg: false,
        }
    },

    modalDlg: {
        title: '',
        content: '',
        highlight: '',
        okCallback: null,
        cancelCallback: null,
    },

    //状态类变量
    isRunning: false, //是否正在运行
    isReset: true, //是否已经reset
    isShowLoading: true, //是否正在加载数据
    isShowSetting: false, //是否正在进行角色属性设置
    isShowDemoCode: false, //是否显示示例代码
    isShowReconnectDialog: false,

    //展示区Stage初始化和数据的加载状态
    loadStageDone: false, //展示区Stage初始化加载完毕
    loadDataDone: false, //课堂数据加载完毕

    //当前的Actor
    //同时用于在角色属性栏中，展示角色属性
    curActor: {
        id: '',
        name: '背景',
        src: null,
        x: 0,
        y: 0,
        size: 100,
        degree: 90,
        index: 0,
        isShow: 'true',
        isDraggable: 'false',
        rotationMode: 0,
        isFlipHorizontal: 'false',
        isFlipVertical: 'false',
    },

    /**
     * 展示信息
     */
    // 项目/作品信息
    projInfo: {
        name: '',
        type: '',
        desc: '',
    },
    //本节课基础信息
    courseInfo: {
        name: '',
        type: '',
        status: 0, //上课状态：0，初始状态；1，可以开课；2，正在上课；3，中间休息；4，课后答疑
        sections: [], //本课程中的子小节id列表，用于在不同小节跳转
    },
    //小节信息
    sectionInfo: {
        name: '',
        type: '',
        desc: '',
    },

    //角色区
    actorAreaData: {
        backdrop: {
            id: '',
            name: '',
            src: '',
            index: '',
        },
        actors: [] //数据结构同backdrop
    },
    //角色属性
    propData: {
        isBackdrop: false,
        meta: {},
        costumes: [],
        sounds: [],
        // curActor: {
        //     name: '',
        //     avatar: ''
        // }
    },
    //数据区数据（包括所有变量和list）
    dataAreaData: [
        // {
        //     id: 'backdrop',
        //     name: '背景',
        //     variables: [
        //         {
        //             id: '',
        //             type: '', //空为变量
        //             name: 'var1',
        //             value: '0',
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         {
        //             id: 'varId2',
        //             type: '', //空为变量
        //             name: '我的变量2',
        //             value: '你好',
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         // vId2: {
        //         //     ...
        //         // },
        //     ],
        //     lists: [
        //         {
        //             id: 'listId1',
        //             type: 'list',
        //             name: 'list1',
        //             value: [3, 4, 5, 8, 9, 8],
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         {
        //             id: 'listId2',
        //             type: 'list',
        //             name: 'list2',
        //             value: ['你好','你好','你好','你好'],
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         // {
        //         //     ...
        //         // },
        //     ],
        // },
        // {
        //     id: '_',
        //     name: '全局',
        //     variables: [
        //         {
        //             id: '',
        //             type: '', //空为变量
        //             name: 'var1',
        //             value: '0',
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         {
        //             id: 'varId2',
        //             type: '', //空为变量
        //             name: '我的变量2',
        //             value: '你好',
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         // vId2: {
        //         //     ...
        //         // },
        //     ],
        //     lists: [
        //         {
        //             id: 'listId1',
        //             type: 'list',
        //             name: 'list1',
        //             value: [3, 4, 5, 8, 9, 8],
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         {
        //             id: 'listId2',
        //             type: 'list',
        //             name: 'list2',
        //             value: ['你好','你好','你好','你好'],
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         // {
        //         //     ...
        //         // },
        //     ],
        // },
        // {
        //     id: 'id1',
        //     name: '角色1',
        //     variables: [
        //         {
        //             id: '',
        //             type: '', //空为变量
        //             name: 'var1',
        //             value: '0',
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         {
        //             id: 'varId2',
        //             type: '', //空为变量
        //             name: '我的变量2',
        //             value: '你好',
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         // vId2: {
        //         //     ...
        //         // },
        //     ],
        //     lists: [
        //         {
        //             id: 'listId1',
        //             type: 'list',
        //             name: 'list1',
        //             value: [3, 4, 5, 8, 9, 8],
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         {
        //             id: 'listId2',
        //             type: 'list',
        //             name: 'list2',
        //             value: ['你好','你好','你好','你好'],
        //             isLocal: false,
        //             isCloud: false,
        //             isShow: true,
        //         },
        //         // {
        //         //     ...
        //         // },
        //     ],
        // },
    ],
}

const mutations = {

    // 设置Header
    SetHeaderConfig: (state, headerConfig) => {
        // reset
        for (let name in state.defaultHeaderConfig) {
            state.headerConfig[name] = state.defaultHeaderConfig[name]
        }
        // new config
        for (let name in headerConfig) {
            state.headerConfig[name] = headerConfig[name]
        }
    },

    // 设置ProjectId
    SetProjectId: (state, pid) => {
        state.pid = pid;
    },
    // 设置CourseId
    SetCourseId: (state, cid) => {
        state.cid = cid;
    },
    // 设置SectionId
    SetSectionId: (state, sid) => {
        state.sid = sid;
    },

    // 设置topMsg
    SetTopMsg: (state, msg) => {
        state.topMsg = msg;
    },
    // 设置appAlert
    SetAlert: (state, {
        type = 'success',
        msg
    }) => {
        state.alert.type = type;
        state.alert.msg = msg;
    },

    // 打开模态对话框
    ShowModalDlg: (state, {
        // modalType,
        title,
        content,
        highlight,
        okCallback = null,
        cancelCallback = null,
    }) => {
        state.modalDlg.title = title;
        state.modalDlg.content = content;
        state.modalDlg.highlight = highlight;
        state.modalDlg.okCallback = okCallback;
        state.modalDlg.cancelCallback = cancelCallback;

        state.ide.dialog.isShowDeleteActorDlg = true;
    },
    // 关闭模态对话框
    CloseModalDlg: (state, /* modaType */) => {
        state.modalDlg.title = '';
        state.modalDlg.content = '';
        state.modalDlg.okCallback = '';
        state.modalDlg.cancelCallback = '';

        state.ide.dialog.isShowDeleteActorDlg = false;
    },

    // 设置当前环境
    SetCurEnv: (state, curEnv) => {
        state.curEnv = curEnv;
    },
    // 设置当前数据
    SetCurrent: (state, current) => {
        CoreData.setCurrent(current);
        state.current = current;
    },

    // 设置当前语言
    SetCurLang: (state, curLang) => {
        state.curLang = curLang;
    },
    // 设置当前展示的代码类型
    SetCurCodeType: (state, curCodeType) => {
        state.curCodeType = curCodeType;
    },
    // 是否展示代码区
    ShowArea: (state, {
        name,
        isShow
    }) => {
        switch (name) {
            case 'CODE_AREA':
                state.ide.area.isShowCodeArea = isShow;
                break;
            case 'DATA_AREA':
                state.ide.area.isShowDataArea = isShow;
                break;
            // case 'DEMO_CODE_AREA':
            //     state.ide.area.isShowDemoCodeArea = isShow;
            //     break;
        }
    },

    // 是否正在运行
    SetIsRunning: (state, isRunning) => {
        state.isRunning = isRunning;
    },
    // 是否已经复位
    SetIsReset: (state, isReset) => {
        state.isReset = isReset;
    },
    // 是否显示角色设置窗口
    SetIsShowLoading: (state, isShow) => {
        state.isShowLoading = isShow;
    },
    // 是否显示角色设置窗口
    SetIsShowSetting: (state, isShow) => {
        state.isShowSetting = isShow;
    },
    // 是否显示示例代码
    SetIsShowDemoCode: (state, isShow) => {
        state.isShowDemoCode = isShow;
    },
    ToggleShowDemoCode: (state) => {
        state.isShowDemoCode = !state.isShowDemoCode;
    },
    SetShowReconnectDialog: (state, isShow) => {
        state.isShowReconnectDialog = isShow;
    },

    // 是否显示窗口
    ShowWindow: (state, {
        name,
        isShow,
        type = 'all',
        showCostume = false
    }) => {
        switch (name) {
            case 'CONFIG':
                state.ide.window.isShowConfig = isShow;
                break;
            case 'MEDIA_LIB':
                state.ide.window.isShowMediaLib = isShow;
                state.curMediaLibType = type;
                state.curMediaLibShowCostume = showCostume;
                break;
        }
    },

    // 显示/关闭指定菜单
    ToggleMenu: (state, name) => {
        //先关闭所有菜单
        state.ide.menu.isShowCodeMenu = false;
        state.ide.menu.isShowLangMenu = false;
        //Toggle指定菜单
        switch (name) {
            case 'CODE':
                state.ide.menu.isShowCodeMenu = !state.ide.menu.isShowCodeMenu;
                break;
            case 'LANG':
                state.ide.menu.isShowLangMenu = !state.ide.menu.isShowLangMenu;
                break;
        }
    },
    // 关闭所有菜单
    HideAllMenu: (state) => {
        state.ide.menu.isShowCodeMenu = false;
        state.ide.menu.isShowLangMenu = false;
    },

    // 设置加载完成状态
    SetLoadDataDone: (state) => {
        state.loadDataDone = true;
    },
    ResetLoadDataDone: (state) => {
        state.loadDataDone = false;
    },
    // 设置egret舞台加载完成
    SetLoadStageDone: (state) => {
        state.loadStageDone = true;
    },

    // 设置项目基本信息
    SetProjectInfo: (state, {
        name,
        type,
        desc
    }) => {
        state.projInfo.name = name
        state.projInfo.type = type
        state.projInfo.desc = desc
    },
    ResetProjectInfo: (state) => {
        state.projInfo.name = ""
        state.projInfo.type = ""
        state.projInfo.desc = ""
    },
    // 设置课堂基本信息
    SetCourseInfo: (state, {
        name,
        type,
        status,
        sections
    }) => {
        state.courseInfo.name = name;
        state.courseInfo.type = type;
        state.courseInfo.status = status;
        state.courseInfo.sections = sections;
    },
    // 设置小节信息
    SetSectionInfo: (state, {
        name,
        type,
        desc
    }) => {
        state.sectionInfo.name = name
        state.sectionInfo.type = type
        state.sectionInfo.desc = desc
    },
    ResetSectionInfo: (state) => {
        state.sectionInfo.name = ""
        state.sectionInfo.type = ""
        state.sectionInfo.desc = ""
    },
    // 设置当前Actor Id, 从角色区中取src数据
    SetCurActor: (state, id) => {
        if (id == 'backdrop') {
            state.curActor.id = 'backdrop';
            state.curActor.name = '背景';
            state.curActor.x = '';
            state.curActor.y = '';
            state.curActor.size = '';
            state.curActor.degree = '';
            state.curActor.index = '';
            state.curActor.isShow = '';
            state.curActor.isDraggable = '';
            state.curActor.rotationMode = '';
            state.curActor.isFlipHorizontal = '';
            state.curActor.isFlipVertical = '';

        } else {
            let actor = CoreData.getCurActor(id);
            if (actor && actor.meta) {
                state.curActor.id = actor.meta.id;
                state.curActor.name = actor.meta.name;
                state.curActor.x = actor.meta.x;
                state.curActor.y = actor.meta.y;
                state.curActor.size = actor.meta.size;
                state.curActor.degree = actor.meta.degree;
                state.curActor.index = actor.meta.index;
                state.curActor.isShow = actor.meta.isShow;
                state.curActor.isDraggable = actor.meta.isDraggable;
                state.curActor.rotationMode = actor.meta.rotationMode;
                state.curActor.isFlipHorizontal = actor.meta.isFlipHorizontal;
                state.curActor.isFlipVertical = actor.meta.isFlipVertical;
            }
        }
    },


    /**
     * 角色区
     */
    // 添加角色区角色
    AddActorAreaData: (state, { id, name, src, index = 0 }) => {
        if (id == 'backdrop') {
            state.actorAreaData.backdrop = { id: id, name: name, src: src };
        } else {
            state.actorAreaData.actors.push({
                id: id,
                name: name,
                src: src,
                index: index,
            })
        }
    },
    // 删除角色区角色
    DeleteActorAreaData: (state, id) => {
        if (id == 'backdrop') {
            state.actorAreaData.backdrop = { id: id, name: '背景', src: '' };
        } else {
            for (let i = 0, len = state.actorAreaData.actors.length; i < len; i++) {
                if (state.actorAreaData.actors[i].id == id) {
                    state.actorAreaData.actors.splice(i, 1);
                    break;
                }
            }
        }
    },
    ResetActorAreaData: (state) => {
        state.actorAreaData.backdrop = { id: '', name: '', src: '' };
        state.actorAreaData.actors = [];
    },
    SetActorAreaData: (state, { id, name, src }) => {
        if (id == 'backdrop') {
            if (src || src == '') {
                state.actorAreaData.backdrop.src = src;
            }
        } else {
            for (let i = 0; i < state.actorAreaData.actors.length; i++) {
                if (state.actorAreaData.actors[i].id == id) {
                    if (name) {
                        state.actorAreaData.actors[i].name = name;
                    }
                    if (src) {
                        state.actorAreaData.actors[i].src = src;
                    }
                    break;
                }
            }
        }

        // 同时更新当前角色中的src
        if (src || src == '') {
            state.curActor.src = src;
        }
    },
    UpdateActorDataIndex: (state) => {
        for (let i = 0; i < state.actorAreaData.actors.length; i++) {
            state.actorAreaData.actors[i].index = i;
        }
    },


    // 设置属性数据
    SetPropData: (state, {
        isBackdrop,
        meta,
        costumes,
        sounds
    }) => {
        state.propData.isBackdrop = isBackdrop;
        state.propData.meta = meta;
        state.propData.costumes = costumes;
        state.propData.sounds = sounds;
    },

    // 在程序运行开始时，在数据展示区中创建变量
    InitDataAreaData: (state) => {
        state.dataAreaData = [];

        let curData = CoreData.getCurData();
        // console.log('InitDataAreaData');
        // console.log(curData);
        if (curData) {
            if (curData.global) {
                if (curData.global.variables.length > 0 ||
                    curData.global.lists.length > 0) {

                    state.dataAreaData.push({
                        id: '_',
                        name: '全局',
                        variables: [],
                        lists: [],
                    });

                    let last = state.dataAreaData.length;

                    for (let i = 0; i < curData.global.variables.length; i++) {
                        state.dataAreaData[last - 1].variables.push(curData.dataMap[curData.global.variables[i]]);
                    }

                    for (let i = 0; i < curData.global.lists.length; i++) {
                        state.dataAreaData[last - 1].lists.push(curData.dataMap[curData.global.lists[i]]);
                    }
                }
            }
            if (curData.backdrop) {
                if (curData.backdrop.variables.length > 0 ||
                    curData.backdrop.lists.length > 0) {

                    state.dataAreaData.push({
                        id: 'backdrop',
                        name: '背景',
                        variables: [],
                        lists: [],
                    });

                    let last = state.dataAreaData.length;

                    for (let i = 0; i < curData.backdrop.variables.length; i++) {
                        state.dataAreaData[last - 1].variables.push(curData.dataMap[curData.backdrop.variables[i]]);
                    }

                    for (let i = 0; i < curData.backdrop.lists.length; i++) {
                        state.dataAreaData[last - 1].lists.push(curData.dataMap[curData.backdrop.lists[i]]);
                    }
                }
            }
            for (const [actorId, actor] of Object.entries(curData.actors)) {
                if (actor.variables.length > 0 ||
                    actor.lists.length > 0) {

                    state.dataAreaData.push({
                        id: actor.meta.id,
                        name: actor.meta.name,
                        variables: [],
                        lists: [],
                    });

                    let last = state.dataAreaData.length;

                    for (let i = 0; i < actor.variables.length; i++) {
                        state.dataAreaData[last - 1].variables.push(curData.dataMap[actor.variables[i]]);
                    }

                    for (let i = 0; i < actor.lists.length; i++) {
                        state.dataAreaData[last - 1].lists.push(curData.dataMap[actor.lists[i]]);
                    }
                }
            }
        }

        // console.log("InitDataAreaData ++++++++++");
        // console.log(state.dataAreaData);
    },
    // 在程序运行过程中，更新变量
    SetDataAreaData: (state, {
        actorId, varId, varData
    }) => {

        // console.log('SetDataAreaData ************');
        // console.log(state.dataAreaData);

        // 全局变量
        if (!varData.isLocal) {
            actorId = '_';
        }

        for (let i = 0; i < state.dataAreaData.length; i++) {
            if (state.dataAreaData[i].id == actorId) {

                if (varData.type == "") {
                    for (let j = 0; j < state.dataAreaData[i].variables.length; j++) {
                        if (state.dataAreaData[i].variables[j].id == varId) {
                            // state.dataAreaData[i].variables[j] = varData;
                            Vue.set(state.dataAreaData[i].variables, j, varData);
                            return;
                        }
                    }
                }
                else if (varData.type == "list") {
                    for (let j = 0; j < state.dataAreaData[i].lists.length; j++) {
                        if (state.dataAreaData[i].lists[j].id == varId) {
                            // state.dataAreaData[i].lists[j] = varData;
                            Vue.set(state.dataAreaData[i].lists, j, varData);
                            return;
                        }
                    }
                }
            }
        }

        // console.log(state.dataAreaData);
    },
    ResetDataAreaData: (state) => {
        state.dataAreaData = [];
    },


    // 设置Actor属性
    SetCurActorInfo: (state, {
        name,
        value
    }) => {
        state.curActor[name] = value;
    },

    // Python相关
    SetPythonDemo: (state, demoIds) => {
        if (!demoIds) {
            state.pyDemoIds = []
        } else {
            state.pyDemoIds = demoIds
        }
    },
    AddPythonDemo: (state, demoId) => {
        state.pyDemoIds.push(demoId)
    },
    DelPythonDemo: (state, demoIndex) => {
        // 将对应位置设置为null，这样可以保证其他标签不变
        state.pyDemoIds.splice(demoIndex, 1, null)
    },
    SetPyCurDemoId: (state, demoId) => {
        state.pyCurDemoId = demoId
    },
    SetPyCurrent: (state, current) => {
        state.pyCurrent = current
    },
}


const actions = {

    /***
     * IDE相关
     */
    async SetTopMsg({ commit, state }, msg) {
        commit('SetTopMsg', msg);

        setTimeout(() => {
            commit('SetTopMsg', '');
        }, 1000);
    },
    async SetAlert({ commit, state }, { type, msg }) {
        commit('SetAlert', { type, msg });

        setTimeout(() => {
            commit('SetAlert', { type, msg: '' });
        }, 1500);
    },

    //获取项目基本信息
    async GetProjectInfo({ commit, state }, pid) {
        let res = await apiWeb.getProjectInfo(pid);
        // console.log(res.data);
        if (res && res.data) {
            commit('SetProjectInfo', {
                name: res.data.name,
                type: res.data.type,
                desc: '',
            });
        }
        return res;
    },
    //获取课堂基本信息
    async GetCourseInfo({ commit, state }, cid) {
        let res = await apiWeb.getCourseInfo(cid);
        // console.log(res.data);
        if (res.data) {
            commit('SetCourseInfo', {
                name: res.data.name,
                type: res.data.type,
                status: res.data.status,
                sections: res.data.sections
            });
        }
        return res;
    },
    //获取课堂基本信息
    async GetAdminCourseInfo({ commit, state }, cid) {
        let res = await apiAdmin.getCourseInfo(cid);
        // console.log(res.data);
        if (res.data) {
            commit('SetCourseInfo', {
                name: res.data.name,
                type: res.data.type,
                status: res.data.status,
                sections: res.data.sections
            });
        }
        return res;
    },
    // 获取小节信息
    async GetSectionInfo({ commit, state }, sid) {
        let res = await apiWeb.getSectionInfo(sid);
        // console.log(res.data);
        if (res.data) {
            commit('SetSectionInfo', {
                name: res.data.name,
                type: res.data.type,
                desc: res.data.desc,
            });
        }
        return res;
    },
    // Admin Editor中获取小节基本信息
    async GetAdminSectionInfo({ commit, state }, sid) {
        let res = await apiAdmin.getSectionInfo(sid);
        // console.log(res.data);
        if (res && res.data) {
            commit('SetSectionInfo', {
                name: res.data.name,
                type: res.data.type,
                desc: res.data.desc,
            });
        }
        return res;
    },

    // 加载课堂数据
    async LoadClassroomData({ commit, state }, { resId, userResId, toolbox }) {
        // console.log(res.data);
        CoreData.setToolbox(toolbox);

        let data = ''
        // 加载示例
        if (resId != '') {
            data = await cos.getObject('project', 'project', resId + '.demo');
            if (data && data.Body) {
                CoreData.setData('demo', JSON.parse(data.Body));
            }
        }
        // 如果存在用户作品文件，优先加载用户文件
        // 如果不存在用户作品，加载默认资源文件
        if (userResId != '') {
            data = await cos.getObject('project', 'project', userResId);
            if (data && data.Body) {
                CoreData.setData('user', JSON.parse(data.Body));
            }
        } else {
            if (resId != '') {
                data = await cos.getObject('project', 'project', resId + '.res');
                if (data && data.Body) {
                    CoreData.setData('user', JSON.parse(data.Body));
                }
            }
        }
        commit('SetLoadDataDone');
    },
    // 加载Editor数据
    async LoadEditorData({ commit, state }, resId) {
        // console.log(res.data);
        if (resId != '') {
            // 加载demo示例文件
            let data = await cos.getObject('project', 'project', resId + '.demo');
            if (data && data.Body) {
                CoreData.setData('demo', JSON.parse(data.Body));
            }
            // 加载res学生用初识资源文件
            data = await cos.getObject('project', 'project', resId + '.res');
            if (data && data.Body) {
                CoreData.setData('user', JSON.parse(data.Body));
            }
        }
        commit('SetLoadDataDone');
    },
    // 获取项目数据
    async LoadProjectData({ commit, state }, resId) {
        if (resId != '' && resId != 'undefined') {
            let data = await cos.getObject('project', 'project', resId);
            if (data && data.Body) {
                CoreData.setData('user', JSON.parse(data.Body));
            }
        }
        commit('SetLoadDataDone')
    },
    // 加载题目数据
    async LoadQuestionData({ commit, state }, { resId, toolbox = null }) {
        if (toolbox != null && toolbox != '') {
            CoreData.setToolbox(toolbox);
        }

        let data = ''
        // 加载题目部分
        if (resId != '') {
            data = await cos.getObject('project', 'project', resId + '.question');
            if (data && data.Body) {
                CoreData.setData('demo', JSON.parse(data.Body));
            }
        }
        // 加载参考答案
        if (resId != '') {
            data = await cos.getObject('project', 'project', resId + '.ans');
            if (data && data.Body) {
                CoreData.setData('user', JSON.parse(data.Body));
            }
        }
        commit('SetLoadDataDone');
    },
    // 获取Exam数据
    async LoadExamData({ commit, state }, { resId, toolbox = null }) {
        if (toolbox != null && toolbox != '') {
            CoreData.setToolbox(toolbox);
        }

        if (resId != '' && resId != 'undefined') {
            let data = await cos.getObject('project', 'project', resId + '.question');
            if (data && data.Body) {
                CoreData.setData('user', JSON.parse(data.Body));
            }
        }
        commit('SetLoadDataDone')
    },

    // 选中角色
    SelectSprite({ commit, state }, id) {
        // 选中展示区中对应的Actor
        if (!state.isRunning) {
            window.spriteInstance.selectSprite(id);
        }
        if (id != state.curActor.id) {

            // 保存当前积木信息
            CoreData.saveBlock(state.curActor.id);

            // 更新当前对象
            // 注意：必须放在恢复选中积木信息前面，这样才能在恢复积木时重建变量
            commit('SetCurActor', id);

            // 恢复选中积木信息
            CoreData.loadBlock(id);

            // 高亮代码
            // if (this.isRunning) {
            //     CodeInterpreter.highlightBlocks(true);
            // }

            // 更新展示区egret中的当前对象id
            window.spriteInstance.setCurSprite(id);

            // 切换toolbox
            if (id == 'backdrop') {
                CoreData.updateToolbox('backdrop');
            } else {
                CoreData.updateToolbox('actor');
            }

            // 手动补充全局变量
            CoreData.paddingGlobalDatas();

            // 将恢复后的积木，同步到代码区
            Ide.workspace2code();

            // 更新toolbox中积木块的坐标
            // 移到xy
            let movex = Ide.workspace.getBlockById('movex')
            if (movex) {
                movex.setFieldValue(state.curActor.x, 'NUM')
            }
            let movey = Ide.workspace.getBlockById('movey')
            if (movey) {
                movey.setFieldValue(state.curActor.y, 'NUM')
            }

            // 滑行到xy
            let glidex = Ide.workspace.getBlockById('glidex')
            if (glidex) {
                glidex.setFieldValue(state.curActor.x, 'NUM')
            }
            let glidey = Ide.workspace.getBlockById('glidey')
            if (glidey) {
                glidey.setFieldValue(state.curActor.y, 'NUM')
            }

            // 坐标设为xy
            let setx = Ide.workspace.getBlockById('setx')
            if (setx) {
                setx.setFieldValue(state.curActor.x, 'NUM')
            }
            let sety = Ide.workspace.getBlockById('sety')
            if (sety) {
                sety.setFieldValue(state.curActor.y, 'NUM')
            }

            // 朝向
            let direction = Ide.workspace.getBlockById('direction')
            if (direction) {
                direction.setFieldValue(state.curActor.degree, 'NUM')
            }

            // Ide.workspace.refreshToolboxSelection();
        }
    },
    SelectSprite2({ commit, state }, id) {
        // 选中展示区中对应的Actor
        if (!state.isRunning) {
            // window.spriteInstance.selectSprite(id);
        }
        if (id != state.curActor.id) {

            // 更新当前对象
            // 注意：必须放在恢复选中积木信息前面，这样才能在恢复积木时重建变量
            commit('SetCurActor', id);

            // 更新展示区egret中的当前对象id
            window.spriteInstance.setCurSprite(id);
        }
    },
    DeleteSprite({ commit, state }, { delId, curActorId }) {
        window.spriteInstance.deleteSprite(delId);

        let curData = CoreData.getCurData();
        delete (curData.actors[delId]);

        // 更新角色区 - 删除
        commit('DeleteActorAreaData', delId);

        // 更新当前对象id
        if (delId == curActorId) {
            Ide.block2workspace(curData.backdrop.block);
            commit('SetCurActor', 'backdrop');
        }
    },

    /**
     * 运行相关
     */
    PlayerStart({ commit, state }) {
        // SoundInstance.playNote();
        if (!state.isRunning) {

            CoreData.saveBlock(state.curActor.id);

            // TODO: 下面初始化的部分，全部放到egret中
            //--------------------------------------
            // 保存Sprites当前位置
            window.spriteInstance.saveSpritesMeta();
            window.backdropInstance.saveBackdropMeta();

            // 通知展示区play
            window.stageInstance.play();

            // 注册键盘监听事件
            Keyboard.addListener();

            // 重置事件管理器
            EventMgr.resetEvent();

            // 重置全局计时器
            Timer.resetTimer();

            //--------------------------------------

            commit("SetIsRunning", true);
            commit("SetIsReset", false);

            // 代码块高亮
            // CodeInterpreter.highlightBlocks(true);

            // 初始化数据区变量
            commit('InitDataAreaData');

            // 执行代码块
            // CodeInterpreter.play(this.backdrop, this.actors);
            let curData = CoreData.getCurData();
            CodeInterpreter.play(curData.backdrop, curData.actors);
        }
    },
    PlayerStop({ commit, state }) {
        if (state.isRunning) {
            commit("SetIsRunning", false);
            commit("SetIsReset", true);

            CodeInterpreter.stop();

            // 关闭所有正在播放的声音
            window.spriteInstance.stopAllSounds();
            window.backdropInstance.stopAllSounds();

            // 通知展示区stop
            window.stageInstance.stop();

            // 重置事件管理器
            EventMgr.resetEvent();

            // 注销键盘监听事件
            Keyboard.removeListener();

            // 恢复Sprites之前的位置
            window.spriteInstance.restoreSpriteMeta();
            window.backdropInstance.restoreBackdropMeta();

            // 代码块高亮
            // CodeInterpreter.highlightBlocks(false);
        }
    },
    PlayerAutoStop() {
        if (state.isRunning) {
            state.isRunning = false;
            state.isReset = false;

            CodeInterpreter.stop();

            // 通知展示区stop
            window.stageInstance.stop();

            // 重置事件管理器
            EventMgr.resetEvent();

            // 注销键盘监听事件
            Keyboard.removeListener();

            // 代码块高亮
            // CodeInterpreter.highlightBlocks(false);
        }
    },
    PlayerReset({ commit, state }) {
        commit("SetIsRunning", false);
        commit("SetIsReset", true);

        // 关闭所有正在播放的声音
        window.spriteInstance.stopAllSounds();
        window.backdropInstance.stopAllSounds();

        // 重置事件管理器
        EventMgr.resetEvent();

        // 注销键盘监听事件
        Keyboard.removeListener();

        // 恢复Sprites之前的位置
        window.spriteInstance.restoreSpriteMeta();
        window.backdropInstance.restoreBackdropMeta();
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
