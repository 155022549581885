'use strict';

import Ide from '@/libs/ide/ide.js';
import CoreData from '@/libs/runtime/coreData.js';

/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */

const BlocklyHooker = {
    init() {
        /**
         * 生成代码相关
         */
        Blockly.Generator.prototype.INDENT = '    ';

        Blockly.Generator.prototype.workspaceToCode = function(workspace) {
            if (!workspace) {
                // Backwards compatibility from before there could be multiple workspaces.
                console.warn('No workspace specified in workspaceToCode call.  Guessing.');
                workspace = Blockly.getMainWorkspace();
            }
            var code = [];
            this.init(workspace);
            var blocks = workspace.getTopBlocks(true);

            // 允许生成代码的顶层模块
            var enabledTopBlocks = {
                'event_whenflagclicked': true,
                'event_whenthisspriteclicked': true,
                'event_whenkeypressed': true,
                'event_whenbroadcastreceived': true,
                'event_whenbackdropswitchesto': true,
                'event_whengreaterthan': true,
                'control_start_as_clone': true,
                'procedures_definition': true,
                // 'procedures_call': true,
            };

            // 说明：
            // 下面的 line变量 指的是从一个TopBlock下获取到的代码
            // 一个workspace中可能会有多个TopBlock
            // 最后会把这些代码合并在code中
            var block;
            for (var x = 0; blocks[x]; x++) {
                block = blocks[x]
                if (block.type in enabledTopBlocks) {
                    try {
                        var line = this.blockToCode(block);
                    } catch (err) {
                        // console.log(err);
                    }
                    if (goog.isArray(line)) {
                        // Value blocks return tuples of code and operator order.
                        // Top-level blocks don't care about operator order.
                        line = line[0];
                    }
                    if (line) {
                        if (block.outputConnection && this.scrubNakedValue) {
                            // This block is a naked value.  Ask the language's code generator if
                            // it wants to append a semicolon, or something.
                            line = this.scrubNakedValue(line);
                        }


                        /**
                         * 替换变量内部函数，用于代码显示
                         */
                        // 变量
                        // line = line.replace(/this.data.getVar\(\"(.+)\", \"(.+)\"\)/g, '$2');
                        // line = line.replace(/this.data.setVarTo\(\"(.+)\", \"(.+)\", (.+)\);/g, '$2 = $3;');
                        // line = line.replace(/this.data.changeVarBy\(\"(.+)\", \"(.+)\", (.+)\);/g, '$2 += $3;');
                        line = line.replace(/this.data.getVar\(\"(.+?)\", \"(.+?)\"\)/g, 'this.data.getVar($2)');
                        line = line.replace(/this.data.setVarTo\(\"(.+?)\", \"(.+?)\", (.+)\);/g, 'this.data.setVar($2, $3);');
                        line = line.replace(/this.data.changeVarBy\(\"(.+?)\", \"(.+?)\", (.+)\);/g, 'this.data.changeVarBy($2, $3);');

                        line = line.replace(/this.data.showVar\(\"(.+?)\", \"(.+?)\"\)/g, 'this.data.showVar($2);');
                        line = line.replace(/this.data.hideVar\(\"(.+?)\", \"(.+?)\"\)/g, 'this.data.hideVar($2);');

                        // 列表
                        line = line.replace(/this.data.getList\(\"(.+?)\", \"(.+?)\"\)/g, 'this.data.getList($2)');
                        line = line.replace(/this.data.push\(\"(.+?)\", \"(.+?)\", (.+)\);/g, 'this.data.push($2, $3);');
                        line = line.replace(/this.data.remove\(\"(.+?)\", \"(.+?)\", (.+)\);/g, 'this.data.remove($2, $3);');
                        line = line.replace(/this.data.removeAll\(\"(.+?)\", \"(.+?)\"\);/g, 'this.data.removeAll($2);');

                        line = line.replace(/this.data.insert\(\"(.+?)\", \"(.+?)\", (.+), (.+)\);/g, 'this.data.insert($2, $3, $4);');
                        line = line.replace(/this.data.replace\(\"(.+?)\", \"(.+?)\", (.+), (.+)\);/g, 'this.data.replace($2, $3, $4);');
                        // line = line.replace(/this.data.getItem\(\"(.+?)\", \"(.+?)\", (.+)\);/g, 'this.data.getItem($2, $3);');
                        line = line.replace(/this.data.getIndex\(\"(.+?)\", \"(.+?)\", (.+)\);/g, 'this.data.getIndex($2, $3);');
                        line = line.replace(/this.data.length\(\"(.+?)\", \"(.+?)\"\)/g, 'this.data.length($2)');
                        line = line.replace(/this.data.contains\(\"(.+?)\", \"(.+?)\", (.+)\)/g, 'this.data.contains($2, $3);');


                        // 删除highlightBlock命令
                        // line = line.replace(/highlightBlock\(.*\);/g, '');
                        line = line.replace(/highlightBlock(.*);/g, '');
                        // 删除waitUntil中的blockId参数
                        // line = line.replace(/this.waitUntil\(\".+\", (.*)\);/g, 'this.waitUntil($1);');
                        // 删除空白行
                        line = line.replace(/(\n[\s\t]*\r*\n)/g, '\n');

                        // 增加代码缩进
                        line = this.prefixLines(/** @type {string} */ (line), this.INDENT);

                        // 去掉首行缩进
                        line = line.replace(/^\s+/, '');

                        // 当前代码段最后一行语法补全，如果是用户自定义函数：补};
                        if (block.type == 'procedures_definition') {
                            line += '}';
                        } else {
                            line += '});';
                        }
                        code.push(line);
                    }
                }
            }
            code = code.join('\n\n');  // Blank line between each section.
            code = this.finish(code);

            // 删除整个代码段最开头的空格和回车
            code = code.replace(/^[\s\t\r\n]+/, '');
            // code = code.replace(/^\s+\n/, '');
            // code = code.replace(/\n\s+$/, '\n');
            // code = code.replace(/[ \t]+\n/g, '\n');

            return code;
        };

        Blockly.Generator.prototype.workspaceToRunCode = function(workspace) {
            if (!workspace) {
                return [];
            }

            var codes = [];

            this.init(workspace);
            var blocks = workspace.getTopBlocks(true);
            // var v = workspace.getAllVariables();

            // 允许生成代码的顶层模块
            var enabledTopBlocks = {
                'event_whenflagclicked': true,
                'event_whenthisspriteclicked': true,
                'event_whenkeypressed': true,
                'event_whenbroadcastreceived': true,
                'event_whenbackdropswitchesto': true,
                'event_whengreaterthan': true,
                'control_start_as_clone': true,
                'procedures_definition': true,
                // 'procedures_call': true,
            };

            var block;
            for (var x = 0; blocks[x]; x++) {
                block = blocks[x];
                if (block.type in enabledTopBlocks) {

                    // 从顶层block获取事件类型及参数
                    var type = '';
                    var args = '';
                    switch (block.type) {
                        case 'event_whenflagclicked':
                            type = 'event_start';
                            break;
                        case 'event_whenthisspriteclicked':
                            type = 'event_click';
                            break;
                        case 'event_whenkeypressed':
                            type = 'event_keyboard';
                            args = block.getFieldValue('KEY_OPTION');
                            break;
                        case 'event_whengreaterthan':
                            var options = block.getFieldValue('WHENGREATERTHANMENU');
                            if (options == 'timer') {
                                type = 'event_timer';
                            }
                            args = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_NONE);
                            break;
                        case 'event_whenbroadcastreceived':
                            type = 'event_broadcast_received';
                            args = Blockly.JavaScript.valueToCode(block, 'BROADCAST_RECEIVED', Blockly.JavaScript.ORDER_NONE);

                            // 去掉字符串两侧的双引号
                            var len = args.length;
                            if (len > 1 && args[0] == '"' && args[len - 1] == '"') {
                                args = args.substring(1, len - 1);
                            }
                            break;
                        case 'control_start_as_clone':
                            type = 'event_start_as_clone';
                            break;
                        case 'procedures_definition':
                            var procBlock = Object.values(block.childBlocks_)[0];
                            var procCode = procBlock.getProcCode();
                            var procName = procCode.split(" ", 1)[0];
                            var argNames = procBlock.displayNames_;

                            type = 'event_user_function';
                            args = {
                                procName: procName,
                                argNames: argNames,
                            };
                            break;
                    }

                    // 获取代码(不包括顶层block)
                    try {
                        var line = this.blockToCode(block.getNextBlock());
                    } catch (err) {
                        // console.log(err);
                    }
                    if (goog.isArray(line)) {
                        // Value blocks return tuples of code and operator order.
                        // Top-level blocks don't care about operator order.
                        line = line[0];
                    }
                    if (line) {
                        if (block.outputConnection && this.scrubNakedValue) {
                            // This block is a naked value.  Ask the language's code generator if
                            // it wants to append a semicolon, or something.
                            line = this.scrubNakedValue(line);
                        }
                        codes.push({
                            type: type,
                            args: args,
                            code: line
                        });
                    }
                }
            }

            return codes;
        };

        // 重载这个函数，减少变量后面空行数（之前有两个空行，改成空一行）
        // Blockly.JavaScript.finish = function(code) {
        //     // Convert the definitions dictionary into a list.
        //     var definitions = [];
        //     for (var name in Blockly.JavaScript.definitions_) {
        //         console.log(name);
        //         definitions.push(Blockly.JavaScript.definitions_[name]);
        //     }
        //     console.log(definitions);
        //     // Clean up temporary data.
        //     delete Blockly.JavaScript.definitions_;
        //     delete Blockly.JavaScript.functionNames_;
        //     Blockly.JavaScript.variableDB_.reset();
        //     // return definitions.join('\n\n') + '\n\n\n' + code;
        //     return definitions.join('\n\n') + '\n\n' + code;
        // };
        Blockly.JavaScript.finish = function(code) {
            if (!Ide.workspace) {
                return code;
            }

            var definitions = [];
            var variables = Ide.workspace.getVariablesOfType('');
            for (let i = 0; i < variables.length; i++) {
                definitions.push('let ' + variables[i].name + ';');
            }

            var lists = Ide.workspace.getVariablesOfType('list');
            for (let i = 0; i < lists.length; i++) {
                definitions.push('let ' + lists[i].name + ' = [];');
            }

            return definitions.join('\n') + '\n\n' + code;
        }


        /**
         * 变量 & 函数 相关
         */

        // 创建变量对话框
        Blockly.prompt = function(message, defaultValue, callback, _opt_title, _opt_varType) {
            // console.log(message);
            // console.log(defaultValue);
            // console.log(_opt_title);
            // console.log(_opt_varType);

            let varType = 'var';
            if (_opt_varType == 'list') {
                varType = 'list';
            }

            // 我们使用defaultValue来判断是新建or修改变量
            // 如果defaultValue为''，是新建变量
            // 不为空，是修改变量
            window.myPrompt(_opt_title, message, defaultValue, varType).then(result => {

                var variableOptions = {
                    // objId: result.scope == 'local' ? result.id : '',
                    scope: result.scope,
                    isLocal: result.scope == 'local' ? true : false,
                    isCloud: false,
                };

                // 通知blockly toolbox添加变量
                callback(result.name, null, variableOptions);

            }).catch(() => {
                //
            });
        };

        Blockly.DataCategory.addCreateButton = function (xmlList, workspace, type) {

            // 说明：在当前版本的scratch-blockly中，没有variableOptions参数
            var createVariableCallback = function (variableId, variableOptions) {
                if (variableId) {
                    var variable = Blockly.Variables.getVariable(workspace, variableId);
                    CoreData.createData(variableId, '', variable.name, variable.isLocal, variable.isCloud, true);
                }
            }
            var createListCallback = function (variableId, variableOptions) {
                if (variableId) {
                    var variable = Blockly.Variables.getVariable(workspace, variableId);
                    // RuntimeData.createList('', variableId, '', variable.name, 0, variable.isLocal, variable.isCloud, true);
                    CoreData.createData(variableId, 'list', variable.name, variable.isLocal, variable.isCloud, true);
                }
            }

            var button = goog.dom.createDom('button');
            // Set default msg, callbackKey, and callback values for type 'VARIABLE'
            var msg = Blockly.Msg.NEW_VARIABLE;
            var callbackKey = 'CREATE_VARIABLE';
            var callback = function (button) {
                Blockly.Variables.createVariable(button.getTargetWorkspace(), createVariableCallback, '');
            };

            if (type === 'LIST') {
                msg = Blockly.Msg.NEW_LIST;
                callbackKey = 'CREATE_LIST';
                callback = function (button) {
                    Blockly.Variables.createVariable(button.getTargetWorkspace(), createListCallback, Blockly.LIST_VARIABLE_TYPE);
                };
            }
            button.setAttribute('text', msg);
            button.setAttribute('callbackKey', callbackKey);
            workspace.registerButtonCallback(callbackKey, callback);
            xmlList.push(button);
        };


        // 重载Blockly中加载变量函数，将变量信息保存到平台中
        Blockly.Xml.domToVariables = function(xmlVariables, workspace) {
            var xmlChild;
            var variable;
            for (var i = 0; xmlVariables.children[i]; i++) {
                xmlChild = xmlVariables.children[i];

                var type = xmlChild.getAttribute('type');
                var id = xmlChild.getAttribute('id');
                var isLocal = xmlChild.getAttribute('islocal') == 'true';
                var isCloud = xmlChild.getAttribute('iscloud') == 'true';
                var name = xmlChild.textContent;
            
                if (typeof(type) === undefined || type === null) {
                    throw Error('Variable with id, ' + id + ' is without a type');
                }
                // 当程序loading加载时，将角色xml中的变量信息加载到CoreData中
                if (window['isShowLoading']() || isLocal) {
                    // TODO：此处后面可以根据meta数据的版本来判断是否需要加载
                    variable = workspace.createVariable(name, type, id, isLocal, isCloud);
                    CoreData.createData(variable.id_, '', variable.name, variable.isLocal, variable.isCloud, true);

                } else if (isLocal) {
                    // 切换角色时，只创建局部变量即可，全局变量会有其他函数补充上
                    variable = workspace.createVariable(name, type, id, isLocal, isCloud, true);
                }
            }
        };

        // 变量保存函数，暂时不需要调整
        // Blockly.Xml.variablesToDom = function(variableList) {
        //     var variables = goog.dom.createDom('variables');
        //     for (var i = 0, variable; variable = variableList[i]; i++) {
        //         if (variable.isLocal) {
        //             var element = goog.dom.createDom('variable', null, variable.name);
        //             element.setAttribute('type', variable.type);
        //             element.setAttribute('id', variable.getId());
        //             element.setAttribute('islocal', variable.isLocal);
        //             element.setAttribute('isCloud', variable.isCloud);
        //             variables.appendChild(element);
        //         }
        //     }
        //     return variables;
        //   };

        Blockly.VerticalFlyout.prototype.checkboxClicked_ = function(checkboxObj) {
            return function(e) {
              this.setCheckboxState(checkboxObj.block.id, !checkboxObj.clicked);

              var variable = checkboxObj.block.getField('VARIABLE').getVariable();
              CoreData.setShowStatus(variable.id_, checkboxObj.clicked);

              // This event has been handled.  No need to bubble up to the document.
              e.stopPropagation();
              e.preventDefault();
            }.bind(this);
        };

        // Blockly.Toolbox.prototype.refreshSelection = function() {
        //     this.showAll_();
        //     // 更新变量勾选框
        //     // Ide.refreshVariableCheckStatus();
        // };

        // 删除变量or列表
        Blockly.VariableMap.prototype.deleteVariable = function(variable) {
            // 更新数据
            CoreData.deleteData(variable.id_, variable.type, variable.name, variable.isLocal, variable.isCloud);
            // CoreData.showData();

            var variableList = this.variableMap_[variable.type];
            var tempVar;
            for (var i = 0; variableList[i]; i++) {
              tempVar = variableList[i]
              if (tempVar.getId() == variable.getId()) {
                variableList.splice(i, 1);
                Blockly.Events.fire(new Blockly.Events.VarDelete(variable));

                // 1ms后，填充checkbox
                setTimeout(function() {
                    CoreData.paddingCheckbox();
                }, 1);

                return;
              }
            }
        };
        // 修改变量名or列表名
        Blockly.VariableMap.prototype.renameVariable = function(variable, newName) {
            // 更新数据
            CoreData.renameData(variable.id_, variable.type, variable.name, variable.isLocal, variable.isCloud, newName);
            // CoreData.showData();

            var type = variable.type;
            var conflictVar = this.getVariable(newName, type);
            var blocks = this.workspace.getAllBlocks();
            Blockly.Events.setGroup(true);
            try {
              if (!conflictVar) {
                this.renameVariableAndUses_(variable, newName, blocks);
              } else {
                // We don't want to rename the variable if one with the exact new name
                // already exists.
                console.warn('Unexpected conflict when attempting to rename ' +
                  'variable with name: ' + variable.name + ' and id: ' + variable.getId() +
                  ' to new name: ' + newName + '. A variable with the new name already exists' +
                  ' and has id: ' + conflictVar.getId());
          
              }
            } finally {
              Blockly.Events.setGroup(false);
            }
        };

        // 创建函数的回调
        Blockly.Procedures.externalProcedureDefCallback = function (mutation, cb) {
            if (!Ide.declarationWorkspace) {

                Ide.declarationWorkspace = Blockly.inject('blockly-func-div', {
                    comments: false,
                    disable: false,
                    collapse: false,
                    media: '/scratch-blockly/media/',
                    // media: null,
                    readOnly: false,
                    rtl: false,
                    scrollbars: true,
                    toolbox: null,
                    // toolbox: '<xml id="procedure-toolbox" style="display: none"></xml>',
                    // toolboxPosition: side == 'top' || side == 'start' ? 'start' : 'end',
                    toolboxPosition: 'start',
                    // horizontalLayout: side == 'top' || side == 'bottom',
                    horizontalLayout: false,
                    sounds: false,
                    zoom: {
                        controls: false,
                        wheel: false,
                        startScale: 1,
                        maxScale: 1.5,
                        minScale: 0.5,
                        scaleSpeed: 1.2
                    },
                });

                Ide.declarationWorkspace.addChangeListener(function() {
                    if (Ide.mutationRoot) {
                        Ide.mutationRoot.onChangeFn();
                    }
                });
            }

            window['showCreateFuncDlg']();

            Ide.mutationCallback = cb;

            Ide.declarationWorkspace.clear();

            Ide.mutationRoot = Ide.declarationWorkspace.newBlock('procedures_declaration');
            Ide.mutationRoot.domToMutation(mutation);
            Ide.mutationRoot.initSvg();
            Ide.mutationRoot.render(false);

            Ide.declarationWorkspace.scrollCenter();
        }

        // 取颜色
        Blockly.FieldColourSlider.activateEyedropper_ = function (cb) {
            window.pickColor().then(result => {
                if (result) {
                    let color = '#' + result;
                    cb(color);
                }
            }).catch(() => {
                //
            });
        }
    }
}

export default BlocklyHooker;
